export const AUTHENTICATING = "AUTHENTICATING";
export const AUTHENTICATED = "AUTHENTICATED";
export const AUTHENTICATING_FAILED = "AUTHENTICATING_FAILED";

export const FETCH_MAP = "FETCH_MAP";
export const FETCH_MAP_COMPLETE = "FETCH_MAP_COMPLETE";
export const UPDATE_MAP = "UPDATE_MAP";
export const UPDATE_MAP_COMPLETE = "UPDATE_MAP_COMPLETE";

export const FETCH_MAPS = "FETCH_MAPS";
export const FETCH_MAPS_COMPLETE = "FETCH_MAPS_COMPLETE";
export const CREATE_MAP = "CREATE_MAP";
export const CREATE_MAP_COMPLETE = "CREATE_MAP_COMPLETE";
export const DELETE_MAP = "DELETE_MAP";
export const DELETE_MAP_COMPLETE = "DELETE_MAP_COMPLETE";
export const GENERATE_MAP_CACHE = "GENERATE_MAP_CACHE";
export const GENERATE_MAP_CACHE_COMPLETE = "GENERATE_MAP_CACHE_COMPLETE";
export const GENERATE_APP_CACHE = "GENERATE_APP_CACHE";
export const GENERATE_APP_CACHE_FAILED = "GENERATE_APP_CACHE_FAILED";
export const DOWNLOAD_DATASET = "DOWNLOAD_DATASET";
export const DOWNLOAD_DATASET_COMPLETE = "DOWNLOAD_DATASET_COMPLETE";

export const FETCH_DATASETS = "FETCH_DATASETS";
export const FETCH_DATASETS_COMPLETE = "FETCH_DATASETS_COMPLETE";

export const FETCH_DATASET_DETAILS = "FETCH_DATASET_DETAILS";
export const FETCH_DATASET_DETAILS_COMPLETE = "FETCH_DATASET_DETAILS_COMPLETE";
export const DATASET_ACTION_FAILED = "DATASET_ACTION_FAILED";

export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_GROUPS_COMPLETE = "FETCH_GROUPS_COMPLETE";
export const FETCH_GROUP = "FETCH_GROUP";
export const FETCH_GROUP_COMPLETE = "FETCH_GROUP_COMPLETE";
export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_COMPLETE = "CREATE_GROUP_COMPLETE";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_COMPLETE = "UPDATE_GROUP_COMPLETE";

export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_COMPLETE = "DELETE_GROUP_COMPLETE";
export const GROUP_ACTION_FAILED = "GROUP_ACTION_FAILED";

export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_COMPLETE = "FETCH_USERS_COMPLETE";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_COMPLETE = "FETCH_USER_COMPLETE";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_COMPLETE = "UPDATE_USER_COMPLETE";
export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_COMPLETE = "CREATE_USER_COMPLETE";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_COMPLETE = "DELETE_USER_COMPLETE";
export const USER_ACTION_FAILED = "USER_ACTION_FAILED";

export const FETCH_APPS = "FETCH_APPS";
export const FETCH_APPS_COMPLETE = "FETCH_APPS_COMPLETE";
export const CREATE_APP = "CREATE_APP";
export const CREATE_APP_COMPLETE = "CREATE_APP_COMPLETE";
export const UPDATE_APP = "UPDATE_APP";
export const UPDATE_APP_COMPLETE = "UPDATE_APP_COMPLETE";
export const ADD_RASTER_TO_APP = "ADD_RASTER_TO_APP";
export const ADD_RASTER_TO_APP_COMPLETE = "ADD_RASTER_TO_APP_COMPLETE";
export const REMOVE_RASTER_FROM_APP = "REMOVE_RASTER_FROM_APP";
export const REMOVE_RASTER_FROM_APP_COMPLETE = "REMOVE_RASTER_FROM_APP_COMPLETE";
export const DELETE_APP = "DELETE_APP";
export const DELETE_APP_COMPLETE = "DELETE_APP_COMPLETE";
export const PUBLISH_APP = "PUBLISH_APP";
export const PUBLISH_APP_COMPLETE = "PUBLISH_APP";
export const APP_ACTION_FAILED = "APP_ACTION_FAILED";

export const FETCH_RASTER = "FETCH_RASTER";
export const FETCH_RASTER_COMPLETE = "FETCH_RASTER_COMPLETE";
export const FETCH_RASTERS = "FETCH_RASTERS";
export const FETCH_RASTERS_COMPLETE = "FETCH_RASTERS_COMPLETE";
export const CREATE_RASTER = "CREATE_RASTER";
export const CREATE_RASTER_COMPLETE = "CREATE_RASTER_COMPLETE";
export const UPDATE_RASTER = "UPDATE_RASTER";
export const UPDATE_RASTER_COMPLETE = "UPDATE_RASTER_COMPLETE";
export const DELETE_RASTER = "DELETE_RASTER";
export const DELETE_RASTER_COMPLETE = "DELETE_RASTER_COMPLETE";
export const RASTER_ACTION_FAILED = "RASTER_ACTION_FAILED";

export const FETCH_DATASET_GEOJSON = "FETCH_DATASET_GEOJSON";
export const FETCH_DATASET_GEOJSON_COMPLETE = "FETCH_DATASET_GEOJSON_COMPLETE";

export const TABLE_VIEW_SET_DATASET = "TABLE_VIEW_SET_DATASET";

export const CREATE_DATASET_COMPLETE = "CREATE_DATASET_COMPLETE";

export const UPDATE_DATASET = "UPDATE_DATASET";
export const UPDATE_DATASET_COMPLETE = "UPDATE_DATASET_COMPLETE";
export const UPDATE_DATASET_ADVANCED = "UPDATE_DATASET_ADVANCED";
export const UPDATE_DATASET_ADVANCED_COMPLETE = "UPDATE_DATASET_ADVANCED_COMPLETE";

export const DELETE_DATASET = "DELETE_DATASET";
export const DELETE_DATASET_COMPLETE = "DELETE_DATASET_COMPLETE";

export const GENERATE_DATASET_CACHE = "GENERATE_DATASET_CACHE";
export const GENERATE_DATASET_CACHE_COMPLETE = "GENERATE_DATASET_CACHE_COMPLETE";

export const CANCEL_GENERATE_DATASET_CACHE = "CANCEL_GENERATE_DATASET_CACHE";
export const CANCEL_GENERATE_DATASET_CACHE_COMPLETE = "CANCEL_GENERATE_DATASET_CACHE_COMPLETE";

export const FETCH_SCHEMA_NAMES = "FETCH_SCHEMA_NAMES";
export const FETCH_SCHEMA_NAMES_COMPLETE = "FETCH_SCHEMA_NAMES_COMPLETE";

export const SIGNALR_PROGRESS_UPDATE = "SIGNALR_PROGRESS_UPDATE";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";
export const SIGNALR_ERROR_UPDATE = "SIGNALR_ERROR_UPDATE";

export const NOTIFICATIONS_TOGGLE_OPEN = "NOTIFICATIONS_TOGGLE_OPEN";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";

export const FETCH_METADATA_SCHEMA = "FETCH_METADATA_SCHEMA";
export const FETCH_METADATA_SCHEMA_COMPLETE = "FETCH_METADATA_SCHEMA_COMPLETE";

export const LINK_DATASET_GOOGLE_DRIVE = "LINK_DATASET_GOOGLE_DRIVE";
export const LINK_DATASET_GOOGLE_DRIVE_COMPLETE = "LINK_DATASET_GOOGLE_DRIVE_COMPLETE";

export const OPEN_UPLOADER = "OPEN_UPLOADER";
export const CLOSE_UPLOADER = "CLOSE_UPLOADER";

export const FETCH_SCHEMAS = "FETCH_SCHEMAS";
export const FETCH_SCHEMAS_COMPLETE = "FETCH_SCHEMAS_COMPLETE";
export const CREATE_SCHEMA = "CREATE_SCHEMA";
export const CREATE_SCHEMA_COMPLETE = "CREATE_SCHEMA_COMPLETE";
export const DELETE_SCHEMA = "DELETE_SCHEMA";
export const DELETE_SCHEMA_COMPLETE = "DELETE_SCHEMA_COMPLETE";
export const SCHEMAS_ACTION_FAILED = "SCHEMAS_ACTION_FAILED";

export const PANEL_TOGGLE = "PANEL_TOGGLE";
export const NAVIGATION_TOGGLE = "NAVIGATION_TOGGLE";

import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import "rc-slider/assets/index.css";
import * as rasterServiceActions from "../../../actions/rasterService";
import * as mapActions from "../../../reducers/map";
import toastr from "../../../components/CustomToastr/CustomToastr";
import * as NetworkErrorUtils from "../../../utils/networkErrorUtils";

import * as TileUtil from "../../../utils/tileUtils";
import { IconButton } from "@mui/material";
import { withRouter } from "react-router";
import ReadOnlyField from "../../../components/ReadOnlyField/ReadOnlyField";

import keyboardBackspace from "../../../utils/icons/keyboard-backspace.svg";
import fileCopyIcon from "../../../utils/icons/copy-icon.svg";
import SaveIcon from "@mui/icons-material/Save";
import dayjs from "dayjs";

import { fromNowWithCheck } from "../../../utils/timeUtils";
import OverflowTip from "../../../components/OverflowTip/OverflowTip";

const styles = (theme) => ({
    headerText: {
        lineHeight: "30px",
        fontWeight: "400",
        color: theme.customColors.appTextColor
    },
    stylerHeader: {
        padding: "10px 0",
        width: 424,
        height: theme.customSpacing.header,
        alignItems: "center",
        display: "flex",
        boxSizing: "border-box",
        flexGrow: 1
    },
    backButton: {
        padding: 10,
        margin: "0 0 0 -10px"
    },
    icon: {
        marginRight: 8
    }
});
class RasterEditView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            raster: {
                name: "",
                id: "",
                fileSize: 0,
                minZoom: 10,
                maxZoom: 24
            },
            tileCount: 0
        };
    }

    componentDidMount() {
        let rasterPromise = this.props.getRaster(this.props.match.params.rasterId);

        Promise.all([rasterPromise])
            .then((res) => {
                let rasterResult = res[0].result;

                this.init(rasterResult);
            })
            .catch((err) => this.props.history.push("/rasters"));
    }

    init(raster) {
        let bbox = {
            minLon: raster.bounds.coordinates[0][0][0],
            minLat: raster.bounds.coordinates[0][0][1],
            maxLon: raster.bounds.coordinates[0][2][0],
            maxLat: raster.bounds.coordinates[0][2][1]
        };
        let tileCount = TileUtil.getTileCount(bbox, raster.minZoom, raster.maxZoom);

        this.setState({
            raster: raster,
            tileCount: tileCount
        });

        this.props.addMapSource({
            id: raster.id,
            minZoom: raster.minZoom,
            maxZoom: raster.maxZoom,
            type: "raster"
        });

        this.props.addMapLayer({
            sourceId: raster.id,
            layerId: raster.id,
            resourceId: raster.id,
            sourceName: raster.name,
            type: "raster",
            sourceMinZoom: raster.minZoom,
            sourceMaxZoom: raster.maxZoom,
            minZoom: raster.minZoom,
            maxZoom: 24
        });

        this.props.mapFitBounds([raster.bounds.coordinates[0][0], raster.bounds.coordinates[0][2]], {
            padding: { top: 45, bottom: 45, left: 45, right: 45 },
            animate: false
        });
    }

    componentWillUnmount() {
        this.props.removeMapLayer(this.props.match.params.rasterId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.rasterId !== prevProps.match.params.rasterId) {
            this.props.getRaster(this.props.match.params.rasterId).then((x) => this.init(x));
        }
    }

    updateRaster = () => {
        let raster = this.state.raster;

        let rasterRequest = {
            name: raster.name
        };

        this.props.updateRaster(raster.id, rasterRequest).then(
            () => toastr.success("Raster updated"),
            (err) => NetworkErrorUtils.handleError(err)
        );
    };

    onRasterNameChange = (event) => {
        this.setState({
            raster: {
                ...this.state.raster,
                name: event.target.value
            }
        });
    };

    onBack = () => {
        if (this.props.prevLocation) {
            this.props.history.push(this.props.lastLocation);
        } else {
            this.props.history.push("/rasters");
        }
    };

    render() {
        let { classes } = this.props;
        let zoom = `${this.state.raster.minZoom} - ${this.state.raster.maxZoom}`;

        return (
            <div className="sidebar-container raster-details">
                <div className="header">
                    <div className={classes.stylerHeader}>
                        <IconButton className={classes.backButton} onClick={this.onBack} size="large">
                            <img alt="" src={keyboardBackspace} />
                        </IconButton>
                        <OverflowTip variant="h2" className={classes.headerText}>
                            {this.state.raster.name}
                        </OverflowTip>
                    </div>
                </div>
                {this.props.fetching && <LinearProgress className="no-margin-progress" />}
                <div className="container">
                    <div className="page">
                        <div className="field copyable">
                            <TextField
                                id="filled-required"
                                label="Raster Name"
                                placeholder="Name of raster"
                                value={this.state.raster.name}
                                variant="filled"
                                onChange={this.onRasterNameChange}
                                fullWidth
                            />
                            <Button
                                TouchRippleProps={{ classes: { root: classes.buttonRipple } }}
                                className="copy-button"
                                onClick={() => {
                                    navigator.clipboard.writeText(this.state.raster.name);
                                    toastr.success("The text has been copied");
                                }}
                            >
                                <img alt="" src={fileCopyIcon} />
                            </Button>
                        </div>

                        <div className="field flex border-bottom">
                            <ReadOnlyField
                                label={"Creation Date"}
                                text={fromNowWithCheck(dayjs(this.state.raster.createdUtc))}
                                tooltipTitle={dayjs(this.state.raster.createdUtc).local().format("LLL")}
                                tooltip={true}
                            />
                            <ReadOnlyField
                                label={"Modified"}
                                text={fromNowWithCheck(dayjs(this.state.raster.modifiedUtc))}
                                tooltipTitle={dayjs(this.state.raster.modifiedUtc).local().format("LLL")}
                                tooltip={true}
                            />
                        </div>

                        <div className="field border-bottom copyable">
                            <ReadOnlyField label={"ID"} text={this.state.raster.id} />
                            <Button
                                TouchRippleProps={{ classes: { root: classes.buttonRipple } }}
                                className="copy-button"
                                onClick={() => {
                                    navigator.clipboard.writeText(this.state.raster.id);
                                    toastr.success("The text has been copied");
                                }}
                            >
                                <img alt="" src={fileCopyIcon} />
                            </Button>
                        </div>

                        <div className="field flex border-bottom">
                            <ReadOnlyField label={"Size"} text={this.state.raster.fileSize} />
                            <ReadOnlyField label={"Zoom"} text={zoom} />
                        </div>
                    </div>
                </div>
                <div className="container container--bottom">
                    <Button variant="contained" color="primary" onClick={() => this.updateRaster()}>
                        <SaveIcon className={classes.icon} />
                        Save Changes
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        fetching: state.rasterDetails.fetching
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRaster: (rasterId) => dispatch(rasterServiceActions.getRaster(rasterId)),
        updateRaster: (rasterId, request) => dispatch(rasterServiceActions.updateRaster(rasterId, request)),
        addMapSource: (source) => dispatch(mapActions.addMapSource(source)),
        addMapLayer: (layer) => dispatch(mapActions.addMapLayer(layer)),
        removeMapLayer: (layerId) => dispatch(mapActions.removeMapLayer(layerId)),
        mapFitBounds: (bbox, options) => dispatch(mapActions.fitMapBounds({ bounds: bbox, options }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(RasterEditView)));

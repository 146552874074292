import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as datasetsActions from "../../../actions/datasets";
import * as tableViewActions from "../../../actions/tableView";
import * as mapActions from "../../../reducers/map";
import * as notificationsActions from "../../../actions/notifications";
import * as metadataSchemaActions from "../../../actions/metadataSchema";
import * as googleDriveActions from "../../../actions/googleDrive";
import { getCacheStatus, getDatasetFetching } from "../../../selectors/datasetDetails";
import usePrevious from "../../../utils/customHooks/usePrevious";
import { IconButton, Tooltip } from "@mui/material";
import { StyledTabs, StyledTab } from "../../../components/CustomTabs/CustomTabs";
import toastr from "../../../components/CustomToastr/CustomToastr";
import * as NetworkErrorUtils from "../../../utils/networkErrorUtils";
import CachedIcon from "@mui/icons-material/Autorenew";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MetadataTab from "./components/MetadataTab/MetadataTab";

import Typography from "../../../components/CustomTypography/CustomTypography";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import * as EnumUtils from "../../../utils/enumUtils";
import { useTheme } from "@mui/styles";
import OverflowTip from "../../../components/OverflowTip/OverflowTip";
import keyboardBackspace from "../../../utils/icons/keyboard-backspace.svg";
import { useDatasetEditViewStyles } from "./styles";
import AdvancedTab from "./components/AdvancedTab/AdvancedTab";
import { Formik } from "formik";
import InfoTab from "./components/InfoTab/InfoTab";
import { DatasetEditViewSchema } from "../../../utils/validators/dataset";
import { metadataTypes } from "../../../utils/constants/metadataTypes";
import MenuAndModals from "./components/MenuAndModals/MenuAndModals";

const DatasetEditView = () => {
    const classes = useDatasetEditViewStyles();
    const theme = useTheme();

    const [dataset, setDataset] = useState({
        name: "",
        id: "",
        tableName: "",
        schemaName: "",
        tileName: "",
        createdUtc: "",
        databaseSize: "",
        geometryType: "",
        modifiedUtc: "",
        rowCount: "",
        cacheStatus: 2,
        minZoom: 0,
        maxZoom: 24,
        projection: 0
    });
    const [columns, setColumns] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [basicDataChanged, setBasicDataChanged] = useState(false);
    const [tileDataChanged, setTileDataChanged] = useState(false);
    const [columnsDataChanged, setColumnsDataChanged] = useState(false);

    const [tab, setTab] = useState("info");
    const [actionsAnchor, setActionsAnchor] = useState(null);
    const [hasDatasetLink, setHasDatasetLink] = useState(false);

    const [bbox, setBbox] = useState();

    const [datasetLoaded, setDatasetLoaded] = useState(false);

    const dispatch = useDispatch();
    const { datasetId } = useParams();

    const cacheStatus = useSelector(getCacheStatus);
    const fetching = useSelector(getDatasetFetching);

    const history = useHistory();
    const prevLocation = history.location.state?.prevLocation;
    const previousCacheStatus = usePrevious(cacheStatus);

    useEffect(() => {
        const datasetPromise = dispatch(datasetsActions.getDatasetDetails(datasetId));
        const columnsPromise = dispatch(datasetsActions.getDatasetColumns(datasetId));
        const datasetLinkPromise = dispatch(googleDriveActions.getDatasetLink(datasetId));
        const metadataSchemaPromise = dispatch(metadataSchemaActions.getSchema(datasetId));

        Promise.all([datasetPromise, columnsPromise, datasetLinkPromise, metadataSchemaPromise]).then(
            (res) => {
                let datasetResult = res[0].result;
                let columnsResult = res[1].result;
                let hasDatasetLink = res[2].result.status === 200;
                let metadataSchemaResult = res[3].result.schema;

                init(datasetResult, columnsResult, metadataSchemaResult, hasDatasetLink);
                dispatch(tableViewActions.setDataset(datasetResult.id));
            },
            (err) => {
                history.push("/datasets");
                NetworkErrorUtils.handleError(err);
            }
        );

        return () => {
            dispatch(mapActions.removeMapLayer(datasetId));
            dispatch(mapActions.resetMapFlags());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (cacheStatus === 2 && previousCacheStatus === 1) refreshMapLayer();
        if (cacheStatus === 1 && previousCacheStatus === 2) removeMapLayer();
        setDataset({ ...dataset, cacheStatus });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cacheStatus]);

    const getLayerType = (type) => {
        switch (type) {
            case "POLYGON":
            case "MULTIPOLYGON":
                return "fill";
            case "POINT":
            case "MULTIPOINT":
                return "circle";
            case "LINESTRING":
            case "MULTILINESTRING":
                return "line";
            default:
                return "circle";
        }
    };

    const init = (paramDataset, paramColumns, metadataSchema, hasDatasetLink) => {
        const datasetMetadataMap = paramDataset.metadata.reduce((a, b) => {
            a[b.id] = b;
            return a;
        }, {});

        const metadataSchemaMap = metadataSchema.reduce((a, b) => {
            a[b.id] = b;
            return a;
        }, {});

        const datasetMetadataTagMap = paramDataset.metadata.reduce((a, b) => {
            if (!metadataSchemaMap.hasOwnProperty(b.id)) {
                return a;
            }
            if (!(metadataSchemaMap[b.id].type === metadataTypes.TAG_LIST)) {
                return a;
            }
            b.value.forEach((tag) => {
                a[tag.id] = tag;
            });
            return a;
        }, {});

        for (let i = 0; i < metadataSchema.length; i++) {
            let metadata = metadataSchema[i];
            if (!datasetMetadataMap.hasOwnProperty(metadata.id)) {
                continue;
            }
            if (metadata.type === metadataTypes.TAG_LIST) {
                metadata.value.forEach((tag) => {
                    tag.enabled = datasetMetadataTagMap.hasOwnProperty(tag.id) ? datasetMetadataTagMap[tag.id].enabled : false;
                });
            } else {
                metadata.value = datasetMetadataMap[metadata.id].value;
            }
        }

        let layerType = getLayerType(paramDataset.geometryType);

        let bbox = {
            minLon: paramDataset.bounds.coordinates[0][0][0],
            minLat: paramDataset.bounds.coordinates[0][0][1],
            maxLon: paramDataset.bounds.coordinates[0][2][0],
            maxLat: paramDataset.bounds.coordinates[0][2][1]
        };

        setColumns(paramColumns);
        setDataset(paramDataset);
        setBbox(bbox);
        setMetadata(metadataSchema);
        setHasDatasetLink(hasDatasetLink);

        setDatasetLoaded(true);

        if (paramDataset.cacheStatus === 2) {
            dispatch(
                mapActions.addMapSource({
                    id: paramDataset.id,
                    minZoom: paramDataset.minZoom,
                    maxZoom: paramDataset.maxZoom,
                    type: "vector"
                })
            );
            dispatch(
                mapActions.addMapLayer({
                    sourceId: paramDataset.id,
                    layerId: paramDataset.id,
                    resourceId: paramDataset.id,
                    sourceName: paramDataset.tileName,
                    type: layerType,
                    sourceMinZoom: paramDataset.minZoom,
                    sourceMaxZoom: paramDataset.maxZoom,
                    minZoom: paramDataset.minZoom,
                    maxZoom: 24
                })
            );
            dispatch(
                mapActions.addMapPaint({
                    layerId: paramDataset.id,
                    properties: [
                        {
                            name: layerType + "-color",
                            value: theme.customColors.primaryColor
                        }
                    ]
                })
            );
        }
        dispatch(
            mapActions.fitMapBounds({
                bounds: [paramDataset.bounds.coordinates[0][0], paramDataset.bounds.coordinates[0][2]],
                options: {
                    padding: { top: 45, bottom: 45, left: 45, right: 45 },
                    animate: false
                }
            })
        );
    };

    const removeMapLayer = () => {
        dispatch(mapActions.removeMapLayer(datasetId));
    };

    const refreshMapLayer = () => {
        let layerType = getLayerType(dataset.geometryType);

        dispatch(mapActions.removeMapLayer(datasetId));
        dispatch(
            mapActions.addMapSource({
                id: dataset.id,
                minZoom: dataset.minZoom,
                maxZoom: dataset.maxZoom,
                type: "vector"
            })
        );
        dispatch(
            mapActions.addMapLayer({
                sourceId: dataset.id,
                layerId: dataset.id,
                sourceName: dataset.tileName,
                type: layerType,
                sourceMinZoom: dataset.minZoom,
                sourceMaxZoom: dataset.maxZoom,
                minZoom: dataset.minZoom,
                maxZoom: 24
            })
        );
        dispatch(
            mapActions.addMapPaint({
                layerId: dataset.id,
                properties: [
                    {
                        name: layerType + "-color",
                        value: theme.customColors.primaryColor
                    }
                ]
            })
        );
    };

    const onRemoveDatasetLink = () => {
        dispatch(googleDriveActions.UnlinkDataset(dataset.id)).then(
            (res) => {
                setHasDatasetLink(false);
                toastr.success("Dataset unlinked");
            },
            (err) => NetworkErrorUtils.handleError(err)
        );
    };

    const onLinkDatasetToDrive = () => {
        dispatch(googleDriveActions.LinkDataset(dataset.id)).then(
            (res) => {
                setHasDatasetLink(true);
                toastr.success("Dataset linked");
            },
            (err) => NetworkErrorUtils.handleError(err)
        );
    };

    const changeDatasetSchemaName = (schemaName) => {
        setDataset({
            ...dataset,
            schemaName
        });
    };

    const changePage = (page) => {
        setTab(page);
    };

    const onOpenActions = (e) => {
        setActionsAnchor(e.currentTarget);
    };

    const onCloseActions = () => {
        setActionsAnchor(null);
    };

    const cacheStatusToClass = (cacheStatus) => {
        return EnumUtils.toCacheStatusString(cacheStatus);
    };

    const cacheStatusTokens = {
        cached: "cached",
        uncached: "uncached"
    };

    const getCacheStatusColor = () => {
        switch (cacheStatusToClass(cacheStatus)) {
            case cacheStatusTokens.cached:
                return "#149B62";
            case cacheStatusTokens.uncached:
                return "#EC3C3E";
            default:
                return "#F67500";
        }
    };

    const onBack = () => {
        history.push(prevLocation || "/datasets");
    };

    const cleanupMetadata = (metadata) => {
        return metadata.map((m) => {
            let value = m.value;

            if (m.type === metadataTypes.TAG_LIST) {
                value = value.map((tag) => {
                    return {
                        id: tag.id,
                        enabled: !!tag.enabled
                    };
                });
            }
            if (m.type === metadataTypes.DATE) {
                value = value?.toString() || null;
            }
            return {
                id: m.id,
                value
            };
        });
    };

    const basicChangesPromise = ({ name, metadata }) => {
        return dispatch(
            datasetsActions.updateDataset(dataset.id, {
                name,
                jsonMetadata: JSON.stringify(cleanupMetadata(metadata))
            })
        ).then(() => {
            setDataset((dataset) => ({ ...dataset, name }));
            setMetadata(metadata);
        });
    };

    const tileChangesPromise = ({ tileName, minZoom, maxZoom }) => {
        return dispatch(
            datasetsActions.updateDatasetAdvanced(dataset.id, {
                tileName,
                minZoom,
                maxZoom
            })
        ).then(() => {
            setDataset((dataset) => ({ ...dataset, tileName, minZoom, maxZoom }));
        });
    };

    const columnsChangesPromise = (columns) => {
        return dispatch(datasetsActions.updateDatasetColumns(dataset.id, columns)).then((res) => {
            setColumns(res.data);
        });
    };

    const onDatasetSave = ({ columns, datasetName, minZoom, maxZoom, tileName, ...metadataValues }) => {
        const updatedMetadata = metadata.map((m) => {
            let value = metadataValues[m.name];
            if (m.type === metadataTypes.DATE) {
                value = value?.toString() || null;
            }
            return {
                ...m,
                value
            };
        });

        const promises = [];

        if (basicDataChanged) {
            const datasetInfoPromise = basicChangesPromise({
                name: datasetName,
                metadata: updatedMetadata
            });
            promises.push(datasetInfoPromise);
        }
        if (tileDataChanged) {
            const datasetTilePromise = tileChangesPromise({ tileName, minZoom, maxZoom });
            promises.push(datasetTilePromise);
        }
        if (columnsDataChanged) {
            const columnsPromise = columnsChangesPromise(columns);
            promises.push(columnsPromise);
        }

        Promise.all(promises)
            .then(() => {
                if (tileDataChanged || columnsDataChanged) {
                    dispatch(datasetsActions.generateCache(dataset.id));
                    dispatch(notificationsActions.resetNotification(dataset.name, dataset.id));
                }

                toastr.success(`${dataset.name} has been successfully saved`);
                setBasicDataChanged(false);
                setTileDataChanged(false);
                setColumnsDataChanged(false);
            })
            .catch((err) => NetworkErrorUtils.handleError(err));
    };

    const getMetadataInitialValue = (metadata) => {
        if (metadata.type === metadataTypes.DATE) return metadata.value ? new Date(metadata.value) : null;
        return metadata.value;
    };

    const saveButtonTooltip = (errors) => {
        if (!basicDataChanged && !columnsDataChanged && !tileDataChanged) return "No changes were made";
        const { columns, datasetName, minZoom, maxZoom, tileName, ...metadata } = errors;

        let errorText = "Before saving, correct the errors on the following tabs:";
        const tabsWithProblems = [];
        if (Object.keys(errors).length) {
            if (errors.datasetName) {
                tabsWithProblems.push("Info");
            }

            if (Object.keys(metadata).length) tabsWithProblems.push("Metadata");

            if (minZoom || maxZoom || tileName || columns) tabsWithProblems.push("Advanced");
            return (
                <div>
                    {errorText}
                    {tabsWithProblems.map((tab) => (
                        <div key={tab}>- {tab}</div>
                    ))}
                </div>
            );
        }
        return "";
    };

    return (
        <div className="sidebar-container dataset-details">
            <div className="header">
                <div className={classes.stylerHeader}>
                    <IconButton className={classes.backButton} onClick={onBack} size="large">
                        <img alt="" src={keyboardBackspace} />
                    </IconButton>
                    <OverflowTip variant="h2" className={classes.headerText}>
                        {dataset.name}
                    </OverflowTip>
                </div>
                <div className={"cache-status-container " + cacheStatusToClass(cacheStatus)}>
                    <CachedIcon className={`flip ${classes.cacheIcon}`} />
                    <Typography variant="body2" fontWeight="semibold" color={getCacheStatusColor()}>
                        {cacheStatusToClass(cacheStatus)}
                    </Typography>
                </div>
            </div>
            {fetching && <LinearProgress className="no-margin-progress" />}
            <StyledTabs value={tab} TabIndicatorProps={<div />}>
                <StyledTab label="info" onClick={() => changePage("info")} value="info" />
                <StyledTab label="metadata" onClick={() => changePage("metadata")} value="metadata" />
                <StyledTab label="advanced" onClick={() => changePage("advanced")} value="advanced" />
            </StyledTabs>
            <Formik
                enableReinitialize={!datasetLoaded}
                initialValues={{
                    columns,
                    datasetName: dataset.name,
                    minZoom: dataset.minZoom,
                    maxZoom: dataset.maxZoom,
                    tileName: dataset.tileName,
                    ...metadata.reduce((acc, metadata) => {
                        acc[metadata.name] = getMetadataInitialValue(metadata);
                        return acc;
                    }, {})
                }}
                onSubmit={(values) => {
                    if (columnsDataChanged || tileDataChanged) {
                        const toastrConfirmOptions = {
                            onOk: () => onDatasetSave(values),
                            onCancel: () => {}
                        };
                        toastr.confirm("The changes will require tile regeneration in order tot take effect.", toastrConfirmOptions);
                    } else {
                        onDatasetSave(values);
                    }
                }}
                validationSchema={DatasetEditViewSchema(metadata)}
            >
                {({ submitForm, errors }) => (
                    <>
                        <div className="container">
                            <div className="page">
                                <div className="info-section">
                                    <InfoIcon className="info-section__icon" />
                                    <Typography variant="body2" fontWeight="semibold" color={theme.customColors.secondaryTextColor}>
                                        The changes made to this dataset will impact all the applications that contain this dataset.
                                    </Typography>
                                </div>
                                {tab === "info" && <InfoTab dataset={dataset} setBasicDataChanged={setBasicDataChanged} />}
                                {tab === "metadata" && <MetadataTab metadata={metadata} setBasicDataChanged={setBasicDataChanged} />}
                                {tab === "advanced" && <AdvancedTab bbox={bbox} setTileDataChanged={setTileDataChanged} setColumnsDataChanged={setColumnsDataChanged} />}
                            </div>
                        </div>

                        <div className="container container--bottom">
                            <Tooltip title={saveButtonTooltip(errors)}>
                                <div>
                                    <Button
                                        disabled={!!Object.keys(errors).length || (!basicDataChanged && !columnsDataChanged && !tileDataChanged)}
                                        className={classes.saveButton}
                                        variant="contained"
                                        onClick={submitForm}
                                    >
                                        <SaveIcon />
                                        Save Changes
                                    </Button>
                                </div>
                            </Tooltip>

                            <Button
                                TouchRippleProps={{ classes: { root: classes.buttonRipple } }}
                                className={classes.actionButton}
                                style={{ marginRight: 8 }}
                                onClick={onOpenActions}
                            >
                                <MoreHorizIcon style={{ marginRight: 8, marginLeft: -8 }} />
                                Actions
                            </Button>
                        </div>
                    </>
                )}
            </Formik>
            <MenuAndModals
                dataset={dataset}
                columns={columns}
                hasDatasetLink={hasDatasetLink}
                onLinkDatasetToDrive={onLinkDatasetToDrive}
                onRemoveDatasetLink={onRemoveDatasetLink}
                actionsAnchor={actionsAnchor}
                setActionsAnchor={setActionsAnchor}
                onCloseActions={onCloseActions}
                changeDatasetSchemaName={changeDatasetSchemaName}
            />
        </div>
    );
};

export default DatasetEditView;

import { createAction } from "@reduxjs/toolkit";
import { getStylesOfLayer } from "../selectors/appData";
import * as appDataActions from "../reducers/appData/appData";
import * as mapActions from "../reducers/map";

//{groupId,newVisibility,layersMap}
export const toggleGroupLayers = createAction("global/toggleGroupLayers");

export const toggleAppLayer = (layerId, newVisibility) => (dispatch, getState) => {
    const layerStyles = getStylesOfLayer(layerId)(getState());

    layerStyles.forEach((s) => {
        dispatch(
            mapActions.updateMapLayout({
                layerId: s.styleId,
                properties: [
                    {
                        name: "visibility",
                        value: newVisibility ? "visible" : "none"
                    }
                ]
            })
        );
    });

    dispatch(appDataActions.setLayerVisibility({ layerId, newVisibility }));
};

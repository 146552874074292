import reducers from "./reducers/index";
import authMiddleware from "./middleware/authMiddleware";
import signalRMiddleware from "./middleware/signalRMiddleware";
import thunk from "redux-thunk";

import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";

let history = createBrowserHistory({
    /* pass a configuration object here if needed */
});

const store = configureStore({
    reducer: { ...reducers },
    middleware: [authMiddleware, thunk, signalRMiddleware],
    devTools: process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION__
});

export { store, history };

import { makeStyles } from "@mui/styles";

export const useDatasetsTableStyles = makeStyles((theme) => ({
    searchBarWidth: {
        maxWidth: "35%"
    },
    tableContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto"
    },
    marginLeft: {
        marginLeft: 4
    },
    infoColor: {
        opacity: 0.5
    },
    errorInfoColor: {
        color: theme.palette.error.main,
        opacity: 0.8
    },
    countText: {
        fontSize: 17
    },
    searchContainer: {
        display: "flex",
        flexWrap: "nowrap"
    }
}));

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import * as datasetActions from "../../../../../actions/datasets";
import { LayerStyleEditor } from "@emblautec/layer-styler-component";

import { getMapSources, getSourceMinZoomSelector } from "../../../../../selectors/map";
import { getDatasets } from "../../../../../selectors/datasets";
import * as mapActions from "../../../../../reducers/map";
import * as appDataActions from "../../../../../reducers/appData/appData";
import { getRasters } from "../../../../../selectors/rasters";
import { getLayerVisibility, getSelectedLayer, getStylesOfLayer } from "../../../../../selectors/appData";
import * as GeometryUtils from "../../../../../utils/GeometryUtils";
import getRasterInitProperties from "../../../../../utils/constants/getRasterInitProperties";
import { getStyleConfig } from "../../../../../selectors/style";

import { STYLE_TYPES } from "../../../../../utils/constants/layers/styleTypes";

const CHARACTER_VARYING = "character varying";
const DOUBLE_PRECISION = "double precision";

const LayerStyler = () => {
    const [datasetColumns, setDatasetColumns] = useState([]);
    const [selectedDatasetColumn, setSelectedDatasetColumn] = useState(null);
    const [distinctColumnValues, setDistinctColumnValues] = useState([]);
    const [minMaxColumnValues, setMinMaxColumnValues] = useState([]); //[min,max]

    const datasets = useSelector(getDatasets);
    const rasters = useSelector(getRasters);
    const selectedLayer = useSelector(getSelectedLayer);
    const sources = useSelector(getMapSources);
    const sourceMinZoomLimit = useSelector(getSourceMinZoomSelector(selectedLayer.sourceId));
    const styles = useSelector(getStylesOfLayer(selectedLayer.resourceId));
    const visible = useSelector(getLayerVisibility(selectedLayer.resourceId));
    const styleConfig = useSelector(getStyleConfig);

    const dispatch = useDispatch();

    useEffect(() => {
        //Based on the selected layer we bring the dataset columns and by default select the first column
        if (!selectedLayer) {
            setDatasetColumns([]);
            setSelectedDatasetColumn(null);
            setDistinctColumnValues([]);
            setMinMaxColumnValues([]);
        }
        if (selectedLayer) {
            dispatch(datasetActions.getDatasetColumns(selectedLayer.resourceId)).then((res) => {
                setDatasetColumns(res.result);
                setSelectedDatasetColumn(res.result[0]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLayer.resourceId]);

    useEffect(() => {
        //Based on the selected column we bring the distinct values and min,max pair
        if (selectedDatasetColumn) {
            const columnName = selectedDatasetColumn.name;
            const selectedDatasetId = selectedLayer.resourceId;
            if (selectedDatasetColumn.type !== DOUBLE_PRECISION) {
                dispatch(datasetActions.getDistinctColumnValues(selectedDatasetId, columnName)).then((res) => {
                    setDistinctColumnValues(res.result);
                });
            }

            if (selectedDatasetColumn.type !== CHARACTER_VARYING) {
                dispatch(datasetActions.getMinMaxColumnValues(selectedDatasetId, columnName)).then((res) => {
                    setMinMaxColumnValues([res.result.minValue, res.result.maxValue]);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDatasetColumn]);

    const onAddStyleToLayer = (symbolStyle = false) => {
        if (selectedLayer.type === STYLE_TYPES.raster) addStyleToRasterLayer();
        else addStyleToVectorLayer(symbolStyle);
    };

    const getLayerCachedSourceId = (layer) => {
        const layerSource = sources.find((source) => source.id === layer.sourceId);
        return layerSource.cacheStatus === 2 ? layer.sourceId : layer.resourceId;
    };

    const addStyleToVectorLayer = (symbolStyle) => {
        const dataset = datasets.filter((x) => x.id === selectedLayer.resourceId)[0];
        const layerType = symbolStyle ? STYLE_TYPES.symbol : GeometryUtils.geometryTypeToMapboxType(selectedLayer.geometryType);
        const initialProperties = styleConfig[layerType];
        const layerId = uuidv4();

        const newStyleSourceId = getLayerCachedSourceId(selectedLayer);

        const style = {
            styleId: layerId,
            sourceName: selectedLayer.sourceName,
            minZoom: dataset.minZoom,
            maxZoom: 24,
            properties: initialProperties,
            type: layerType
        };
        dispatch(
            mapActions.addMapLayer({
                sourceId: newStyleSourceId,
                layerId,
                resourceId: selectedLayer.resourceId,
                sourceName: selectedLayer.sourceName,
                type: style.type,
                minZoom: style.minZoom,
                maxZoom: style.maxZoom
            })
        );
        initializeNewStyle(layerId, initialProperties, visible);
        dispatch(mapActions.addMapZoomRange({ layerId, minZoom: dataset.minZoom, maxZoom: 24 }));

        dispatch(appDataActions.addStyleToAppLayer(style));
    };

    const addStyleToRasterLayer = (layer) => {
        const raster = rasters.filter((x) => x.id === layer.resourceId)[0];
        const layerId = uuidv4();

        const initialProperties = getRasterInitProperties();

        const style = {
            styleId: layerId,
            sourceName: layer.sourceName,
            minZoom: raster.minZoom,
            maxZoom: 24,
            properties: initialProperties,
            type: "raster"
        };

        const newStyleSourceId = getLayerCachedSourceId(layer);
        dispatch(
            mapActions.addMapLayer({
                sourceId: newStyleSourceId,
                layerId: layerId,
                resourceId: selectedLayer.resourceId,
                sourceName: raster.name,
                type: layer.type,
                minZoom: style.minZoom,
                maxZoom: style.maxZoom
            })
        );
        dispatch(
            mapActions.addMapLayout({
                layerId: layerId,
                properties: [
                    {
                        name: "visibility",
                        value: visible ? "visible" : "none"
                    }
                ]
            })
        );
        dispatch(mapActions.addMapZoomRange({ layerId, minZoom: 0, maxZoom: 24 }));
        dispatch(appDataActions.addStyleToAppLayer(style));
    };

    const onRemoveStyleFromLayer = (styleLayer) => {
        dispatch(mapActions.removeMapLayer(styleLayer.styleId));
        dispatch(appDataActions.removeStyleFromAppLayer(styleLayer.styleId));
    };

    const onStyleTypeChanged = (type, style) => {
        let properties = JSON.parse(JSON.stringify(styleConfig[type]));
        const layer = selectedLayer;
        //Todo. make sure order stays the same

        const newStyleSourceId = getLayerCachedSourceId(layer);

        dispatch(
            mapActions.updateMapLayer({
                sourceId: newStyleSourceId,
                layerId: style.styleId,
                resourceId: layer.resourceId,
                sourceName: layer.sourceName,
                type,
                minZoom: style.minZoom,
                maxZoom: style.maxZoom
            })
        );

        initializeNewStyle(style.styleId, properties, true);
        dispatch(appDataActions.changeStyleTypeOfAppLayer({ styleId: style.styleId, properties, type }));
    };

    const initializeNewStyle = (layerId, properties, startEnabled) => {
        const paintProperties = properties.filter((x) => x.type === "paint");

        const paint = {
            layerId: layerId,
            properties: paintProperties
        };

        const layoutProperties = properties.filter((x) => x.type === "layout");
        const containsVisibility = layoutProperties.find((property) => property.name === "visibility");

        if (!containsVisibility) {
            layoutProperties.push({
                type: "layout",
                name: "visibility",
                value: startEnabled ? "visible" : "none"
            });
        }

        const layout = {
            layerId: layerId,
            properties: layoutProperties
        };
        dispatch(mapActions.addMapPaint(paint));
        dispatch(mapActions.addMapLayout(layout));
    };

    const onZoomSliderChange = (style, zoom) => {
        dispatch(mapActions.updateMapZoomRange({ layerId: style.styleId, minZoom: zoom[0], maxZoom: zoom[1] }));

        dispatch(appDataActions.changeZoomLimitsOfAppLayer({ styleId: style.styleId, minZoom: zoom[0], maxZoom: zoom[1] }));
    };

    const onStyleDrop = (styleId, beforeStyleId) => {
        dispatch(mapActions.moveLayer({ layerId: styleId, beforeLayerId: beforeStyleId }));

        dispatch(
            appDataActions.changeStyleOrder({
                layerId: selectedLayer.resourceId,
                styleId,
                beforeStyleId
            })
        );
    };

    const onPropertiesChanged = (style, newProperties) => {
        dispatch(appDataActions.changePropertiesOfAppLayer({ styleId: style.styleId, newProperties }));
        updateMap(style, newProperties);
    };

    const updateMap = (style, properties) => {
        const paintProperties = properties.filter((x) => x.type === "paint");

        const paint = {
            layerId: style.styleId,
            properties: paintProperties
        };

        const layoutProperties = properties.filter((x) => x.type === "layout");
        //This is for copy pasted styles
        const containsVisibility = layoutProperties.find((property) => property.name === "visibility");

        if (!containsVisibility) {
            layoutProperties.push({
                type: "layout",
                name: "visibility",
                value: visible ? "visible" : "none"
            });
        }

        const layout = {
            layerId: style.styleId,
            properties: layoutProperties
        };
        dispatch(mapActions.updateMapPaint(paint));
        dispatch(mapActions.updateMapLayout(layout));
    };

    return (
        <LayerStyleEditor
            layer={{ ...selectedLayer, styles: styles.map((style, index) => ({ ...style, index })) }}
            isDigitizeLayer={false}
            styleConfig={styleConfig}
            //column stuff
            minZoomLimit={sourceMinZoomLimit ?? 0}
            columns={datasetColumns}
            selectedDatasetColumn={selectedDatasetColumn}
            setSelectedDatasetColumn={setSelectedDatasetColumn}
            distinctColumnValues={distinctColumnValues}
            minMaxColumnValues={minMaxColumnValues}
            //onHandlers
            onPropertiesChanged={onPropertiesChanged}
            onPropertyExpressionTypeChanged={onStyleTypeChanged}
            onTypeChanged={onStyleTypeChanged}
            onZoomSliderChange={onZoomSliderChange}
            onAddStyleToLayer={onAddStyleToLayer}
            onRemoveStyleFromLayer={onRemoveStyleFromLayer}
            onStyleDrop={onStyleDrop}
        />
    );
};

export default LayerStyler;

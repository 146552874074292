import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/MoreHoriz";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "../../../../components/CustomTypography/CustomTypography";
import { useTheme } from "@mui/styles";

export default function User(props) {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);

    const onMenuClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const onDeleteClick = (e) => {
        onMenuClose(e);
        e.stopPropagation();
        props.onDeleteClick(props.user);
    };

    return (
        <div className={props.selected ? "item selected" : "item"} onClick={() => props.OnClick(props.user)}>
            <div className="container">
                <div className="row-container">
                    <div className={"row-1"}>
                        <div className="name">
                            <Typography variant="body2" fontWeight="semibold" color={theme.customColors.primaryColor}>
                                {props.user.username}
                            </Typography>
                        </div>
                        <div className="roles">
                            <Typography variant="body2">{props.user.roles}</Typography>
                        </div>

                        <Tooltip id="tooltip-top" title="Options">
                            <div className="button" onClick={onMenuClick}>
                                <MenuIcon />
                            </div>
                        </Tooltip>
                    </div>
                </div>

                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
                    <MenuItem disabled={props.disabled} onClick={onDeleteClick} className="menu-button">
                        <DeleteIcon className="icon" />
                        Delete
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
}

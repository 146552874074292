import { Table, TableBody, TablePagination, TableRow, TableCell } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";
import CustomTableHeader from "./components/CustomTableHead/CustomTableHead";
import { useCustomTableStyles } from "./styles";

const CustomTable = ({ data, fetchingData, columns, generateItem, hideBottomBar, tableSize, additionalActionColumn }) => {
    const classes = useCustomTableStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [sorting, setSorting] = useState(columns.filter((x) => x.start)[0].name);
    const [sortingReverse, setSortingReverse] = useState(false);

    const [isBarVisible, setIsBarVisible] = useState(false);
    const bottomBarRef = React.createRef();

    const sortings = useMemo(
        () =>
            columns.reduce((a, b) => {
                a[b.name] = b.sortingFunction;
                return a;
            }, {}),
        [columns]
    );

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            const bottomBar = entries[0];
            if (bottomBar.target.scrollHeight && bottomBar.target.clientHeight && bottomBar.target.scrollWidth && bottomBar.target.clientWidth)
                setIsBarVisible(bottomBar.target.scrollHeight > bottomBar.target.clientHeight && !(bottomBar.target.scrollWidth > bottomBar.target.clientWidth));
        });
        const oldRef = bottomBarRef.current;
        observer.observe(oldRef);
        return () => oldRef && observer.unobserve(oldRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBarVisible]);

    useEffect(() => {
        if (page > 0) setPage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onSetSort = (column) => {
        setSortingReverse(sorting === column.name ? !sortingReverse : false);
        setSorting(column.name);
    };

    const onRowsPerPageChange = (e) => {
        setPage(Math.floor((page * rowsPerPage) / e.target.value));
        setRowsPerPage(e.target.value);
        bottomBarRef.current.scrollTop = 0;
    };

    const handlePageChange = (page) => {
        setPage(page);
        bottomBarRef.current.scrollTop = 0;
    };

    const sliceStart = page * rowsPerPage;
    const sliceEnd = page * rowsPerPage + rowsPerPage;

    const items = data
        .sort(sortings[sorting](sortingReverse))
        .slice(sliceStart, sliceEnd)
        .map((item, index) => generateItem(item, index));

    const numOfColumns = columns.length + !!additionalActionColumn;

    return (
        <div className="list-view">
            <div className="pagination">
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                        "aria-label": "Next Page"
                    }}
                    onPageChange={(e, page) => handlePageChange(page)}
                    onRowsPerPageChange={onRowsPerPageChange}
                />
            </div>
            <div className="scroll-container" ref={bottomBarRef}>
                <Table stickyHeader size={tableSize}>
                    <CustomTableHeader columns={columns} sorting={sorting} onSetSort={onSetSort} sortingReverse={sortingReverse} additionalActionColumn={additionalActionColumn} />
                    <TableBody>
                        {!fetchingData &&
                            (items.length ? (
                                items
                            ) : (
                                <TableRow className={classes.noDataIndicator}>
                                    <TableCell className={classes.noDataText} colSpan={numOfColumns} align="center" bold={600}>
                                        No data
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <LoadingPlaceholder loading={fetchingData} message={"Getting your data"} />
            </div>
            {isBarVisible && !hideBottomBar && <div className="bottom-container" />}
        </div>
    );
};

CustomTable.defaultProps = {
    hideBottomBar: false,
    tableSize: "medium",
    additionalActionColumn: true
};

export default CustomTable;

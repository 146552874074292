import React from "react";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import EditView from "../../groupEditView/groupEditView";

const EditModal = ({ open, handleClose, groupId }) => {
    return (
        <CustomModal handleClose={handleClose} isOpen={open} dialogTitle={"Edit Group"} hideActionContainer>
            <EditView groupId={groupId} />
        </CustomModal>
    );
};

export default EditModal;

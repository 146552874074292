import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";

import toastr from "../CustomToastr/CustomToastr";
import { handleError } from "../../utils/networkErrorUtils";

import * as datasetsActions from "../../actions/datasets";

import FileLoader from "./fileLoader";

import { Typography, LinearProgress } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CustomModal from "../CustomModal/CustomModal";
import { ID_COLUMN } from "../../utils/constants/columns";

const styles = (theme) => ({});

class Replacer extends Component {
    constructor(props) {
        super(props);

        this.geojsonData = null;

        this.state = {
            file: null,
            errors: [],
            uploading: false,
            uploadProgressPercentage: 0
        };
    }

    onFileChanged = (file, data) => {
        this.setState({
            file: file,
            errors: []
        });
    };

    handleClose = () => {
        if (this.state.uploading) return;

        this.props.handleClose();
        this.setState({
            file: null
        });
    };

    validateGeoJsonProperties(properties) {
        let errors = [];
        let columnsTocheck = this.props.columnDefinitions.filter((x) => x.name !== ID_COLUMN);

        for (let i = 0; i < columnsTocheck.length; i++) {
            let column = columnsTocheck[i];

            if (!properties.hasOwnProperty(column.name)) {
                errors.push(column.name);
            }
        }

        return errors;
    }

    onReplaceDataset = () => {
        this.setState({
            uploading: true
        });

        let formData = new FormData();

        formData.append("file", this.state.file);
        formData.append("generateCache", false);

        let config = {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({
                    uploadProgressPercentage: percentCompleted
                });
            }
        };

        this.props.replaceDataset(this.props.datasetId, formData, config).then(
            (res) => {
                toastr.success("Data Replaced");
                this.props.handleClose();
                this.setState({
                    uploading: false,
                    file: null,
                    uploadProgressPercentage: 0
                });
            },
            (err) => {
                handleError(err);
                this.setState({
                    uploading: false,
                    file: null,
                    uploadProgressPercentage: 0
                });
            }
        );
    };

    onCancelPreUpload = () => {
        this.setState({
            file: null
        });
    };

    render() {
        let errors = this.state.errors.map((error, index) => {
            return (
                <div className="error">
                    <Typography variant="body2" id="transition-modal-title" className="text">
                        Missing column: {error}
                    </Typography>
                    <ErrorOutlineIcon className="icon" />
                </div>
            );
        });

        return (
            <CustomModal
                handleClose={this.handleClose}
                isOpen={this.props.open}
                onConfirm={this.onReplaceDataset}
                dialogTitle={"Replace"}
                dialogType={"upload"}
                disabled={!this.state.file}
            >
                <div className="container">
                    <div className="page">
                        <div className="file-upload-container">
                            <div className="errors">{errors}</div>
                            {!this.state.uploading && <FileLoader accept=".geojson" onFileChanged={this.onFileChanged} onError={this.onFileError} />}
                            {this.state.uploading && (
                                <div className="progress-page">
                                    <Typography variant="h6" id="transition-modal-title" className="text">
                                        Uploading
                                    </Typography>
                                    <Typography variant="body1" id="transition-modal-title" className="text">
                                        {this.state.file.name}
                                    </Typography>
                                    <Typography variant="body1" id="transition-modal-title" className="text">
                                        {this.state.uploadProgressPercentage}%
                                    </Typography>
                                </div>
                            )}
                            <LinearProgress className="progress" variant="determinate" value={this.state.uploadProgressPercentage} />
                        </div>
                    </div>
                </div>
            </CustomModal>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        replaceDataset: (datasetId, formData, config) => dispatch(datasetsActions.replaceDataset(datasetId, formData, config))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Replacer));

import GeometryTypeIcon from "@mui/icons-material/Category";

function sortByName(dataset1, dataset2) {
    return dataset1.name.localeCompare(dataset2.name);
}

function sortByNameReverse(dataset1, dataset2) {
    return dataset2.name.localeCompare(dataset1.name);
}

function sortBySchema(dataset1, dataset2) {
    return dataset1.schemaName.localeCompare(dataset2.schemaName);
}

function sortBySchemaReverse(dataset1, dataset2) {
    return dataset2.schemaName.localeCompare(dataset1.schemaName);
}

function sortByGeometryType(dataset1, dataset2) {
    return dataset1.geometryType.localeCompare(dataset2.geometryType);
}

function sortByGeometryTypeReverse(dataset1, dataset2) {
    return dataset2.geometryType.localeCompare(dataset1.geometryType);
}

const datasetColumns = [
    {
        type: "geometry",
        name: "geometry type",
        icon: <GeometryTypeIcon />,
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByGeometryTypeReverse : sortByGeometryType)
    },

    {
        type: "schema",
        name: "schema",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortBySchemaReverse : sortBySchema)
    },
    {
        type: "name",
        name: "name",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByNameReverse : sortByName),
        start: true
    }
];
export default datasetColumns;

import { isGroup } from "@emblautec/rescursive-array-extensions";

const moveResourceActionFunction = ({ layerGroups, layerVisibilityMap }, { payload: { resourceId, destinationId, moveAsChild, moveAbove } }) => {
    const resource = layerGroups.getRecursive(resourceId);

    //Substract from the counts of the old position parents
    if (isGroup(resource)) {
        layerGroups.forParentsRecursive(resourceId, (parent) => {
            parent.totalLayersCount -= resource.totalLayersCount;
            parent.visibleLayersCount -= resource.visibleLayersCount;
        });
    } else {
        layerGroups.forParentsRecursive(resourceId, (parent) => {
            parent.totalLayersCount -= 1;
            parent.visibleLayersCount -= layerVisibilityMap[resourceId] ? 1 : 0;
        });
    }

    if (!layerGroups.isChildOf(destinationId, resourceId)) {
        layerGroups.removeOneRecursive(resourceId);
        if (moveAsChild) {
            layerGroups.addChildRecursive(destinationId, resource);
        } else {
            layerGroups.addElementRecursive(destinationId, resource, moveAbove);
        }
    }

    //Add to the counts of the new position parents
    if (isGroup(resource)) {
        layerGroups.forParentsRecursive(resourceId, (parent) => {
            parent.totalLayersCount += resource.totalLayersCount;
            parent.visibleLayersCount += resource.visibleLayersCount;
        });
    } else {
        layerGroups.forParentsRecursive(resourceId, (parent) => {
            parent.totalLayersCount += 1;
            parent.visibleLayersCount += layerVisibilityMap[resourceId] ? 1 : 0;
        });
    }
};

export default moveResourceActionFunction;

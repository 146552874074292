import { Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import ReplacerModal from "../../../../../components/uploader/replacer";
import DownloaderModal from "../../../../../components/downloader/downloader";
import ChangeSchemaModal from "../../../../../components/changeSchema/changeSchema";
import { ADMIN_ROLE } from "../../../../../utils/accountUtils";
import PublishIcon from "@mui/icons-material/Publish";
import GetAppIcon from "@mui/icons-material/GetApp";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import CachedIcon from "@mui/icons-material/Autorenew";
import { generateCache } from "../../../../../actions/datasets";

const cacheDatasetTooltipTitle = {
    0: "",
    1: "Dataset is already in the process of caching",
    2: "Dataset is already cached"
};

const MenuAndModals = ({
    dataset,
    columns,
    hasDatasetLink,
    onLinkDatasetToDrive,
    onRemoveDatasetLink,
    actionsAnchor,
    changeDatasetSchemaName,
    setActionsAnchor,
    onCloseActions
}) => {
    const [replacerOpen, setReplacerOpen] = useState(false);
    const [downloaderOpen, setDownloaderOpen] = useState(false);
    const [changeSchemaOpen, setChangeSchemaOpen] = useState(false);

    const userRole = useSelector((state) => state.auth.userRole);

    const dispatch = useDispatch();

    const onGenerateDataset = () => {
        dispatch(generateCache(dataset.id));
    };

    const onOpenReplacer = () => {
        setReplacerOpen(true);
        setActionsAnchor(null);
    };

    const onCloseReplacer = () => {
        setReplacerOpen(false);
    };

    const onOpenDownloader = () => {
        setDownloaderOpen(true);
    };

    const onCloseDownloader = () => {
        setDownloaderOpen(false);
    };

    const onOpenChangeSchema = () => {
        setChangeSchemaOpen(true);
    };

    const onCloseChangeSchema = () => {
        setChangeSchemaOpen(false);
    };

    return (
        <>
            <Menu
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                anchorEl={actionsAnchor}
                open={Boolean(actionsAnchor)}
                onClose={onCloseActions}
                PaperProps={{ square: true }}
            >
                <MenuItem onClick={onOpenReplacer} className="menu-button">
                    <PublishIcon className="icon" />
                    <Typography variant="subtitle1">Replace Data</Typography>
                </MenuItem>
                <MenuItem onClick={onOpenDownloader} className="menu-button">
                    <GetAppIcon className="icon" />
                    <Typography variant="subtitle1">Download Data</Typography>
                </MenuItem>
                {!hasDatasetLink && (
                    <MenuItem onClick={onLinkDatasetToDrive} className="menu-button">
                        <LinkIcon className="icon" />
                        <Typography variant="subtitle1">Link google drive</Typography>
                    </MenuItem>
                )}
                {hasDatasetLink && (
                    <MenuItem onClick={onRemoveDatasetLink} className="menu-button">
                        <LinkOffIcon className="icon" />
                        <Typography variant="subtitle1">Unlink google drive</Typography>
                    </MenuItem>
                )}
                {userRole === ADMIN_ROLE && (
                    <MenuItem onClick={onOpenChangeSchema} className="menu-button">
                        <EditIcon className="icon" />
                        <Typography variant="subtitle1">Change Schema</Typography>
                    </MenuItem>
                )}
                <Tooltip title={cacheDatasetTooltipTitle[dataset.cacheStatus]}>
                    <div>
                        <MenuItem onClick={onGenerateDataset} disabled={dataset.cacheStatus !== 0} className="menu-button">
                            <CachedIcon className={`icon flip`} />
                            <Typography variant="subtitle1">Cache Dataset</Typography>
                        </MenuItem>
                    </div>
                </Tooltip>
            </Menu>
            <ReplacerModal open={replacerOpen} datasetId={dataset.id} columnDefinitions={columns} handleClose={onCloseReplacer} />
            <DownloaderModal open={downloaderOpen} dataset={dataset} handleClose={onCloseDownloader} />
            <ChangeSchemaModal
                open={changeSchemaOpen}
                datasetId={dataset.id}
                schemaName={dataset.schemaName}
                handleClose={onCloseChangeSchema}
                changeDatasetSchemaName={changeDatasetSchemaName}
            />
        </>
    );
};

export default MenuAndModals;

const sortByName = (app1, app2) => {
    return app1.name.localeCompare(app2.name);
};

const sortByNameReverse = (app1, app2) => {
    return app2.name.localeCompare(app1.name);
};

const sortByModified = (app1, app2) => {
    return new Date(app2.modifiedUtc) - new Date(app1.modifiedUtc);
};

const sortByModifiedReverse = (app1, app2) => {
    return new Date(app1.modifiedUtc) - new Date(app2.modifiedUtc);
};

const sortByCreated = (app1, app2) => {
    return new Date(app2.createdUtc) - new Date(app1.createdUtc);
};

const sortByCreatedReverse = (app1, app2) => {
    return new Date(app1.createdUtc) - new Date(app2.createdUtc);
};

const appsSorting = [
    {
        type: "name",
        name: "Name",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByNameReverse : sortByName)
    },
    {
        type: "date",
        name: "Created",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByCreatedReverse : sortByCreated)
    },
    {
        type: "date",
        name: "Modified",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByModifiedReverse : sortByModified),
        start: true
    }
];

export default appsSorting;

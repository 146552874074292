import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export const getRasters = () => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_RASTERS });

        return axiosClient
            .get("raster")
            .then((res) => {
                return dispatch({ type: types.FETCH_RASTERS_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.RASTER_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const getRaster = (rasterId) => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_RASTER });

        return axiosClient
            .get("raster/" + rasterId)
            .then((res) => {
                return dispatch({ type: types.FETCH_RASTER_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.RASTER_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const updateRaster = (rasterId, request) => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_RASTER });

        return axiosClient
            .put("raster/" + rasterId, request)
            .then((res) => {
                return dispatch({ type: types.UPDATE_RASTER_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.RASTER_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const deleteRaster = (rasterId) => {
    return (dispatch) => {
        dispatch({ type: types.DELETE_RASTER });

        return axiosClient
            .delete("raster/" + rasterId)
            .then((res) => {
                return dispatch({ type: types.DELETE_RASTER_COMPLETE, result: rasterId });
            })
            .catch((err) => {
                dispatch({ type: types.RASTER_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const createRaster = (formData, config) => {
    return (dispatch) => {
        dispatch({ type: types.CREATE_RASTER });

        return axiosClient
            .post("raster", formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => {
                return dispatch({ type: types.CREATE_RASTER_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.RASTER_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

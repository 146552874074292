import { TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import ColorPopover from "./colorPopover";
import React, { useState, useEffect } from "react";

const SingleTagEditor = ({ tag, onEdit, onDelete, error, errorHelper }) => {
    const [newName, setNewName] = useState(tag.name);
    const [newColor, setNewColor] = useState(tag.color);

    useEffect(() => {
        setNewColor(tag.color);
        setNewName(tag.name);
    }, [tag]);

    const onColorChange = (color) => {
        setNewColor(color);
        onEdit({ ...tag, color: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})` }, tag.name);
    };

    const onNameChange = (e) => {
        setNewName(e.target.value);
        onEdit({ ...tag, name: e.target.value }, tag.name);
    };

    return (
        <div className="tag-create-container">
            <TextField value={newName} label="Tag name" variant="outlined" onChange={onNameChange} error={error} helperText={error ? errorHelper : ""} />
            <ColorPopover color={newColor} onColorChanged={onColorChange} />
            <div className="add-tag-btn" onClick={() => onDelete(tag)}>
                <DeleteIcon />
            </div>
        </div>
    );
};

export default SingleTagEditor;

import * as types from "../actions/actionTypes";

const rastersInit = {
    fetching: false,
    rasters: []
};

export function rastersReducer(state = rastersInit, action) {
    switch (action.type) {
        case types.FETCH_RASTERS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_RASTERS_COMPLETE:
            return {
                ...state,
                fetching: false,
                rasters: action.result
            };
        case types.CREATE_RASTER:
            return {
                ...state,
                fetching: true
            };
        case types.CREATE_RASTER_COMPLETE:
            return {
                ...state,
                fetching: false,
                rasters: [...state.rasters, action.result]
            };
        case types.DELETE_RASTER:
            return {
                ...state,
                fetching: true
            };
        case types.DELETE_RASTER_COMPLETE:
            return {
                ...state,
                fetching: false,
                rasters: removeFromArray(state.rasters, action.result)
            };
        case types.RASTER_ACTION_FAILED:
            return {
                ...state,
                fetching: false
            };
        default:
            return state;
    }
}

const rasterDetailsInit = {
    fetching: false
};

export function rasterDetailsReducer(state = rasterDetailsInit, action) {
    switch (action.type) {
        case types.FETCH_RASTER:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_RASTER_COMPLETE:
            return {
                ...state,
                fetching: false,
                rasters: action.result
            };

        case types.UPDATE_RASTER:
            return {
                ...state,
                fetching: true
            };
        case types.UPDATE_RASTER_COMPLETE:
            return {
                ...state,
                fetching: false
            };
        case types.RASTER_ACTION_FAILED:
            return {
                ...state,
                fetching: false
            };
        default:
            return state;
    }
}

function removeFromArray(array, id) {
    return array.filter((x) => id !== x.id);
}

// function updateArray(array, item) {
//     return array.map((x) => {
//         if (item.id === x.id) {
//             return item;
//         }
//         return x;
//     });
// }

import * as types from "../actions/actionTypes";

const notificationsInit = {
    open: false,
    notifications: [
        // {name:"test",tileGoal:100,tileCount:0,id:'123'}
    ],
    unseenNotifications: false
};

export function notificationReducer(state = notificationsInit, action) {
    switch (action.type) {
        case types.NOTIFICATIONS_TOGGLE_OPEN:
            return {
                ...state,
                open: !state.open,
                unseenNotifications: false
            };
        case types.RESET_NOTIFICATION:
            return {
                ...state,
                notifications: removeFromArray(state.notifications, action.result),
                unseenNotifications: !state.open && true
            };
        case types.DISMISS_NOTIFICATION:
            return {
                ...state,
                notifications: removeFromArray(state.notifications, action.result)
            };
        case types.SIGNALR_PROGRESS_UPDATE:
            let result = addOrUpdateArray(state.notifications, action.result);
            return {
                ...state,
                notifications: result.newArray,
                unseenNotifications: !state.open && !result.found
            };
        case types.SIGNALR_ERROR_UPDATE:
            return {
                ...state,
                notifications: updateArrayWithError(state.notifications, action.result),
                unseenNotifications: !state.open && true
            };
        case types.CANCEL_GENERATE_DATASET_CACHE:
            return {
                ...state,
                notifications: updateArrayWithCancelling(state.notifications, action.result)
            };
        case types.CANCEL_GENERATE_DATASET_CACHE_COMPLETE:
            return {
                ...state,
                notifications: updateArrayWithCancelled(state.notifications, action.result),
                unseenNotifications: !state.open && true
            };
        default:
            return state;
    }
}

function removeFromArray(array, item) {
    return array.filter((x) => item.id !== x.id);
}

function addOrUpdateArray(array, item) {
    let found = false;

    const newArray = array.map((x) => {
        if (item.id === x.id) {
            found = true;
            return {
                ...item,
                name: x.name
            };
        }
        return x;
    });

    if (!found) {
        newArray.push(item);
    }

    return { newArray, found };
}

function updateArrayWithCancelling(array, itemId) {
    return array.map((x) => {
        if (itemId === x.id) {
            return {
                ...x,
                cancelling: true
            };
        }
        return x;
    });
}

function updateArrayWithCancelled(array, itemId) {
    return array.map((x) => {
        if (itemId === x.id) {
            return {
                ...x,
                cacheStatus: 0,
                cancelling: false,
                wasCancelled: true
            };
        }
        return x;
    });
}

function updateArrayWithError(array, item) {
    let found = false;
    const newArray = array.map((x) => {
        if (item.id === x.id) {
            found = true;
            if (!x.wasCancelled) {
                return {
                    ...x,
                    ...item,
                    error: true
                };
            }
        }
        return x;
    });

    if (!found) {
        newArray.push({ ...item, error: true });
    }

    return newArray;
}

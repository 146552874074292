import React, { useState } from "react";
import { Handle, Range } from "rc-slider";
import RcTooltip from "rc-tooltip";
import CollapsibleCard from "../../../../../components/CollapsibleCard/CollapsibleCard";
import { useFormikContext } from "formik";
import * as TileUtil from "../../../../../utils/tileUtils";
import Typography from "../../../../../components/CustomTypography/CustomTypography";

const ZoomSection = ({ bbox, setTileDataChanged }) => {
    const { values, setFieldValue } = useFormikContext();

    const [isZoomOpen, setIsZoomOpen] = useState(true);
    const zoom = `${values.minZoom} - ${values.maxZoom}`;

    const tileCount = bbox ? TileUtil.getTileCount(bbox, values.minZoom, values.maxZoom) : "-";

    const toggleZoomSection = () => {
        setIsZoomOpen(!isZoomOpen);
    };

    const handle = (props) => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <RcTooltip key={index} prefixCls="rc-slider-tooltip" overlay={value} placement="top">
                <Handle value={value} {...restProps} />
            </RcTooltip>
        );
    };
    return (
        <div className="shadow-section">
            <CollapsibleCard innerPadding={0} onToggleClick={toggleZoomSection} title="Zoom" isToggled={isZoomOpen} unmountOnExit>
                <div className="shadow-section__container flex">
                    <div className="generated-tiles">
                        <Typography variant="body2" fontWeight="bold" className="generated-tiles__header">
                            Number of Generated Tiles
                        </Typography>
                        <Typography variant="h3" fontWeight="bold" className="generated-tiles__value">
                            {tileCount}
                        </Typography>
                    </div>
                    <div className="zoom-level">
                        <Typography variant="body2" fontWeight="bold" className="zoom-level__header">
                            Zoom Level
                        </Typography>
                        <Typography variant="h3" fontWeight="bold" className="zoom-level__value">
                            {zoom}
                        </Typography>
                    </div>
                </div>
                <div className="slider-container">
                    <Range
                        max={24}
                        min={0}
                        handle={handle}
                        pushable={2}
                        value={[values.minZoom, values.maxZoom]}
                        onChange={(value) => {
                            setTileDataChanged(true);
                            setFieldValue("minZoom", value[0]);
                            setFieldValue("maxZoom", value[1]);
                        }}
                    />
                    <div className="slider-footer">
                        <span>
                            <Typography variant="body2" fontWeight="bold">
                                0
                            </Typography>
                        </span>
                        <span>
                            <Typography variant="body2" fontWeight="semibold">
                                24
                            </Typography>
                        </span>
                    </div>
                </div>
            </CollapsibleCard>
        </div>
    );
};

export default ZoomSection;

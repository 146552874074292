import { makeStyles } from "@mui/styles";

export const useCustomTableStyles = makeStyles((theme) => ({
    visible: {
        display: "flex"
    },
    noDataIndicator: { cursor: "default", fontWeight: "heavy" },
    noDataText: {
        fontWeight: 700
    }
}));

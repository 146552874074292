import { toastr as reduxToastr } from "react-redux-toastr";

const options = { position: "bottom-right" };

const CustomToastr = {
    info: (text, opt = options) => reduxToastr.info("", text, opt),
    success: (text, opt = options) => reduxToastr.success("", text, opt),
    warning: (text, opt = options) => reduxToastr.warning("", text, opt),
    error: (text, opt = options) => reduxToastr.error("", text, opt),
    confirm: (text, opt) => reduxToastr.confirm(text, opt)
};

//this helps us with auto-import
const toastr = CustomToastr;

export default toastr;

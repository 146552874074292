const getRasterInitProperties = () => {
    return [
        {
            name: "fill-color",
            title: "Legend",
            type: "paint",
            propertyType: "color",
            expressionType: "match",
            index: 0,
            value: ["match", ["get", null], "replace this", "darkcyan", "replace this also", "pink", "black"]
        }
    ];
};

export default getRasterInitProperties;

import React, { Component } from "react";
import { connect } from "react-redux";
import * as datasetsActions from "../../../actions/datasets";
import Table from "./components/table";
import { handleError } from "../../../utils/networkErrorUtils";

import withStyles from "@mui/styles/withStyles";

const styles = () => ({});

class TableView extends Component {
    state = {
        rows: []
    };

    componentDidMount() {
        this.fetchRows();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableViewState.datasetId !== this.props.tableViewState.datasetId) {
            this.fetchRows();
        }
    }

    fetchRows() {
        this.props
            .getGeoJson(this.props.tableViewState.datasetId)
            .then((res) => {
                this.setState({
                    rows: this.generateRows(res.result[0].properties)
                });
            })
            .catch((err) => {
                handleError(err);
            });
    }

    generateRows(properties) {
        let rows = [];
        for (let [key] of Object.entries(properties)) {
            rows.push({
                id: key,
                label: key,
                disablePadding: false
            });
        }
        return rows;
    }

    onItemClick = (item) => {};

    render() {
        return (
            <div className="table-view">
                <Table
                    title={"Database view"}
                    fetching={this.props.tableViewState.fetching}
                    data={this.props.tableViewState.features}
                    rows={this.state.rows}
                    onAddClick={this.onAddClick}
                    onDeleteClick={this.onDeleteClick}
                    onRefreshClick={this.onRefreshClick}
                    onItemClick={this.onItemClick}
                    onEditClick={this.onTaskEditClick}
                    onRunClick={this.onTaskRunClick}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tableViewState: state.tableView
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getGeoJson: (datasetId) => dispatch(datasetsActions.getAsGeoJson(datasetId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TableView));

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import CustomTypography from "./components/CustomTypography/CustomTypography";

const Auth0ErrorWrapper = ({ children }) => {
    const { error } = useAuth0();

    if (error) {
        return <CustomTypography textAlign="center">Oops... {error.message}</CustomTypography>;
    }

    return children;
};

export default Auth0ErrorWrapper;

import React from "react";

import DatasetsView from "../views/datasets/datasetsRootView/datasetsRootView";
import RasterView from "../views/rasters/rastersRootView/rastersRootView";
import ApplicationView from "../views/apps/appsRootView/appsRootView";
import GroupsView from "../views/groups/groupsRootView/groupsRootView";
import UsersView from "../views/users/usersRootView/usersRootView";
import SettingsView from "../views/settings/settingsRootView/settingsRootview";

import DatasetIcon from "@mui/icons-material/Layers";
import AppIcon from "@mui/icons-material/DesktopWindows";
import RasterIcon from "@mui/icons-material/Wallpaper";
import GroupIcon from "@mui/icons-material/Group";
import UserIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";

import { ADMIN_ROLE, USER_ROLE } from "./accountUtils";

export const LOCATIONS = [
    {
        name: "Vector Data",
        route: "/datasets",
        exact: false,
        component: DatasetsView,
        allowedRoles: [ADMIN_ROLE, USER_ROLE],
        icon: <DatasetIcon className="icon" />,
        displayInNavbar: true
    },
    {
        name: "Rasters",
        route: "/rasters",
        exact: false,
        component: RasterView,
        allowedRoles: [ADMIN_ROLE, USER_ROLE],
        icon: <RasterIcon className="icon" />,
        displayInNavbar: true
    },
    {
        name: "Applications",
        route: "/applications",
        exact: false,
        component: ApplicationView,
        allowedRoles: [ADMIN_ROLE, USER_ROLE],
        icon: <AppIcon className="icon" />,
        displayInNavbar: true
    },
    {
        name: "Groups",
        route: "/groups",
        exact: false,
        component: GroupsView,
        allowedRoles: [ADMIN_ROLE],
        icon: <GroupIcon className="icon" />,
        displayInNavbar: true
    },
    {
        name: "Users",
        route: "/users",
        exact: false,
        component: UsersView,
        allowedRoles: [ADMIN_ROLE],
        icon: <UserIcon className="icon" />,
        displayInNavbar: true
    },
    {
        name: "Settings",
        route: "/settings",
        exact: false,
        component: SettingsView,
        allowedRoles: [ADMIN_ROLE],
        icon: <SettingsIcon className="icon" />,
        displayInNavbar: true
    }
];

import { CircularProgress } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";
import CustomTypography from "../CustomTypography/CustomTypography";

const LoadingPlaceholder = ({ children, loading, message, textVariant, spinnerSize }) => {
    const { classes } = useStyles();
    return loading ? (
        <div className={classes.container}>
            <CustomTypography className={classes.bottomMargin} variant={textVariant}>
                {message}
            </CustomTypography>
            <CircularProgress size={spinnerSize} />
        </div>
    ) : (
        children
    );
};

LoadingPlaceholder.defaultProps = {
    message: "Loading",
    textVariant: "body1",
    spinnerSize: 50,
    children: null
};

export default LoadingPlaceholder;

import { TableCell } from "@mui/material";
import React from "react";
import { CELL_SIZES, getCellSizeWidth } from "../../../../../utils/constants/cellSizes";
import { useCurrentBreakpoint } from "../../../../../utils/customHooks/useCurrentBreakpoint";
import OverflowTip from "../../../../OverflowTip/OverflowTip";
import { useOverflowCellStyles } from "./styles";
import clsx from "clsx";

const OverflowCell = ({ children, cellSize, className, color, size }) => {
    const currentBreakpoint = useCurrentBreakpoint();
    const cellWidth = getCellSizeWidth(cellSize, currentBreakpoint);
    const classes = useOverflowCellStyles({ cellWidth });

    return (
        <TableCell size={size} className={clsx(className, classes.cellWidth)}>
            <OverflowTip variant="body2" color={color}>
                {children}
            </OverflowTip>
        </TableCell>
    );
};

OverflowCell.defaultProps = {
    cellSize: CELL_SIZES.medium,
    color: "inherit",
    size: "medium"
};

export default OverflowCell;

import React, { useEffect, useState } from "react";
import { useDatasetsTableStyles } from "./styles";
import SearchBar from "../../../../../../../components/SearchBar/SearchBar";
import CustomTable from "../../../../../../../components/CustomTable/CustomTable";
import DatasetRow from "../DatasetRow/DatasetRow";
import { useSelector } from "react-redux";
import { getAppLayersCount, getIncludedDatasets, isLayerLimitReached } from "../../../../../../../selectors/appData";
import { Box, Tooltip, Typography } from "@mui/material";
import appLayersLimit from "../../../../../../../utils/constants/appLayersLimit";
import InfoIcon from "@mui/icons-material/Info";
import datasetColumns from "./datasetColumns";

const LAYER_LIMIT_REACHED_TEXT = "Layer limit reached. In order to add new layers, try removing old ones, or create a new app.";
const LAYER_LIMIT_INFO_TEXT = "Due to performance reasons, there is a limit on the total amount of layers an app can include.";

const filterFunction = (item, query) => {
    return item.name.toLowerCase().includes(query.toLowerCase());
};

const DatasetsTable = ({ disableCheckbox, onToggleIncludeDataset, data }) => {
    const classes = useDatasetsTableStyles();

    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState("");

    const includedDatasets = useSelector(getIncludedDatasets);
    const layerLimitReached = useSelector(isLayerLimitReached);
    const appLayersCount = useSelector(getAppLayersCount);

    useEffect(() => {
        setFilteredData(data.filter((entity) => filterFunction(entity, searchText)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onSearchChange = (newValue) => {
        setFilteredData(data.filter((entity) => filterFunction(entity, newValue)));
        setSearchText(newValue);
    };

    const generateItem = (dataset) => {
        return (
            <DatasetRow
                dataset={{ ...dataset, included: !!includedDatasets[dataset.id] }}
                disabled={disableCheckbox || (layerLimitReached && !includedDatasets[dataset.id])}
                onToggle={onToggleIncludeDataset}
                key={dataset.id}
            />
        );
    };

    return (
        <div className={classes.tableContainer}>
            <div className={classes.searchContainer}>
                <SearchBar searchPlaceholder="Search datasets" value={searchText} onChange={onSearchChange} textFieldClassName={classes.searchBarWidth} />
                <Box display="flex" alignItems="center">
                    <Typography className={classes.countText} color={layerLimitReached ? "error" : ""}>
                        {appLayersCount} / {appLayersLimit}
                    </Typography>
                    <Tooltip className={classes.marginLeft} title={layerLimitReached ? LAYER_LIMIT_REACHED_TEXT : LAYER_LIMIT_INFO_TEXT}>
                        <InfoIcon fontSize="small" className={layerLimitReached ? classes.errorInfoColor : classes.infoColor} />
                    </Tooltip>
                </Box>
            </div>
            <CustomTable data={filteredData} columns={datasetColumns} generateItem={generateItem} />
        </div>
    );
};

export default DatasetsTable;

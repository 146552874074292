import React from "react";
import { Route, useHistory } from "react-router-dom";

const GuardedRoute = ({ component: Component, condition, ...rest }) => {
    const history = useHistory();
    if (condition === false) {
        history.push("/dataset");
        return null;
    }
    return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default GuardedRoute;

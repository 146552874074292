import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import SettingsView from "../settingsView/settingsView";
import Header from "../../../components/header/header";
import HeaderButtons from "../../../components/HeaderButtons/HeaderButtons";

const styles = () => ({});

class SettingsRootView extends Component {
    render() {
        const { unseenNotifications, isNavigationOpen } = this.props;

        return (
            <div className="settings-rootview">
                <div className={isNavigationOpen ? "sidebar" : "sidebar closed"}>
                    <div className="header">
                        <Header />
                        <HeaderButtons unseenNotifications={unseenNotifications} />
                    </div>
                    <SettingsView />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        unseenNotifications: state.notifications.unseenNotifications,
        isNavigationOpen: state.nav.isNavigationOpen
    };
};

export default connect(mapStateToProps)(withStyles(styles)(SettingsRootView));

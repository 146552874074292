import React from "react";
import { connect } from "react-redux";

import * as notificationActions from "../../actions/notifications";

import { Badge, Tooltip } from "@mui/material";
import AlarmIcon from "@mui/icons-material/Notifications";

const NotificationButton = (props) => {
    return (
        <Tooltip title="Notifications">
            <div className="notification-button" onClick={() => props.toggleNotificationsOpen()}>
                <Badge invisible={!props.unseenNotifications} badgeContent={""} color="error">
                    <AlarmIcon className="icon" />
                </Badge>
            </div>
        </Tooltip>
    );
};

const mapStateToProps = (state) => ({
    unseenNotifications: state.notifications.unseenNotifications
});

const mapDispatchToProps = {
    toggleNotificationsOpen: notificationActions.toggleNotificationsOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationButton);

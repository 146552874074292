import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApp } from "../../../../selectors/appData";
import { CONFIG } from "../../../../utils/constants/appDefaults";
import useComponentCancelToken from "../../../../utils/customHooks/useComponentCancelToken";
import mapVisibilityCounts from "../../../../utils/layerGroups/mapVisibilityCounts";
import { getDatasets } from "../../../../actions/datasets";
import { addMapSources, fitMapBounds, initMapResources, removeAllMapLayers, removeAllSources, resetMapFlags } from "../../../../reducers/map";
import axios from "axios";
import { getStyleConfig } from "../../../../selectors/style";
import { getApp as getAppAction } from "../../../../actions/apps";
import { getMaps } from "../../../../actions/mapService";
import { clearAppData, setAppDetails, setLastSaveDatasetCount } from "../../../../reducers/appData/appData";
import { getRasters } from "../../../../actions/rasterService";
import { useHistory } from "react-router-dom";
import { getStyleConfig as getStyleConfigThunk } from "../../../../actions/config";

export const useAppInit = () => {
    const [loading, setLoading] = useState(true);

    const cancelToken = useComponentCancelToken();

    const storeApp = useSelector(getApp);
    const styleConfig = useSelector(getStyleConfig);

    const dispatch = useDispatch();

    const history = useHistory();

    const initApp = (appId) => {
        const appPromise = dispatch(getAppAction({ applicationId: appId, cancelToken: cancelToken.token }));
        const mapsPromise = dispatch(getMaps(cancelToken.token));

        //The style config from the bootsrap isn't always available if a user opens directly the app. In this case we also get it.
        //If it is available, we provide the already existent one. This logic can result in 2 style fetches at the same time, but it shouldn't matter.
        let styleConfigPromise = new Promise((resolve) => {
            resolve(styleConfig);
        });

        if (styleConfig === null) {
            styleConfigPromise = dispatch(getStyleConfigThunk()).then((res) => res.payload);
        }

        dispatch(getDatasets());
        dispatch(getRasters());

        Promise.all([appPromise, mapsPromise, styleConfigPromise])
            .then((res) => {
                const appRes = res[0].payload;
                const mapsRes = res[1].result;
                const styleConfig = res[2];
                setDetails(appRes, mapsRes, styleConfig);
            })
            .catch((e) => {
                if (!axios.isCancel(e)) {
                    history.push("/applications");
                }
            });
        return () => {
            dispatch(removeAllMapLayers());
            dispatch(removeAllSources());
            dispatch(resetMapFlags());
            dispatch(clearAppData());
            cancelToken.cancel("Canceled");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const setDetails = (app, maps, styleConfig) => {
        //Build a map of added datasets
        const includedDatasets = app.maps
            .flatMap((x) => x.datasets)
            .reduce((a, b) => {
                a[b.id] = true;
                return a;
            }, {});
        const includedRasters = app.rasters.reduce((a, b) => {
            a[b.id] = true;
            return a;
        }, {});
        let configJson = initAppConfig(app);
        const sources = addSources(app.maps, app.rasters);
        const { layerGroups, layerVisibilityMap, layerStylesMap } = loadStyles(configJson.layerGroups, sources, styleConfig);
        dispatch(
            setAppDetails({
                app: { ...app, configJson },
                layerGroups,
                layerVisibilityMap,
                layerStylesMap,
                maps,
                includedDatasets,
                includedRasters
            })
        );
        setLoading(false);
        dispatch(
            fitMapBounds({
                bounds: configJson.mapBounds,
                options: {
                    padding: {
                        top: 45,
                        bottom: 45,
                        left: 45,
                        right: 45
                    },
                    animate: false
                }
            })
        );
    };

    const initAppConfig = (app) => {
        if (app.configJson == null) return storeApp.configJson;

        const configJson = {
            layerGroups: app.configJson.layerGroups || [],
            sidebarCollapsed: app.configJson.sidebarCollapsed || storeApp.configJson.sidebarCollapsed,
            mapBounds: app.configJson.mapBounds || storeApp.configJson.mapBounds,
            projections: app.configJson.projections || storeApp.configJson.projections,
            basemaps: app.configJson.basemaps || storeApp.configJson.basemaps,
            tools: [],
            widgets: [],
            languages: app.configJson.languages || storeApp.configJson.languages
        }; //Add or remove new and old tools from the config

        CONFIG.tools.forEach((tool) => {
            const appTool = app.configJson.tools.find((x) => x.name === tool.name);

            configJson.tools.push({ ...tool, enabled: !!appTool?.enabled });
        });
        //Add or remove new and old widgets from the config

        CONFIG.widgets.forEach((widget) => {
            const appWidget = app.configJson.widgets.find((x) => x.name === widget.name);
            configJson.widgets.push({ ...widget, enabled: !!appWidget?.enabled });
        });

        return configJson;
    };

    const addSources = (maps, rasters) => {
        const sources = [];

        maps.forEach((map) => {
            sources.push({
                id: map.id,
                minZoom: map.minZoom,
                maxZoom: map.maxZoom,
                type: "vector",
                cacheStatus: map.cacheStatus,
                isMap: true
            });
            if (map.cacheStatus !== 2) {
                map.datasets.forEach((dataset) => {
                    sources.push({
                        id: dataset.id,
                        minZoom: dataset.minZoom,
                        maxZoom: dataset.maxZoom,
                        type: "vector",
                        isMap: false
                    });
                });
            }
        });
        rasters.forEach((raster) => {
            sources.push({
                id: raster.id,
                minZoom: raster.minZoom,
                maxZoom: raster.maxZoom,
                type: "raster",
                isMap: false
            });
        });
        dispatch(addMapSources(sources));
        return sources;
    };

    const loadStyles = (layerGroups, sources, styleConfig) => {
        let previousStyleId = null;
        const mapSourcesMap = sources.reduce((acc, source) => {
            if (source.isMap) acc[source.id] = source;
            return acc;
        }, {});
        const layerVisibilityMap = {};
        const layerStylesMap = {};
        const mapLayers = [];
        const paints = {};
        const layouts = {};
        const zoomRanges = {};

        const processStyle = (layer, style) => {
            const mapCacheStatus = mapSourcesMap[layer.sourceId]?.cacheStatus;
            const sourceId = mapCacheStatus === 2 ? layer.sourceId : layer.resourceId;
            const mapLayer = {
                sourceId,
                layerId: style.styleId,
                resourceId: layer.resourceId,
                sourceName: layer.sourceName,
                type: style.type,
                minZoom: style.minZoom,
                maxZoom: style.maxZoom,
                drawBefore: previousStyleId
            };
            mapLayers.push(mapLayer);
            const properties = !!styleConfig ? JSON.parse(JSON.stringify(styleConfig[style.type])) : [];

            //Add any new paint/layout properties to style
            for (let i = 0; i < properties.length; i++) {
                let property = properties[i];
                let styleProperty = style.properties.find((x) => x.name === property.name);

                if (styleProperty) {
                    property.value = styleProperty.value;
                    property.expressionType = styleProperty.expressionType || "none";

                    if (styleProperty.name === "raster-opacity") {
                        styleProperty.propertyType = property.propertyType;
                    }
                }
            }

            const paintProperties = properties.filter((x) => x.type === "paint");
            const paint = {
                layerId: style.styleId,
                properties: paintProperties
            };
            paints[style.styleId] = paint;
            const layoutProperties = properties.filter((x) => x.type === "layout");
            layoutProperties.push({
                type: "layout",
                name: "visibility",
                value: layer.options.enabled ? "visible" : "none"
            });
            const layout = {
                layerId: style.styleId,
                properties: layoutProperties
            };
            layouts[style.styleId] = layout;
            const zoomRange = {
                layerId: style.styleId,
                minZoom: style.minZoom,
                maxZoom: style.maxZoom
            };
            zoomRanges[style.styleId] = zoomRange;
            previousStyleId = style.styleId;
        };

        let layerCount = 0;
        const processedLayerGroups = layerGroups
            .mapGroupsRecursive((group) => ({ ...group, collapsed: group.options.collapsed }))
            .mapLayersRecursive((layer) => {
                layerCount++;
                layerVisibilityMap[layer.resourceId] = layer.options.enabled;

                for (let i = 0; i < layer.styles.length; i++) {
                    let style = layer.styles[i];
                    processStyle(layer, style);
                }

                layerStylesMap[layer.resourceId] = [...layer.styles];
                delete layer.styles;
                return layer;
            });
        dispatch(setLastSaveDatasetCount(layerCount));
        dispatch(
            initMapResources({
                layers: mapLayers,
                paints,
                layouts,
                zoomRanges
            })
        );
        return {
            layerGroups: mapVisibilityCounts(processedLayerGroups, layerVisibilityMap),
            layerVisibilityMap,
            layerStylesMap
        };
    };

    return { loading, initApp };
};

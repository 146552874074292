import { reducer as toastrReducer } from "react-redux-toastr";
import { datasetsReducer, datasetDetailsReducer } from "./datasets";
import { authReducer } from "./auth";
import { usersReducer } from "./users";
import { groupsReducer } from "./groups";
import { appsReducer } from "./apps";
import { tableViewReducer } from "./tableView";
import { notificationReducer } from "./notifications";
import { rastersReducer, rasterDetailsReducer } from "./rasters";
import { metadataSchemaReducer } from "./metadataSchema";
import { uploaderReducer } from "./uploader";
import { schemasReducer } from "./schemas";
import { navigationReducer } from "./navigation";
import appDataReducer from "./appData/appData";
import mapReducer from "./map";
import styleReducer from "./style";

// main reducers
const reducers = {
    map: mapReducer,
    appData: appDataReducer,
    datasets: datasetsReducer,
    datasetDetails: datasetDetailsReducer,
    rasters: rastersReducer,
    rasterDetails: rasterDetailsReducer,
    users: usersReducer,
    groups: groupsReducer,
    apps: appsReducer,
    tableView: tableViewReducer,
    notifications: notificationReducer,
    metadataSchema: metadataSchemaReducer,
    uploader: uploaderReducer,
    schemas: schemasReducer,
    toastr: toastrReducer,
    auth: authReducer,
    nav: navigationReducer,
    style: styleReducer
};

export default reducers;

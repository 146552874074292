import React from "react";
import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/AddCircle";

const AfterUpload = ({ dragging, acceptedFormats, onFileChanged, onCloseModal }) => {
    return (
        <div className={`bottom-container uploaded  ${dragging ? `dragging` : ``}`}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    document.getElementById("file-button").click();
                }}
                style={{ marginRight: 20 }}
            >
                <AddIcon className="button-icon" fontSize="small" />
                Add Files
            </Button>
            <input accept={acceptedFormats} style={{ display: "none" }} multiple={true} id="file-button" type="file" onChange={onFileChanged} />
            <Button variant="text" color="primary" onClick={onCloseModal}>
                Cancel
            </Button>

            {dragging && (
                <div className="drag-overlay big">
                    <CloudUploadIcon />
                    <div>Drop files here</div>
                </div>
            )}
        </div>
    );
};

export default AfterUpload;

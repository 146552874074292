import { TableCell, Tooltip } from "@mui/material";
import React from "react";
import Typography from "../../../../CustomTypography/CustomTypography";

const TooltipCell = ({ tooltipTitle, children }) => {
    return (
        <TableCell>
            <Tooltip id="tooltip-top" title={tooltipTitle}>
                <div>{children}</div>
            </Tooltip>
        </TableCell>
    );
};

TooltipCell.defaultProps = {
    tooltipTitle: "",
    children: <Typography>-</Typography>
};

export default TooltipCell;

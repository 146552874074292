import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export const getMaps = (cancelToken) => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_MAPS });
        return axiosClient.get("map", { cancelToken }).then(function (res) {
            return dispatch({ type: types.FETCH_MAPS_COMPLETE, result: res.data });
        });
    };
};

export const getMapsAdmin = () => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_MAPS });
        return axiosClient.get("admin/map").then(function (res) {
            return dispatch({ type: types.FETCH_MAPS_COMPLETE, result: res.data });
        });
    };
};

export const getMap = (mapId) => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_MAP });

        return axiosClient.get("map/" + mapId).then(function (res) {
            return dispatch({ type: types.FETCH_MAP_COMPLETE, result: res.data });
        });
    };
};

export const updateMap = (mapId, map) => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_MAP });

        return axiosClient.put("map/" + mapId, map).then(function (res) {
            return dispatch({ type: types.UPDATE_MAP_COMPLETE, result: res.data });
        });
    };
};

export const createMap = (createMapRequest) => {
    return (dispatch) => {
        dispatch({ type: types.CREATE_MAP });

        return axiosClient.post("map", createMapRequest).then(function (res) {
            return dispatch({
                type: types.CREATE_MAP_COMPLETE,
                result: res.data
            });
        });
    };
};

export const deleteMap = (mapId) => {
    return (dispatch) => {
        dispatch({ type: types.DELETE_MAP });

        return axiosClient.delete("map/" + mapId).then(function (res) {
            dispatch({ type: types.DELETE_MAP_COMPLETE, result: { id: mapId } });
        });
    };
};

export const generateCache = (mapId) => {
    return (dispatch) => {
        dispatch({ type: types.GENERATE_MAP_CACHE });

        return axiosClient.post("map/" + mapId + "/generate").then(function (res) {
            return dispatch({ type: types.GENERATE_MAP_CACHE_COMPLETE, result: { id: mapId } });
        });
    };
};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as uploaderActions from "../../../../../actions/uploader";
import DatasetIcon from "@mui/icons-material/Layers";
import CloseIcon from "@mui/icons-material/Close";
import RasterIcon from "@mui/icons-material/Wallpaper";
import { StyledTabs, StyledTab } from "../../../../../components/CustomTabs/CustomTabs";
import RasterList from "./components/RasterList/rasterList";

import { Box, Button, Tooltip, Typography } from "@mui/material";
import { getDatasets } from "../../../../../selectors/datasets";
import { getRasters } from "../../../../../selectors/rasters";
import useAddDataToolStyles from "./styles";
import { getAppLayersCount, isLayerLimitReached } from "../../../../../selectors/appData";
import InfoIcon from "@mui/icons-material/Info";
import appLayersLimit from "../../../../../utils/constants/appLayersLimit";
import DatasetsTable from "./components/DatasetsTable/DatasetsTable";
import { addDatasetToAppThunk, addRasterToAppThunk, removeDatasetFromAppThunk, removeRasterFromAppThunk } from "../../../../../actions/apps";
import { useParams } from "react-router-dom";
import { handleError } from "../../../../../utils/networkErrorUtils";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import { unwrapResult } from "@reduxjs/toolkit";

const LAYER_LIMIT_REACHED_TEXT = "Layer limit reached. In order to add new layers, try removing old ones, or create a new app.";
const LAYER_LIMIT_INFO_TEXT = "Due to performance reasons, there is a limit on the total amount of layers an app can include.";

const AddDataTool = ({ onClose, disableCheckbox }) => {
    const classes = useAddDataToolStyles();

    const { appId } = useParams();

    const [page, setPage] = useState("vector");

    const datasets = useSelector(getDatasets);
    const rasters = useSelector(getRasters);
    const appLayersCount = useSelector(getAppLayersCount);
    const layerLimitReached = useSelector(isLayerLimitReached);

    const dispatch = useDispatch();

    const changePage = (page) => {
        setPage(page);
    };

    const righthandSearchComponent = (
        <Box display="flex" alignItems="center">
            <Typography className={classes.countText} color={layerLimitReached ? "error" : ""}>
                {appLayersCount} / {appLayersLimit}
            </Typography>
            <Tooltip className={classes.marginLeft} title={layerLimitReached ? LAYER_LIMIT_REACHED_TEXT : LAYER_LIMIT_INFO_TEXT}>
                <InfoIcon fontSize="small" className={layerLimitReached ? classes.errorInfoColor : classes.infoColor} />
            </Tooltip>
        </Box>
    );

    const onToggleIncludeDataset = (dataset) => {
        const isToggled = dataset.included;

        if (isToggled) {
            removeDatasetLayer(dataset.id);
        } else {
            addLayerFromDataset(dataset);
        }
    };

    const addLayerFromDataset = (dataset) => {
        dispatch(addDatasetToAppThunk({ appId, dataset }))
            .then(unwrapResult)
            .then(() => {
                toastr.success("Dataset added");
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const onToggleIncludeRaster = (raster) => {
        const isToggled = raster.included;

        if (isToggled) {
            removeRasterLayer(raster.id);
        } else {
            addLayerFromRaster(raster);
        }
    };

    const addLayerFromRaster = (raster) => {
        dispatch(addRasterToAppThunk({ appId, raster }))
            .then(unwrapResult)
            .then(() => {
                toastr.success("Raster added");
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const removeDatasetLayer = (datasetId) => {
        dispatch(removeDatasetFromAppThunk({ appId, datasetId }))
            .then(unwrapResult)

            .then(() => {
                toastr.success("Dataset removed");
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const removeRasterLayer = (rasterId) => {
        dispatch(removeRasterFromAppThunk({ appId, rasterId }))
            .then(unwrapResult)

            .then(() => {
                toastr.success("Raster removed");
            })
            .catch((err) => {
                handleError(err);
            });
    };

    return (
        <div className="add-data-tool">
            <div className="tabs">
                <StyledTabs value={page} TabIndicatorProps={<div />}>
                    <StyledTab label={<div>Vector</div>} icon={<DatasetIcon />} iconPosition="start" onClick={() => changePage("vector")} value="vector" />
                    <StyledTab label={<div>Raster</div>} icon={<RasterIcon />} iconPosition="start" onClick={() => changePage("raster")} value="raster" />
                </StyledTabs>
                <div className="flex-grow"></div>
                <div className="close-button" onClick={onClose}>
                    <CloseIcon />
                </div>
            </div>

            <div className="page-container">
                <div className="upload-button">
                    <Button color="primary" variant="outlined" fullWidth onClick={() => dispatch(uploaderActions.open())}>
                        Upload
                    </Button>
                </div>
                <div className="dataset-list">
                    {page === "vector" && (
                        <DatasetsTable data={datasets.filter((x) => x.cacheStatus === 2)} disableCheckbox={disableCheckbox} onToggleIncludeDataset={onToggleIncludeDataset} />
                    )}
                    {page === "raster" && (
                        <RasterList
                            rasters={rasters}
                            disableCheckbox={disableCheckbox}
                            onToggleIncludeRaster={onToggleIncludeRaster}
                            righthandSearchComponent={righthandSearchComponent}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddDataTool;

import * as types from "./actionTypes";
import axiosClient from "./apiClient";
import fileDownload from "js-file-download";

export function getDatasets() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_DATASETS });

        return axiosClient
            .get("dataset")
            .then((res) => {
                return dispatch({ type: types.FETCH_DATASETS_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
}

export const createDataset = (formData, config) => {
    return (dispatch) => {
        return axiosClient
            .post("dataset", formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => {
                return dispatch({ type: types.CREATE_DATASET_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const createDatasetCsv = (formData, config) => {
    return (dispatch) => {
        return axiosClient
            .post("dataset/csv", formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => {
                return dispatch({ type: types.CREATE_DATASET_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const createDatasetKml = (formData, config) => {
    return (dispatch) => {
        return axiosClient
            .post("dataset/kml", formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => {
                return dispatch({ type: types.CREATE_DATASET_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const createDatasetZip = (formData, config) => {
    return (dispatch) => {
        return axiosClient
            .post("dataset/zip", formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => {
                return dispatch({ type: types.CREATE_DATASET_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const createDatasetGeoPackage = (formData, config) => {
    return (dispatch) => {
        return axiosClient
            .post("dataset/gpkg", formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => {
                return dispatch({ type: types.CREATE_DATASET_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const replaceDataset = (datasetId, formData, config) => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_DATASET_ADVANCED });

        return axiosClient
            .post(`dataset/${datasetId}/replace`, formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => {
                return dispatch({ type: types.UPDATE_DATASET_ADVANCED_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const downloadDataset = (datasetId, filename, format, config) => {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_DATASET });

        return axiosClient
            .get(`dataset/${datasetId}/download/${format}`, {
                ...config,
                responseType: "blob"
            })
            .then((res) => {
                dispatch({ type: types.DOWNLOAD_DATASET_COMPLETE });
                fileDownload(res.data, filename);
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const updateDataset = (id, updateRequest) => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_DATASET });

        return axiosClient
            .put("dataset/meta/" + id, updateRequest)
            .then((res) => {
                dispatch({ type: types.UPDATE_DATASET_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const updateDatasetAdvanced = (id, updateRequest) => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_DATASET_ADVANCED });

        return axiosClient
            .put("dataset/meta/" + id + "/advanced", updateRequest)
            .then((res) => {
                dispatch({ type: types.UPDATE_DATASET_ADVANCED_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const deleteDataset = (id) => {
    return (dispatch) => {
        dispatch({ type: types.DELETE_DATASET });

        return axiosClient
            .delete("dataset/meta/" + id)
            .then((res) => {
                dispatch({ type: types.DELETE_DATASET_COMPLETE, result: id });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const getDatasetDetails = (id) => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_DATASET_DETAILS });

        return axiosClient
            .get("dataset/meta/" + id)
            .then((res) => {
                return dispatch({ type: types.FETCH_DATASET_DETAILS_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const getDatasetColumns = (datasetId) => {
    return (dispatch) => {
        return axiosClient
            .get(`dataset/${datasetId}/columns`)
            .then((res) => ({ result: res.data, id: datasetId }))
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const updateDatasetColumns = (datasetId, columns) => {
    return (dispatch) => {
        return axiosClient
            .put(`dataset/${datasetId}/columns`, { columns })
            .then((res) => ({ result: res.data }))
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export function updateDatasetSchema(datasetId, schema) {
    return (dispatch) => {
        dispatch({ type: "none" });

        return axiosClient.put(`dataset/${datasetId}/schema/${schema}`).then(
            (res) => {
                return dispatch({ type: "none" });
            },
            (err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export const getDistinctColumnValues = (datasetId, column) => {
    return (dispatch) => {
        return axiosClient
            .get(`dataset/${datasetId}/distinct?column=` + column)
            .then((res) => ({ result: res.data }))
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const getMinMaxColumnValues = (datasetId, column) => {
    return (dispatch) => {
        return axiosClient
            .get(`dataset/${datasetId}/minmax?column=` + column)
            .then((res) => ({ result: res.data }))
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const generateCache = (datasetId) => {
    return (dispatch) => {
        dispatch({ type: types.GENERATE_DATASET_CACHE });

        return axiosClient
            .post(`dataset/${datasetId}/generate`)
            .then((res) => {
                dispatch({ type: types.GENERATE_DATASET_CACHE_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const cancelGenerateCache = (datasetId) => {
    return (dispatch) => {
        dispatch({ type: types.CANCEL_GENERATE_DATASET_CACHE, result: datasetId });

        return axiosClient
            .post(`dataset/${datasetId}/generate/cancel`)
            .then((res) => {
                dispatch({ type: types.CANCEL_GENERATE_DATASET_CACHE_COMPLETE, result: datasetId });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const getSchemaNames = () => {
    return (dispatch) => {
        return axiosClient
            .get("dataset/schema")
            .then((res) => ({ result: res.data }))
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const getAsGeoJson = (datasetId) => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_DATASET_GEOJSON });

        return axiosClient
            .get(`dataset/${datasetId}/geojson`)
            .then((res) => {
                return dispatch({ type: types.FETCH_DATASET_GEOJSON_COMPLETE, result: res.data.features });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

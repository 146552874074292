import React, { Component } from "react";
import { connect } from "react-redux";
import * as datasetsActions from "../../../actions/datasets";
import * as appsActions from "../../../actions/apps";
import { Route, Switch } from "react-router-dom";

import withStyles from "@mui/styles/withStyles";

import Map from "../../../components/map/mapComponent";
import AppsListView from "../appsListView/appsListView";
import AppEditView from "../appEditView/appEditView";

import MapTools from "../../../components/map/mapTools/MapTools";
import ZoomLevelWidget from "../../../components/map/mapTools/ZoomLevelWidget/ZoomLevelWidget";
import TogglePanelButton from "@mui/icons-material/NavigateNext";
import Header from "../../../components/header/header";
import HeaderButtons from "../../../components/HeaderButtons/HeaderButtons";

const styles = () => ({});

class AppsRootView extends Component {
    render() {
        let { match, unseenNotifications, isPanelClosed, panelToggle } = this.props;

        return (
            <div className="apps-rootview">
                <div className="apps-rootview__wrapper">
                    <div className={`sidebar ${isPanelClosed ? `closed` : ``}`}>
                        <div className="collapse-button" onClick={() => panelToggle()}>
                            <TogglePanelButton className={`collapse-button__icon ${!isPanelClosed ? `rotate180` : ``}`} style={{ fontSize: "1.7rem" }}></TogglePanelButton>
                        </div>

                        <Header />

                        <Switch>
                            <Route exact path={`${match.path}/`} component={AppsListView} />
                            <Route path={`${match.path}/edit/:appId`} component={AppEditView} />
                        </Switch>
                    </div>
                </div>

                <div className="map">
                    <HeaderButtons unseenNotifications={unseenNotifications} />
                    <Map>
                        <MapTools>
                            <ZoomLevelWidget />
                        </MapTools>
                    </Map>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        unseenNotifications: state.notifications.unseenNotifications,
        isPanelClosed: state.apps.isPanelClosed
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDatasets: () => dispatch(datasetsActions.getDatasets()),
        panelToggle: () => dispatch(appsActions.panelToggle())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppsRootView));

import { Checkbox, TextField } from "@mui/material";
import React, { useMemo, useState } from "react";
import OverflowTip from "../../../../../components/OverflowTip/OverflowTip";
import { ID_COLUMN } from "../../../../../utils/constants/columns";
import { usePropertiesSectionStyles } from "./styles";
import CollapsibleCard from "../../../../../components/CollapsibleCard/CollapsibleCard";

import { useFormikContext } from "formik";
import Typography from "../../../../../components/CustomTypography/CustomTypography";
const PropertiesSection = ({ setColumnsDataChanged }) => {
    const classes = usePropertiesSectionStyles();
    const [isPropertiesOpen, setIsPropertiesOpen] = useState(true);
    const { values, errors, setFieldValue } = useFormikContext();

    const togglePropertiesSection = () => {
        setIsPropertiesOpen(!isPropertiesOpen);
    };

    const allColumns = useMemo(() => !values.columns.some((column) => !column.included), [values.columns]);

    const getItemErrorMessage = (index) => {
        return errors.columns?.[index]?.prettyName;
    };

    const columnCheckboxes = useMemo(
        () =>
            values.columns.map((item, index) => {
                return (
                    <div className="checkbox-item light-grey-border" key={index}>
                        <div className="flex">
                            <div className="box">
                                <Checkbox
                                    onChange={() => {
                                        const newCols = [...values.columns];
                                        newCols[index].included = !item.included;
                                        setColumnsDataChanged(true);
                                        setFieldValue("columns", newCols);
                                    }}
                                    disabled={item.name === ID_COLUMN}
                                    className={classes.checkbox}
                                    checked={item.included}
                                />
                            </div>
                            <div className="label">
                                <OverflowTip>{item.name}</OverflowTip>
                            </div>
                        </div>
                        <TextField
                            variant="standard"
                            placeholder="Name of the dataset"
                            disabled={item.name === ID_COLUMN}
                            value={item.prettyName}
                            onChange={(e) => {
                                const newCols = [...values.columns];
                                newCols[index].prettyName = e.target.value;
                                setColumnsDataChanged(true);
                                setFieldValue("columns", newCols);
                            }}
                            error={!!getItemErrorMessage(index)}
                            helperText={getItemErrorMessage(index)}
                            fullWidth
                            margin="none"
                            InputProps={{ disableUnderline: true }}
                        />
                    </div>
                );
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [values.columns, errors.columns]
    );

    return (
        <div className="shadow-section scroll">
            <CollapsibleCard innerPadding={0} onToggleClick={togglePropertiesSection} title="Properties" isToggled={isPropertiesOpen} unmountOnExit>
                <div className="all-checkbox border-bottom">
                    <div className="all-box flex">
                        <div className="box">
                            <Checkbox
                                className={classes.checkbox}
                                checked={allColumns}
                                onClick={() => {
                                    const newCols = [...values.columns];
                                    newCols.forEach((col) => {
                                        if (col.name !== ID_COLUMN) col.included = !allColumns;
                                    });
                                    setColumnsDataChanged(true);
                                    setFieldValue("columns", newCols);
                                }}
                            />
                        </div>
                        <div className="label">
                            <Typography variant="body2" fontWeight="bold">
                                Database Name
                            </Typography>
                        </div>
                    </div>
                    <div className="label">
                        <Typography variant="body2" fontWeight="bold">
                            Edited Name
                        </Typography>
                    </div>
                </div>
                <div className="checkbox-container">{columnCheckboxes}</div>
            </CollapsibleCard>
        </div>
    );
};

export default PropertiesSection;

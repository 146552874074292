import { makeStyles } from "@mui/styles";

export const useMenuButtonCellStyles = makeStyles((theme) => ({
    menuCell: {
        position: "absolute",
        right: 0
    },
    iconContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "flex-end"
    },
    buttonContainer: {
        backgroundColor: "white",
        boxShadow: "-2px 10px 15px 0 rgba(0, 0, 0, 0.04)",
        display: "flex",
        alignContent: "center",
        justifyContent: "center"
    },
    menuIcon: {
        "&:hover": {
            cursor: "pointer"
        },
        background: "#e3e4e5",
        color: "black",
        margin: "14px 16px"
    }
}));

import React from "react";

import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import ZoomIcon from "@mui/icons-material/Search";
import Palette from "@mui/icons-material/Palette";
import Delete from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as mapActions from "../../../../reducers/map";
import toastr from "../../../../components/CustomToastr/CustomToastr";
import { toggleAppLayer } from "../../../../actions/globalActions";
import { removeDatasetFromAppThunk, removeRasterFromAppThunk } from "../../../../actions/apps";
import { handleError } from "../../../../utils/networkErrorUtils";
import { unwrapResult } from "@reduxjs/toolkit";

const Context = ({ anchorEl, open, layer, toggleCMenu, onLayerSelected, markForRecomputation }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { appId } = useParams();

    const zoomToLayer = (e) => {
        e.stopPropagation();
        toggleCMenu();
        let bounds = [layer.bounds.coordinates[0][0], layer.bounds.coordinates[0][2]];
        dispatch(mapActions.fitMapBounds({ bounds, options: { padding: 100, animate: false } }));
        dispatch(toggleAppLayer(layer.resourceId, true));
    };

    const pathname = () => {
        if (layer.type === "raster") {
            return "/rasters/edit/" + layer.resourceId;
        }
        return "/datasets/edit/" + layer.resourceId;
    };

    function editResource(e) {
        e.stopPropagation();
        toggleCMenu();
        history.push(pathname(), { prevLocation: history.location });
    }

    const onRemoveLayer = (layer) => {
        markForRecomputation();
        if (layer.type === "raster") {
            removeRasterLayer(layer.resourceId);
        } else {
            removeDatasetLayer(layer.resourceId);
        }
    };

    const removeDatasetLayer = (datasetId) => {
        dispatch(removeDatasetFromAppThunk({ appId, datasetId }))
            .then(unwrapResult)
            .then(() => {
                toastr.success("Dataset removed");
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const removeRasterLayer = (rasterId) => {
        dispatch(removeRasterFromAppThunk({ appId, rasterId }))
            .then(unwrapResult)
            .then(() => {
                toastr.success("Raster removed");
            })
            .catch((err) => {
                handleError(err);
            });
    };

    function removeLayer(e, layer) {
        e.stopPropagation();
        toggleCMenu();

        const toastrConfirmOptions = {
            onOk: () => onRemoveLayer(layer),
            onCancel: () => {}
        };
        toastr.confirm(`Are you sure you want to delete dataset: ${layer.name} ?`, toastrConfirmOptions);
    }

    function changeLayerStyle(e, layer) {
        e.stopPropagation();
        toggleCMenu();
        onLayerSelected(layer);
    }

    return (
        <Menu anchorEl={anchorEl} open={Boolean(open)} onClose={toggleCMenu}>
            <MenuItem onClick={zoomToLayer} className="menu-button">
                <ZoomIcon className="icon" />
                Zoom to layer
            </MenuItem>

            <MenuItem onClick={(e) => editResource(e)} className="menu-button">
                <EditIcon className="icon" />
                Edit dataset
            </MenuItem>

            <MenuItem onClick={(e) => removeLayer(e, layer)} className="menu-button">
                <Delete className="icon" />
                Remove dataset
            </MenuItem>
            <MenuItem onClick={(e) => changeLayerStyle(e, layer)} className="menu-button">
                <Palette className="icon" />
                Change layer style
            </MenuItem>
        </Menu>
    );
};

export default Context;

import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddCircle";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { withRouter } from "react-router-dom";
import CreateModal from "./CreateUserModal/CreateUserModal";
import EditModal from "./EditModal/EditModal";

import * as userActions from "../../../actions/userService";
import toastr from "../../../components/CustomToastr/CustomToastr";
import User from "./components/user";

import ListView from "../../../components/listView";
import userSorting from "./components/userSorting";

const styles = () => ({
    title: {
        flexGrow: 1
    },
    icon: {
        marginRight: 8
    }
});

class UsersListView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sorting: "name",
            sortingReverse: false,
            rowsPerPage: 10,
            page: 0,
            modalOpen: false,
            fetching: false,
            editUserModalOpen: false,
            selected: false,
            selectedUserId: null
        };

        this.columns = userSorting;
    }

    onUserClick = (item) => {
        this.setState({
            editUserModalOpen: true,
            selectedUserId: item.id,
            selected: true
        });
    };

    onEditUserModalClose = () => {
        this.setState({
            editUserModalOpen: false,
            selectedUserId: null,
            selected: false
        });
    };

    onPageChange = (event, page) => {
        this.setState({
            page: page
        });
    };

    onChangeRowsPerPage = (e) => {
        this.setState({
            rowsPerPage: e.target.value
        });
    };

    onAddUserClick = () => {
        this.setState({
            modalOpen: true
        });
    };

    onModalClose = () => {
        this.setState({
            modalOpen: false
        });
    };

    deleteUser = (user) => {
        this.setState({
            fetching: true
        });
        this.props.deleteUser(user.id).then(
            () => {
                toastr.success("User deleted");
                this.setState({
                    fetching: false
                });
            },
            (err) => {
                toastr.error(err.message);
                this.setState({
                    fetching: false
                });
            }
        );
    };

    onDeleteUser = (user) => {
        const toastrConfirmOptions = {
            onOk: () => this.deleteUser(user),
            onCancel: () => {}
        };
        toastr.confirm("Are you sure you want to delete: " + user.username, toastrConfirmOptions);
    };

    generateItem = (user, index) => {
        return <User user={user} selected={this.state.selected} key={index} OnClick={this.onUserClick} onDeleteClick={this.onDeleteUser} disabled={this.state.fetching} />;
    };

    filterItem(item, query) {
        return item.username.toLowerCase().includes(query.toLowerCase());
    }

    render() {
        let { classes } = this.props;

        return (
            <div className="sidebar-container users-overview">
                <CreateModal open={this.state.modalOpen} handleClose={this.onModalClose} disabled={this.state.fetching} />
                <EditModal open={this.state.editUserModalOpen} handleClose={this.onEditUserModalClose} userId={this.state.selectedUserId} />
                <div className="header">
                    <Typography variant="h6" className={classes.title}>
                        Users
                    </Typography>
                    <Button color="primary" variant="contained" onClick={this.onAddUserClick}>
                        <AddIcon className="button-icon" fontSize="small" />
                        Add User
                    </Button>
                </div>
                {this.props.fetching && <LinearProgress className="no-margin-progress" />}
                <ListView
                    searchPlaceholder={"Search users"}
                    data={this.props.users}
                    columns={this.columns}
                    generateItem={this.generateItem}
                    filterFunction={this.filterItem}
                ></ListView>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.users.users,
        fetching: state.users.fetching
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteUser: (userId) => dispatch(userActions.deleteUser(userId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(UsersListView)));

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Route, useLocation, useRouteMatch, Switch as RouterSwitch } from "react-router-dom";
import { getUnseenNotifications } from "../../../selectors/notifications";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Map from "../../../components/map/mapComponent";
import DatasetsListView from "../datasetsListView/datasetsListView";
import DatasetEditView from "../datasetEditView/datasetEditView";
import TableView from "../tableView/tableView";
import Header from "../../../components/header/header";
import { getDatasetId } from "../../../selectors/tableView";
import MapTools from "../../../components/map/mapTools/MapTools";
import ZoomLevelWidget from "../../../components/map/mapTools/ZoomLevelWidget/ZoomLevelWidget";
import HeaderButtons from "../../../components/HeaderButtons/HeaderButtons";

const DatasetRootViewFunctional = () => {
    const [showDataView, setShowDataView] = useState(false);

    const datasetId = useSelector(getDatasetId);
    const unseenNotifications = useSelector(getUnseenNotifications);

    const location = useLocation();
    const match = useRouteMatch("/datasets");

    const onToggleDataView = () => {
        setShowDataView(!showDataView);
    };

    const renderTableSwitcher = () =>
        datasetId && (
            <div className="map-data-switch">
                <Typography color="inherit" variant="subtitle1">
                    Map
                </Typography>
                <Switch checked={showDataView} onChange={onToggleDataView} value="checkedA" inputProps={{ "aria-label": "secondary checkbox" }} />
                <Typography color="inherit" variant="subtitle1">
                    Data
                </Typography>
            </div>
        );

    return (
        <div className="datasets-rootview">
            <div className="sidebar">
                <div className="header">
                    <Header />
                    <HeaderButtons unseenNotifications={unseenNotifications} />
                </div>

                <RouterSwitch>
                    <Route exact path={`${match.path}/`} component={DatasetsListView} />
                    <Route path={`${match.path}/edit/:datasetId`} component={DatasetEditView} />
                </RouterSwitch>
            </div>
            <div className={location.pathname !== "/datasets" ? "map" : "map hidden"}>
                <Map>
                    <MapTools direction="column" alignItems="flex-end">
                        <ZoomLevelWidget />
                        {renderTableSwitcher()}
                    </MapTools>
                </Map>
                {showDataView && <TableView />}
            </div>
        </div>
    );
};

export default DatasetRootViewFunctional;

import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { TableRow } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import CacheIcon from "@mui/icons-material/Cached";
import * as EnumUtils from "../../../../../utils/enumUtils";
import PublishIcon from "@mui/icons-material/Publish";
import GetAppIcon from "@mui/icons-material/GetApp";
import Typography from "../../../../../components/CustomTypography/CustomTypography";
import dayjs from "dayjs";
import { fromNowWithCheck } from "../../../../../utils/timeUtils";
import TooltipCell from "../../../../../components/CustomTable/components/customCells/TooltipCell/TooltipCell";
import OverflowCell from "../../../../../components/CustomTable/components/customCells/OverflowCell/OverflowCell";
import MenuButtonCell from "../../../../../components/CustomTable/components/customCells/MenuButtonCell/MenuButtonCell";
import { CELL_SIZES } from "../../../../../utils/constants/cellSizes";
import { useDatasetRowStyles } from "./styles";
import styles from "../../../../../scss/themeVariables.module.scss";
import { getGeometryIcon } from "../../../../../utils/datasets/getGeometryIcon";
import { cacheStateColor } from "../../../../../utils/datasets/cacheStateColor";
export default function DatasetRow(props) {
    const classes = useDatasetRowStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const onMenuClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const onEditClick = (e) => {
        onMenuClose(e);
        e.stopPropagation();
        props.onEditClick(props.dataset);
    };

    const onGenerateClick = (e) => {
        onMenuClose(e);
        e.stopPropagation();
        props.onGenerateClick(props.dataset);
    };

    const onReplaceDataset = (e) => {
        onMenuClose(e);
        e.stopPropagation();
        props.onReplaceDataset(props.dataset);
    };

    const onDownloadDataset = (e) => {
        onMenuClose(e);
        e.stopPropagation();
        props.onDownloadDataset(props.dataset);
    };

    const onDeleteClick = (e) => {
        onMenuClose(e);
        e.stopPropagation();
        props.onDeleteClick(props.dataset);
    };

    const cacheStatusToClass = (cacheStatus) => {
        return EnumUtils.toCacheStatusString(cacheStatus);
    };

    const renderDatasetMenu = () => (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
            <MenuItem onClick={onEditClick} className="menu-button">
                <EditIcon className="icon" />
                <Typography variant="subtitle1" fontWeight="semibold">
                    Edit
                </Typography>
            </MenuItem>
            <MenuItem disabled={props.dataset.cacheStatus === 1} onClick={onGenerateClick} className="menu-button">
                <CacheIcon className="icon" />
                <Typography variant="subtitle1" fontWeight="semibold">
                    Generate
                </Typography>
            </MenuItem>
            <MenuItem onClick={onReplaceDataset} className="menu-button">
                <PublishIcon className="icon" />
                <Typography variant="subtitle1" fontWeight="semibold">
                    Replace Data
                </Typography>
            </MenuItem>
            <MenuItem onClick={onDownloadDataset} className="menu-button">
                <GetAppIcon className="icon" />
                <Typography variant="subtitle1" fontWeight="semibold">
                    Download Data
                </Typography>
            </MenuItem>
            <MenuItem onClick={onDeleteClick} className="menu-button">
                <DeleteIcon className="icon" />
                <Typography variant="subtitle1" fontWeight="semibold">
                    Delete
                </Typography>
            </MenuItem>
        </Menu>
    );

    const cacheStatus = cacheStatusToClass(props.dataset.cacheStatus);

    return (
        <TableRow onDoubleClick={() => props.onDoubleClick(props.dataset)} onClick={() => props.onClick(props.dataset)}>
            <TooltipCell tooltipTitle={cacheStatus}>
                <SvgIcon color={cacheStateColor[cacheStatus]}>{getGeometryIcon(props.dataset.geometryType)}</SvgIcon>
            </TooltipCell>

            <OverflowCell cellSize={CELL_SIZES.small}>{props.dataset.schemaName?.replace(/_/g, " ")}</OverflowCell>

            <OverflowCell color={styles.primaryColor} className={classes.nameCell}>
                {props.dataset.name}
            </OverflowCell>

            <OverflowCell cellSize={CELL_SIZES.small}>{props.dataset.tableName}</OverflowCell>

            <TooltipCell>{props.dataset.projection}</TooltipCell>

            <TooltipCell>
                {props.dataset.minZoom} - {props.dataset.maxZoom}
            </TooltipCell>

            <TooltipCell>{props.dataset.databaseSize}</TooltipCell>

            <TooltipCell>{props.dataset.rowCount}</TooltipCell>

            <TooltipCell tooltipTitle={dayjs(props.dataset.modifiedUtc).format("MMMM DD YYYY, h:mm:ss a")}>{fromNowWithCheck(dayjs(props.dataset.modifiedUtc))}</TooltipCell>

            <TooltipCell tooltipTitle={dayjs(props.dataset.createdUtc).format("MMMM DD YYYY, h:mm:ss a")}>{fromNowWithCheck(dayjs(props.dataset.createdUtc))}</TooltipCell>

            <MenuButtonCell onMenuClick={onMenuClick} />

            {renderDatasetMenu()}
        </TableRow>
    );
}

import React from "react";
import { useSelector } from "react-redux";
import ListView from "../../../../../../../components/listView";
import { getIncludedRasters, isLayerLimitReached } from "../../../../../../../selectors/appData";
import rasterColumns from "./rasterColumns";
import RasterListItem from "../../../rasterListItemToggle";

export default function RasterList({ rasters, disableCheckbox, onToggleIncludeRaster, righthandSearchComponent }) {
    const includedRasters = useSelector(getIncludedRasters);
    const layerLimitReached = useSelector(isLayerLimitReached);

    const filterItem = (item, query) => {
        return item.name.toLowerCase().includes(query.toLowerCase());
    };

    const generateItem = (raster, index) => {
        return (
            <RasterListItem
                raster={{ ...raster, included: !!includedRasters[raster.id] }}
                disabled={disableCheckbox || (layerLimitReached && !includedRasters[raster.id])}
                onToggle={onToggleIncludeRaster}
                key={raster.id}
            />
        );
    };

    return (
        <ListView
            searchPlaceholder={"Search raster"}
            data={rasters}
            columns={rasterColumns}
            generateItem={generateItem}
            filterFunction={filterItem}
            righthandSearchComponent={righthandSearchComponent}
        />
    );
}

import jwt_decode from "jwt-decode";

const ROLE_CLAIM_NAME = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
export function getUserRoleFromToken(jwt) {
    let tokenObj = jwt_decode(jwt);
    return tokenObj[ROLE_CLAIM_NAME];
}

export const ADMIN_ROLE = "admin";
export const USER_ROLE = "user";
export const UPLOADER_ROLE = "uploader";

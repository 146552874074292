import { AppBar, Toolbar } from "@mui/material";
import React from "react";

const Header = () => {
    return (
        <AppBar position="static">
            <Toolbar className="toolbar">
                <img className="logo" src="/Logo.svg" alt="Lautec WindGIS"></img>
            </Toolbar>
        </AppBar>
    );
};

export default Header;

const config = {
    apiUrl: "https://api.esox2.windgis.io/",
    basemap: "mapbox://styles/lautec/ck949aof41kc41itbk257vk78",
    mapboxApiKey: "pk.eyJ1IjoibGF1dGVjIiwiYSI6ImNrNzRpNjFxaTBpc2IzZnBkNHU3MGJoMmoifQ.yNFawhQiPQGVujuEwb-TIg",
    auth0Domain: "lautec-gis.au.auth0.com",
    auth0Audience: "esox2",
    auth0ClientId: "jkraoHIX80YmQYdgWkNcOPkKDBILZfpd"
};

export default config;

import { makeStyles } from "@mui/styles";
import styles from "../../scss/themeVariables.module.scss";

export const useSearchBarStyles = makeStyles((theme) => ({
    searchIcon: {
        color: styles.darkGrey,
        width: 20
    },
    searchField: {
        minWidth: 380,
        "& .MuiFilledInput-input": {
            padding: 0,
            paddingLeft: 8
        },
        "& .MuiInputAdornment-filled.MuiInputAdornment-positionStart": {
            marginTop: 0
        }
    },
    clearTextButton: {
        display: "none"
    },
    searchContainer: {
        padding: "12px " + theme.customSpacing.generalSidePadding
    },
    grayBG: {
        backgroundColor: theme.customColors.appBgColor
    },
    visible: {
        display: "flex"
    }
}));

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Menu, MenuItem, TableRow } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import MenuButtonCell from "../../../../../components/CustomTable/components/customCells/MenuButtonCell/MenuButtonCell";
import NameCell from "../../../../../components/CustomTable/components/customCells/NameCell/NameCell";
import TooltipCell from "../../../../../components/CustomTable/components/customCells/TooltipCell/TooltipCell";
import Typography from "../../../../../components/CustomTypography/CustomTypography";
import { fromNowWithCheck } from "../../../../../utils/timeUtils";

const AppRow = ({ dataset, app, onEditClick, onDeleteClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const onMenuClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const onEdit = (e) => {
        onMenuClose(e);
        onEditClick(dataset);
    };

    const onDelete = (e) => {
        onMenuClose(e);
        onDeleteClick(dataset);
    };

    return (
        <TableRow onClick={() => onEditClick(app)}>
            <NameCell>{app.name}</NameCell>

            <TooltipCell tooltipTitle={dayjs(app.createdUtc).format("LLL")}>
                <Typography variant="body2">{fromNowWithCheck(dayjs(app.createdUtc))}</Typography>
            </TooltipCell>

            <TooltipCell tooltipTitle={dayjs(app.modifiedUtc).format("LLL")}>
                <Typography variant="body2">{fromNowWithCheck(dayjs(app.modifiedUtc))}</Typography>
            </TooltipCell>

            <MenuButtonCell onMenuClick={onMenuClick} />

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
                <MenuItem onClick={onEdit} className="menu-button">
                    <EditIcon className="icon" />
                    Edit
                </MenuItem>
                <MenuItem onClick={onDelete} className="menu-button">
                    <DeleteIcon className="icon" />
                    Delete
                </MenuItem>
            </Menu>
        </TableRow>
    );
};

export default AppRow;

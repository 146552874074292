import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    profileInfo: {
        display: "flex",
        textAlign: "center",
        flexDirection: "column"
    },
    signOut: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-evenly"
    },
    paperRoot: {
        backgroundColor: theme.customColors.appBgColorLight
    },
    button: {
        padding: 0,
        margin: 0,
        color: theme.customColors.whiteText,
        width: "100%",
        height: "100%",
        minWidth: "initial"
    },
    justifyCenter: {
        justifyContent: "center"
    },
    justifyStart: {
        justifyContent: "start"
    },
    logoutButton: {
        position: "absolute",
        left: 0,
        bottom: 0
    },
    itemWrapper: {
        width: "100%"
    }
}));

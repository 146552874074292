//The promise from the returned props will resolve to the getAccessTokenSilently from auth0
//This acts as a getter for the function which will hang until the function is provided
export const deferredGetTokenPromise = (() => {
    const props = {};
    props.promise = new Promise((resolve) => (props.resolve = resolve));
    return props;
})();
//Here we will await both the provision of the function and then call it, thus returning a promise with the new token
export const getAccessToken = async () => {
    const getToken = await deferredGetTokenPromise.promise;
    return getToken();
};

import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import fileCopyIcon from "../../../../../utils/icons/copy-icon.svg";
import maPpreview from "../../../../../utils/icons/mapImage.png";
import { Button, IconButton, Tooltip } from "@mui/material";
import Chip from "@mui/material/Chip";

import toastr from "../../../../../components/CustomToastr/CustomToastr";

const BasemapCard = ({ basemap, numberOfBasemaps, onDeleteClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const onMenuClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const deleteClick = () => {
        onMenuClose();
        const toastrConfirmOptions = {
            onOk: () => onDeleteClick(basemap),
            onCancel: () => {}
        };
        toastr.confirm("Are you sure you want to delete basemap: " + basemap.title, toastrConfirmOptions);
    };

    return (
        <div className="basemap">
            <DragIndicatorIcon className="drag-indicator" fontSize="small" />
            <div className="map-preview">
                <img alt="" src={maPpreview} />
            </div>
            <div className="basemap-details">
                <div className="basemap-title">{basemap.title}</div>
                <div className="basemap-url">
                    <div>{basemap.url}</div>{" "}
                    <Button
                        className="copy-button"
                        onClick={() => {
                            navigator.clipboard.writeText(basemap.url);
                            toastr.success("The text has been copied");
                        }}
                    >
                        <img alt="" src={fileCopyIcon} />
                    </Button>
                </div>
            </div>
            <Chip className={`type-chip ${basemap.type === "vector" ? `vector` : `raster`}`} label={basemap.type} />
            <IconButton className="menu-button" onClick={onMenuClick} size="large">
                <MoreHoriz fontSize="small" />
            </IconButton>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
                {/*Buttons that appear in the design but have no functionality
                <MenuItem className="menu-button">
                    <EditIcon className="icon" />
                    Edit basemap
                </MenuItem>
                <MenuItem className="menu-button">
                    <VisibilityIcon className="icon" />
                    Preview
                </MenuItem> */}
                {numberOfBasemaps === 1 ? (
                    <Tooltip title="There needs to be at least a basemap">
                        <div>
                            <MenuItem disabled className="menu-button">
                                <DeleteIcon className="icon" />
                                Remove
                            </MenuItem>
                        </div>
                    </Tooltip>
                ) : (
                    <MenuItem onClick={deleteClick} className="menu-button">
                        <DeleteIcon className="icon" />
                        Remove
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default BasemapCard;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthToken } from "./actions/auth";
import { getStyleConfig } from "./actions/config";
import LoaderComponent from "./components/auth0Loader/LoaderComponent";
import { deferredGetTokenPromise } from "./utils/getAccessToken";
import { useAuth0 } from "@auth0/auth0-react";
import apiClient from "./actions/apiClient";
import { refreshToken } from "./utils/refreshToken";

const Bootstrap = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();

    const [criticalResourcesLoaded, setCriticalResourcesLoaded] = useState(false);
    const [tokenRetrieved, setTokenRetrieved] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        // Update the document title using the browser API
        deferredGetTokenPromise.resolve(getAccessTokenSilently);

        refreshToken().then((token) => {
            dispatch(setAuthToken(token));
            setTokenRetrieved(true);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tokenRetrieved) {
            const styleConfigPromise = dispatch(getStyleConfig());
            const criticalPromises = [styleConfigPromise];
            Promise.all(criticalPromises).then(() => setCriticalResourcesLoaded(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenRetrieved]);

    const loadingText = () => {
        switch (true) {
            case !tokenRetrieved:
                return "Authenticating";
            case !criticalResourcesLoaded:
                return "Getting user data";
            default:
                return "";
        }
    };

    if (!criticalResourcesLoaded || !tokenRetrieved) return <LoaderComponent text={loadingText()} />;

    return children;
};

export default Bootstrap;

import React, { useState } from "react";
import toastr from "../../../../components/CustomToastr/CustomToastr";

import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OverflowTip from "../../../../components/OverflowTip/OverflowTip";
import Typography from "../../../../components/CustomTypography/CustomTypography";
import { useTheme } from "@mui/styles";

import { metadataTypes } from "../../../../utils/constants/metadataTypes";

export default function MetadataField({ field, selected, onDelete, onEdit }) {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);

    const onMenuClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const onDeleteClick = (e) => {
        onMenuClose(e);
        e.stopPropagation();
        const toastrConfirmOptions = {
            onOk: () => onDelete(field),
            onCancel: () => {}
        };
        toastr.confirm(`Are you sure you want to delete property: ${field.name}?`, toastrConfirmOptions);
    };

    const onEditClick = (e) => {
        onMenuClose(e);
        e.stopPropagation();
        onEdit(field);
    };

    const fieldTypePretty = {
        [metadataTypes.TAG_LIST]: "Tag List"
    };

    return (
        <div className="item" onClick={onEditClick}>
            <div className="container">
                <div className="row-container">
                    <div className={"row-1"}>
                        <div className="schema">
                            <div className="overflow-text">
                                <Typography variant="body2">{fieldTypePretty[field.type] || field.type}</Typography>
                            </div>
                        </div>
                        <div className="name">
                            <OverflowTip variant="body2" fontWeight="semibold" color={theme.customColors.primaryColor}>
                                {field.name}
                            </OverflowTip>
                        </div>
                        <Tooltip id="tooltip-top" title="Options">
                            <div className="dataset-table-cell button" onClick={onMenuClick}>
                                <MenuIcon />
                            </div>
                        </Tooltip>
                    </div>
                </div>

                <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={onMenuClose}>
                    <MenuItem onClick={onEditClick} className="menu-button">
                        <EditIcon className="icon" />
                        Edit
                    </MenuItem>
                    <MenuItem onClick={onDeleteClick} className="menu-button">
                        <DeleteIcon className="icon" />
                        Delete
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
}

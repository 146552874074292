import makeStyles from "@mui/styles/makeStyles";

export const useBasemapPickerStyles = makeStyles((theme) => ({
    subHeader: {
        margin: `-${theme.customSpacing.generalSidePadding} -${theme.customSpacing.generalSidePadding} ${theme.customSpacing.generalSidePadding}`,
        width: "auto"
    },
    modalContent: {
        display: "flex",
        flexDirection: "column",
        width: 660,
        maxHeight: 400,
        marginBottom: `-${theme.customSpacing.generalSidePadding}`,
        overflow: "auto",

        "& .filler, & .pagination": {
            display: "none"
        },

        "& .name": {
            marginLeft: 32
        },

        "& .search": {
            backgroundColor: "initial",
            padding: "22px 0px"
        },

        "& .scroll-container": {
            borderTop: `1px solid ${theme.customColors.borderColor}`
        }
    },
    fieldsContainer: {
        margin: "22px 0"
    },
    selectField: {
        width: 168,
        marginRight: 20
    }
}));

export default useBasemapPickerStyles;

import makeStyles from "@mui/styles/makeStyles";

export const useCustomTableHeadStyles = makeStyles((theme) => ({
    tableCell: {
        "&:hover $visibleOnHover": {
            display: "initial"
        },
        "&:hover $visibleWhenNotHovering": {
            display: "none"
        },
        "&:hover $arrowPlaceholder": {
            marginRight: 0
        }
    },
    tableCellContent: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center"
    },
    hidden: {
        display: "none"
    },
    visibleOnHover: {
        display: "none"
    },
    active: {
        borderBottom: "1px solid " + theme.palette.primary.main,
        color: theme.palette.primary.main
    },
    button: {
        cursor: "pointer"
    },
    arrowPlaceholder: {
        marginRight: 20
    },
    sortingArrow: {
        marginLeft: 4,
        width: 16
    }
}));

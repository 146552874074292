import makeStyles from "@mui/styles/makeStyles";

export const usePropertiesSectionStyles = makeStyles((theme) => ({
    checkbox: {
        padding: 0
    },
    expandedRight: {
        transform: "rotate(-180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    collapsed: {
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    }
}));

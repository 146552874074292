import React from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import CancelIcon from "@mui/icons-material/Close";

import toastr from "../CustomToastr/CustomToastr";

import { Typography, LinearProgress } from "@mui/material";
import * as datasetsActions from "../../actions/datasets";
import * as notificationsActions from "../../actions/notifications";

import NotificationButton from "./notificationButton";

const styles = () => ({});

const Notifications = (props) => {
    const onCancelClick = (notification) => {
        const toastrConfirmOptions = {
            onOk: () => props.cancelJob(notification.id),
            onCancel: () => {}
        };
        toastr.confirm("Stop " + notification.name, toastrConfirmOptions);
    };

    function toCacheStatusString(cacheStatus) {
        switch (cacheStatus) {
            case 0:
                return "Queued";
            case 1:
                return "Caching";
            case 2:
                return "Completed";
            default:
                console.error("Cache status error");
        }
    }

    return (
        <div className={"notifications " + (props.open ? "open" : "")}>
            <div className="notifications-title">
                <Typography variant="h6">Notifications</Typography>
                <NotificationButton />
            </div>
            <div className="notifications-container">
                {props.notifications.length === 0 && (
                    <Typography style={{ textAlign: "center" }} variant="body2">
                        No new notifications
                    </Typography>
                )}
                {props.notifications.map((notification, index) => {
                    let percentage = (notification.tileCount / notification.tileGoal || 0) * 100;

                    let statusText = toCacheStatusString(notification.cacheStatus);

                    if (notification.error) {
                        statusText = "Failed";
                    } else if (notification.cancelling && !notification.wasCancelled) {
                        statusText = "Cancelling";
                    } else if (notification.wasCancelled) {
                        statusText = "Cancelled";
                    }

                    return (
                        <div className="notification" key={index}>
                            <div className="title-container">
                                <div className="title">{notification.name}</div>

                                <div className="grow"></div>
                                {notification.error && (
                                    <Tooltip title={notification.errorTitle + ": " + notification.errorMessage}>
                                        <ErrorIcon className="error-icon" />
                                    </Tooltip>
                                )}
                                {notification.cacheStatus === 1 ? (
                                    <Tooltip title="Cancel">
                                        <div onClick={() => onCancelClick(notification)} className="cancel-button">
                                            <CancelIcon />
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Dismiss Notification">
                                        <div onClick={() => props.onDismissNotifClick(notification.id)} className="cancel-button">
                                            <CancelIcon />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                            <div className="info">
                                <Typography className="text" variant="subtitle2">
                                    {statusText}
                                </Typography>
                                <Typography variant="subtitle2">{Math.min(percentage.toFixed(2), 100)}%</Typography>
                            </div>
                            <div className="progress-bar">
                                <LinearProgress className="progress" variant="determinate" value={Math.min(percentage.toFixed(2), 100)} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    notifications: state.notifications.notifications,
    unseenNotifications: state.notifications.unseenNotifications,
    open: state.notifications.open
});

const mapDispatchToProps = {
    cancelJob: datasetsActions.cancelGenerateCache,
    onDismissNotifClick: notificationsActions.dismissNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Notifications));

import React from "react";
import { Tooltip, Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import useBeforeUploadStyles from "./styles";
import selectFromComp from "../../../../utils/icons/select_from_comp.svg";
import Typography from "../../../CustomTypography/CustomTypography";
import { useTheme } from "@mui/styles";

const BeforeUpload = ({ uploads, dragging, acceptedFormats, onFileDrop, onFileDragLeave, onFileDragOver, onHoverDone, onQueueAllUploads, onFileChanged }) => {
    const classes = useBeforeUploadStyles();
    const theme = useTheme();
    return (
        <div className={`bottom-container ${dragging ? `dragging` : ``}`} onDrop={onFileDrop} onDragLeave={onFileDragLeave} onDragOver={onFileDragOver} onMouseLeave={onHoverDone}>
            <div className={classes.actions}>
                <Tooltip title="Upload All">
                    <div>
                        <Button disabled={uploads.length === 0} variant="filled" onClick={onQueueAllUploads}>
                            <CloudUploadIcon />
                        </Button>
                    </div>
                </Tooltip>
            </div>
            <Typography className={classes.centered} variant="subtitle1" fontWeight="bold" gutterBottom>
                Drag and drop files here
            </Typography>
            <Typography
                className={classes.centered}
                variant="subtitle1"
                fontWeight="semibold"
                color={theme.customColors.appTextColorLight}
                gutterBottom
                id="transition-modal-title"
            >
                - or -
            </Typography>
            <Button
                className={classes.selectButton}
                variant="contained"
                color="secondary"
                onClick={() => {
                    document.getElementById("file-button").click();
                }}
            >
                <img alt="" src={selectFromComp} />
                Select from computer
            </Button>
            <input accept={acceptedFormats} style={{ display: "none" }} multiple={true} id="file-button" type="file" onChange={onFileChanged} />
            {dragging && (
                <div className="drag-overlay">
                    <CloudUploadIcon />
                    <div>Drop files here</div>
                </div>
            )}
        </div>
    );
};

export default BeforeUpload;

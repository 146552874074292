import React from "react";
import { TextField, ButtonBase } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDayJs from "@mui/lab/AdapterDayjs";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { useFormikContext } from "formik";
import { metadataTypes } from "../../../../../utils/constants/metadataTypes";
import Typography from "../../../../../components/CustomTypography/CustomTypography";

const MetadataTab = ({ metadata, setBasicDataChanged }) => {
    const { values, errors, handleChange, setFieldValue } = useFormikContext();
    const formikProps = (metadata) => ({
        value: values[metadata.name] || "",
        onChange:
            metadata.type === metadataTypes.DATE
                ? (val) => {
                      setBasicDataChanged(true);
                      setFieldValue(metadata.name, val);
                  }
                : (e) => {
                      setBasicDataChanged(true);
                      handleChange(e);
                  },
        name: metadata.name,
        error: !!errors[metadata.name],
        helperText: errors[metadata.name]
    });

    function onTagChange(item, tag) {
        const tags = values[item.name].map((x) => {
            if (x.name === tag.name) {
                return {
                    ...x,
                    enabled: !x.enabled
                };
            }
            return x;
        });
        setBasicDataChanged(true);
        setFieldValue(item.name, tags);
    }

    function renderItem(item) {
        switch (item.type) {
            case metadataTypes.TEXT:
                return <TextField id="filled-required" label={item.name} variant="filled" fullWidth {...formikProps(item)} />;
            case metadataTypes.NUMBER:
                return <TextField id="filled-required" label={item.name} variant="filled" fullWidth {...formikProps(item)} />;
            case metadataTypes.DATE:
                return (
                    <LocalizationProvider dateAdapter={AdapterDayJs}>
                        {/*We use the Mobile Date picker because this doesn't allow the user to write dates manually. The normal date picker has some weird behaviour that it's better to avoid if possible*/}
                        <MobileDatePicker
                            clearable
                            label={item.name}
                            renderInput={(props) => <TextField fullWidth variant="filled" helperText={errors[item.name]} {...props} />}
                            //onchange doesn't return an event so we have to use setfieldValue this time
                            {...formikProps(item)}
                        />
                    </LocalizationProvider>
                );
            case metadataTypes.TAG_LIST:
                return (
                    <div>
                        <Typography variant="body1" className="tag-list-header">
                            {item.name}
                        </Typography>
                        <div className="tag-list">
                            {values[item.name].map((tag, index) => {
                                return (
                                    <ButtonBase
                                        key={tag.id}
                                        className={tag.enabled ? "tag enabled" : "tag"}
                                        onClick={() => onTagChange(item, tag)}
                                        style={tag.enabled ? { borderColor: tag.color, backgroundColor: tag.color } : { borderColor: tag.color }}
                                    >
                                        <Typography variant="caption" className="text">
                                            {tag.name}
                                        </Typography>
                                    </ButtonBase>
                                );
                            })}
                        </div>
                    </div>
                );
            default:
                return null;
        }
    }
    return (
        <div>
            {metadata.map((item, index) => {
                return (
                    <div key={index} className="field">
                        {renderItem(item)}
                    </div>
                );
            })}
        </div>
    );
};

export default MetadataTab;

import React from "react";
import dayjs from "dayjs";
import { useTheme } from "@mui/styles";
import { Tooltip } from "@mui/material";
import { fromNowWithCheck } from "../../../../utils/timeUtils";
import OverflowTip from "../../../../components/OverflowTip/OverflowTip";
import UncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckedIcon from "@mui/icons-material/CheckCircleOutline";

export default function RasterListItem(props) {
    const theme = useTheme();
    return (
        <div className={props.selected ? "item selected" : "item"} onDoubleClick={() => props.onDoubleClick(props.raster)} onClick={() => props.onClick(props.raster)}>
            <div className="container">
                <div className="row-container">
                    <div className={"row-1"}>
                        <div>
                            {props.isChecked === props.raster.name ? (
                                <CheckedIcon className="checkbox-mark checked"></CheckedIcon>
                            ) : (
                                <UncheckedIcon className="checkbox-mark"></UncheckedIcon>
                            )}
                        </div>
                        <div className="name">
                            <div className="overflow-text">
                                <OverflowTip variant="body2" color={theme.customColors.primaryColor} fontWeight="semibold">
                                    {props.raster.name}
                                </OverflowTip>
                            </div>
                        </div>
                        <Tooltip id="tooltip-top" title={dayjs(props.raster.modifiedUtc).format("LLL")}>
                            <div className="date">{fromNowWithCheck(dayjs(props.raster.modifiedUtc))}</div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
}

import { createSlice } from "@reduxjs/toolkit";
import { getStyleConfig } from "../actions/config";

const initialState = {
    config: null
};

const styleSlice = createSlice({
    name: "style",
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder.addCase(getStyleConfig.fulfilled, (state, { payload }) => {
            state.config = payload;
        })
});

export default styleSlice.reducer;

import React from "react";
import { useHistory } from "react-router-dom";
import { IconButton } from "@mui/material";
import useAppEditHeaderStyles from "./styles";
import { useSelector } from "react-redux";
import { getApp } from "../../../../../selectors/appData";
import keyboardBackspace from "../../../../../utils/icons/keyboard-backspace.svg";
import OverflowTip from "../../../../../components/OverflowTip/OverflowTip";

const AppEditHeader = () => {
    const classes = useAppEditHeaderStyles();

    const app = useSelector(getApp);

    const history = useHistory();
    const prevLocation = history.location.state?.prevLocation;

    const onBack = () => {
        if (prevLocation) {
            history.push(prevLocation);
        } else {
            history.push("/applications");
        }
    };

    return (
        <>
            <div className={classes.stylerHeader}>
                <IconButton className={classes.backButton} onClick={onBack} size="large">
                    <img alt="" src={keyboardBackspace} />
                </IconButton>
                <OverflowTip variant="h2" className={classes.headerText}>
                    {app.name}
                </OverflowTip>
            </div>
        </>
    );
};

export default AppEditHeader;

import React, { useState } from "react";

import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import DigitizeIcon from "@mui/icons-material/Timeline";
import PrintIcon from "@mui/icons-material/Print";
import Measure from "@mui/icons-material/SquareFoot";
import CheckIcon from "@mui/icons-material/Check";
import { TextField, Tooltip } from "@mui/material";
import { CustomIcons } from "../../../../utils/customIcons";
import BasemapPicker from "./BasemapPicker/BasemapPicker";
import ProjectionPicker from "./projectionPicker";
import ReadOnlyField from "../../../../components/ReadOnlyField/ReadOnlyField";
import CollapsibleCard from "../../../../components/CollapsibleCard/CollapsibleCard";
import LanguagePicker from "./languagePicker";
import { useDispatch, useSelector } from "react-redux";
import * as appDataActions from "../../../../reducers/appData/appData";
import { getMapPosition } from "../../../../selectors/map";
import { getApp } from "../../../../selectors/appData";

import fileCopyIcon from "../../../../utils/icons/copy-icon.svg";
import InfoIcon from "@mui/icons-material/Info";
import UpdateIcon from "@mui/icons-material/Save";
import UncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckedIcon from "@mui/icons-material/CheckCircleOutline";
import toastr from "../../../../components/CustomToastr/CustomToastr";

let icons = {
    search: <SearchIcon fontSize="small" />,
    print: <PrintIcon fontSize="small" />,
    measure: <Measure fontSize="small" />,
    digitize: <DigitizeIcon fontSize="small" />,
    legend: CustomIcons.legend
};

const AppSettingsView = (props) => {
    const [toggleState, setToggleState] = useState({ section: "1" });

    const mapPosition = useSelector(getMapPosition);
    const app = useSelector(getApp);
    const dispatch = useDispatch();

    const { section } = toggleState;

    function onSidebarCollapsedChanged() {
        let newConfigJson = { ...app.configJson };

        newConfigJson.sidebarCollapsed = !newConfigJson.sidebarCollapsed;

        dispatch(appDataActions.setAppConfig(newConfigJson));
    }

    function onToolToggled(tool) {
        let newConfigJson = { ...app.configJson };

        const newTool = { ...tool, enabled: !tool.enabled };

        newConfigJson.tools = newConfigJson.tools.map((x) => {
            if (x.name === newTool.name) {
                return newTool;
            }
            return x;
        });

        dispatch(appDataActions.setAppConfig(newConfigJson));
    }

    function onWidgetsToggled(widget) {
        let newConfigJson = { ...app.configJson };

        const newWidget = { ...widget, enabled: !widget.enabled };

        newConfigJson.widgets = newConfigJson.widgets.map((x) => {
            if (x.name === newWidget.name) {
                return newWidget;
            }
            return x;
        });

        dispatch(appDataActions.setAppConfig(newConfigJson));
    }

    function onSetMapPosition() {
        let newConfigJson = { ...app.configJson };

        newConfigJson.mapBounds = mapPosition.bounds;

        dispatch(appDataActions.setAppConfig(newConfigJson));
    }

    function onChangeBasemaps(basemaps) {
        let newConfigJson = { ...app.configJson };

        newConfigJson.basemaps = basemaps;

        dispatch(appDataActions.setAppConfig(newConfigJson));
    }

    function onChangeProjections(projections) {
        let newConfigJson = { ...app.configJson };

        newConfigJson.projections = projections;
        dispatch(appDataActions.setAppConfig(newConfigJson));
    }

    function onChangeLanguages(languages) {
        let newConfigJson = { ...app.configJson };

        newConfigJson.languages = languages;

        dispatch(appDataActions.setAppConfig(newConfigJson));
    }

    function boundsToString(bounds) {
        return `${bounds[0][0].toFixed(2)} ${bounds[0][1].toFixed(2)}, ${bounds[1][0].toFixed(2)} ${bounds[1][1].toFixed(2)}`;
    }

    const handleSectionToggle = (section) => {
        if (toggleState.section === section) {
            setToggleState("");
        } else {
            setToggleState(() => ({
                section
            }));
        }
    };
    const onNameChange = (e) => {
        const newName = e.target.value;
        dispatch(appDataActions.setAppName(newName));
    };

    return (
        <div className="app-settings-view">
            <div className="settings-container">
                <div className="field copyable">
                    <TextField
                        placeholder="Name of application"
                        label="Application Name"
                        variant="filled"
                        fullWidth
                        value={app.name}
                        onChange={onNameChange}
                        id="filled-required"
                    />
                    <Tooltip title="Copy to clipboard">
                        <Button
                            className="copy-button"
                            onClick={() => {
                                navigator.clipboard.writeText(app.name);
                                toastr.success("The text has been copied");
                            }}
                        >
                            <img alt="" src={fileCopyIcon} />
                        </Button>
                    </Tooltip>
                </div>
                <div className="section">
                    <CollapsibleCard onToggleClick={() => handleSectionToggle("1")} title="Map Bounds" isToggled={section === "1"} unmountOnExit>
                        <div className="info-section">
                            <InfoIcon className="info-section__icon" />
                            <div className="info-section__text">Drag the map until you're satisfied with the new bounds, then click the update button to apply them.</div>
                        </div>
                        <div className="flex">
                            <div className="flex-grow">
                                <ReadOnlyField label={"Bounds"} text={boundsToString(app.configJson.mapBounds)} />
                            </div>
                        </div>
                        <Button className="update-button" variant="text" color="primary" onClick={onSetMapPosition}>
                            <UpdateIcon className="left-icon" fontSize="small" /> Update Bounds
                        </Button>
                    </CollapsibleCard>
                </div>
                <div className="section">
                    <CollapsibleCard onToggleClick={() => handleSectionToggle("2")} title="Viewer Settings" isToggled={section === "2"} unmountOnExit>
                        <div className="section-content">
                            <div className="subtitle">Tools and Widgets</div>
                            <div className="enabled-tools">
                                {app.configJson.tools.map((tool, index) => {
                                    return (
                                        <div className={tool.enabled ? "tool enabled" : "tool"} onClick={() => onToolToggled(tool)} key={tool.name}>
                                            {tool.enabled && <CheckIcon className="left-icon" fontSize="small" />}
                                            {tool.name} <div className="icon">{icons[tool.name]}</div>
                                        </div>
                                    );
                                })}
                                {app.configJson.widgets.map((widget, index) => {
                                    return (
                                        <div className={widget.enabled ? "tool enabled" : "tool"} onClick={() => onWidgetsToggled(widget)} key={widget.name}>
                                            {widget.enabled && <CheckIcon className="left-icon" fontSize="small" />}
                                            {widget.name} <div className="icon">{icons[widget.name]}</div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="subtitle">Sidebar Layout</div>
                            <div className="enable-sidebar-collaps">
                                <div className={app.configJson.sidebarCollapsed ? "checkbox checked" : "checkbox"} onClick={onSidebarCollapsedChanged}>
                                    {app.configJson.sidebarCollapsed ? (
                                        <CheckedIcon className="left-icon checked-icon" fontSize="medium" />
                                    ) : (
                                        <UncheckedIcon className="left-icon" fontSize="medium" />
                                    )}
                                    Sidebar Collapsed
                                </div>
                                <div className={!app.configJson.sidebarCollapsed ? "checkbox checked" : "checkbox"} onClick={onSidebarCollapsedChanged}>
                                    {!app.configJson.sidebarCollapsed ? (
                                        <CheckedIcon className="left-icon checked-icon" fontSize="medium" />
                                    ) : (
                                        <UncheckedIcon className="left-icon" fontSize="medium" />
                                    )}
                                    Sidebar Expanded
                                </div>
                            </div>
                        </div>
                    </CollapsibleCard>
                </div>
                <div className="section scroll">
                    <CollapsibleCard
                        onToggleClick={() => handleSectionToggle("3")}
                        title={`Projection (${app.configJson.projections.length})`}
                        isToggled={section === "3"}
                        unmountOnExit
                        maxHeight={600}
                        paddingBottom={0}
                    >
                        <ProjectionPicker projections={app.configJson.projections} onChange={onChangeProjections} />
                    </CollapsibleCard>
                </div>
                <div className="section scroll">
                    <CollapsibleCard
                        onToggleClick={() => handleSectionToggle("4")}
                        title={`Map Languages (${app.configJson.languages.length})`}
                        isToggled={section === "4"}
                        unmountOnExit
                        maxHeight={600}
                        paddingBottom={0}
                    >
                        <LanguagePicker languages={app.configJson.languages} onChange={onChangeLanguages} />
                    </CollapsibleCard>
                </div>
                <div className="section scroll">
                    <CollapsibleCard
                        onToggleClick={() => handleSectionToggle("5")}
                        title={`Basemaps (${app.configJson.basemaps.length})`}
                        isToggled={section === "5"}
                        unmountOnExit
                        maxHeight={600}
                        paddingBottom={0}
                    >
                        <BasemapPicker basemaps={app.configJson.basemaps} onChange={onChangeBasemaps} />
                    </CollapsibleCard>
                </div>
            </div>
        </div>
    );
};

export default AppSettingsView;

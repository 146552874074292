import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddCircle";
import Typography from "../../../components/CustomTypography/CustomTypography";
import LinearProgress from "@mui/material/LinearProgress";
import ReplacerModal from "../../../components/uploader/replacer";
import DownloaderModal from "../../../components/downloader/downloader";

import * as uploaderActions from "../../../actions/uploader";
import * as datasetsActions from "../../../actions/datasets";
import * as notificationsActions from "../../../actions/notifications";
import { history } from "../../../store";
import toastr from "../../../components/CustomToastr/CustomToastr";
import { handleError } from "../../../utils/networkErrorUtils";
import datasetsSortings from "./components/datasetsSorting";

import DatasetRow from "./components/DatasetRow/DatasetRow";
import TableView from "../../../components/TableView/TableView";

const styles = (theme) => ({
    title: {
        flexGrow: 1
    },
    searchIcon: {
        color: theme.customColors.darkGrey,
        width: 20
    },
    clearTextButton: {
        visibility: "hidden"
    },
    visible: {
        visibility: "visible"
    },
    icon: {
        marginRight: 8
    }
});

class DatasetsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: "",
            sorting: "modified",
            sortingReverse: false,
            rowsPerPage: 20,
            page: 0,
            downloaderOpen: false,
            replacerOpen: false,
            targetDataset: undefined,
            targetDatasetColumns: undefined
        };

        this.sortings = datasetsSortings;
    }

    componentDidMount() {
        this.props.getDatasets();
    }

    onEditClick = (item) => {
        history.push("/datasets/edit/" + item.id);
    };

    onClickDeleteDataset(item) {
        const toastrConfirmOptions = {
            onOk: () => this.deleteDataset(item),
            onCancel: () => {}
        };
        toastr.confirm("Are you sure you want to delete dataset: \n" + item.name, toastrConfirmOptions);
    }

    onClickAddDataset = () => {
        this.props.openUploader();
    };

    onSearch = (e) => {
        this.setState({
            search: e.target.value,
            page: this.state.search === "" ? 0 : this.state.page
        });
    };

    onClearSearchText = () => {
        this.setState({ search: "", page: 0 });
    };

    deleteDataset = (dataset) => {
        this.props
            .deleteDataset(dataset.id)
            .then(() => {
                toastr.success("Dataset deleted");
            })
            .catch((err) => {
                handleError(err);
            });
    };

    onGenerateDataset = (dataset) => {
        this.props
            .generateCache(dataset.id)
            .then(() => {
                this.props.resetNotification(dataset.name, dataset.id);
                toastr.success(`Caching has been queued for ${dataset.name} - Zoom Level ${dataset.minZoom} - ${dataset.maxZoom}`);
            })
            .catch((err) => {
                handleError(err);
            });
    };

    onSetSort = (sort) => {
        this.setState({
            sorting: sort,
            sortingReverse: this.state.sorting === sort ? !this.state.sortingReverse : false
        });
    };

    onPageChange = (event, page) => {
        this.setState({
            page: page
        });
    };

    onRowsPerPageChange = (e) => {
        this.setState({
            rowsPerPage: e.target.value
        });
    };

    onDatasetUploaded = (dataset) => {
        history.push("/datasets/edit/" + dataset.id);
    };

    onCloseReplacer = () => {
        this.setState({ replacerOpen: false });
    };

    onCloseDownloader = () => {
        this.setState({ downloaderOpen: false });
    };

    onReplaceDataset = (dataset) => {
        this.props.getDatasetColumns(dataset.id).then((res) => {
            this.setState({ replacerOpen: true, targetDataset: dataset, targetDatasetColumns: res.result });
        });
    };

    onDownloadDataset = (dataset) => {
        this.setState({ downloaderOpen: true, targetDataset: dataset });
    };

    generateItem = (dataset, index) => {
        return (
            <DatasetRow
                key={dataset.id}
                dataset={dataset}
                onClick={() => this.onEditClick(dataset)}
                onDoubleClick={() => this.onEditClick(dataset)}
                onEditClick={() => this.onEditClick(dataset)}
                onGenerateClick={this.onGenerateDataset}
                onDeleteClick={() => this.onClickDeleteDataset(dataset)}
                onReplaceDataset={this.onReplaceDataset}
                onDownloadDataset={this.onDownloadDataset}
            />
        );
    };

    filterItem(item, query) {
        return item.name.toLowerCase().includes(query.toLowerCase());
    }

    render() {
        let { classes } = this.props;

        return (
            <div className="sidebar-container datasets-overview">
                <div className="header">
                    <Typography variant="h2" className={classes.title}>
                        Vector Datasets
                    </Typography>
                    <Button color="primary" variant="contained" onClick={this.onClickAddDataset}>
                        <AddIcon fontSize="small" className={classes.icon} />
                        Add Dataset
                    </Button>
                </div>
                {this.props.fetching && <LinearProgress className="no-margin-progress" />}
                <TableView
                    searchPlaceholder={"Search Vector Datasets by Name or Database Name"}
                    data={this.props.datasets}
                    fetchingData={this.props.fetching}
                    columns={this.sortings}
                    generateItem={this.generateItem}
                    filterFunction={this.filterItem}
                />
                {this.state.targetDataset ? (
                    <>
                        <ReplacerModal
                            open={this.state.replacerOpen}
                            datasetId={this.state.targetDataset.id}
                            columnDefinitions={this.state.targetDatasetColumns}
                            handleClose={this.onCloseReplacer}
                        />
                        <DownloaderModal open={this.state.downloaderOpen} dataset={this.state.targetDataset} handleClose={this.onCloseDownloader} />
                    </>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        datasets: state.datasets.datasets,
        fetching: state.datasets.fetching
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openUploader: () => dispatch(uploaderActions.open()),
        getDatasets: () => dispatch(datasetsActions.getDatasets()),
        deleteDataset: (id) => dispatch(datasetsActions.deleteDataset(id)),
        generateCache: (id) => dispatch(datasetsActions.generateCache(id)),
        resetNotification: (name, id) => dispatch(notificationsActions.resetNotification(name, id)),
        getDatasetColumns: (datasetId) => dispatch(datasetsActions.getDatasetColumns(datasetId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatasetsView));

import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export const LinkDataset = (datasetId) => {
    return (dispatch) => {
        dispatch({ type: types.LINK_DATASET_GOOGLE_DRIVE });

        return axiosClient
            .post("googledrive/" + datasetId)
            .then((res) => {
                return dispatch({ type: types.LINK_DATASET_GOOGLE_DRIVE_COMPLETE, result: datasetId });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const UnlinkDataset = (datasetId) => {
    return (dispatch) => {
        dispatch({ type: types.LINK_DATASET_GOOGLE_DRIVE });

        return axiosClient
            .delete("googledrive/" + datasetId)
            .then((res) => {
                return dispatch({ type: types.LINK_DATASET_GOOGLE_DRIVE_COMPLETE, result: datasetId });
            })
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const getDatasetLink = (datasetId) => {
    return (dispatch) => {
        return axiosClient
            .get("googledrive/" + datasetId)
            .then((res) => ({ result: res }))
            .catch((err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import toastr from "../../../../../components/CustomToastr/CustomToastr";

const LanguageCard = ({ language, defaultLanguage, onDeleteClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const onMenuClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const deleteClick = () => {
        onMenuClose();
        const toastrConfirmOptions = {
            onOk: () => onDeleteClick(language),
            onCancel: () => {}
        };
        toastr.confirm("Are you sure you want to delete language: " + language.name, toastrConfirmOptions);
    };

    return (
        <div className="projection">
            {language.name}
            {language.id === defaultLanguage.id ? <span>(Default)</span> : <MoreHoriz className="icon" fontSize="small" onClick={onMenuClick} />}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
                {/* Buttons that appear in the design but have no functionality
                <MenuItem className="menu-button">
                    <SetDefault className="icon" />
                    Set as default
                </MenuItem> */}
                <MenuItem onClick={deleteClick} className="menu-button">
                    <DeleteIcon className="icon" />
                    Remove
                </MenuItem>
            </Menu>
        </div>
    );
};

export default LanguageCard;

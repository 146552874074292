import React from "react";

import { Checkbox } from "@mui/material";
import dayjs from "dayjs";
import { Tooltip } from "@mui/material";
import OverflowTip from "../../../../components/OverflowTip/OverflowTip";

export default function RasterListItemToggle({ raster, disabled, ...props }) {
    const onToggle = () => {
        props.onToggle(raster);
    };

    return (
        <div className={"item"} style={{ cursor: "default" }}>
            <div className="container">
                <div className="name">
                    <OverflowTip title={raster.name}>
                        <div className="overflow-text">{raster.name}</div>
                    </OverflowTip>
                </div>

                <Tooltip id="tooltip-top" title={dayjs(raster.modifiedUtc).format("LLL")}>
                    <div className="date">{dayjs(raster.modifiedUtc).fromNow()}</div>
                </Tooltip>
                <div className="checkbox">
                    <Checkbox disabled={disabled} checked={raster.included} onChange={onToggle} />
                </div>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import * as userService from "../../../../actions/userService";
import toastr from "../../../../components/CustomToastr/CustomToastr";
import { getUserSelector } from "../../../../selectors/user";
import { LinearProgress, TextField, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ADMIN_ROLE, USER_ROLE, UPLOADER_ROLE } from "../../../../utils/accountUtils";

const roles = [ADMIN_ROLE, USER_ROLE, UPLOADER_ROLE];

const EditModal = ({ open, handleClose, userId }) => {
    const [username, setUsername] = useState("");
    const [selectedRole, setSelectedRole] = useState(USER_ROLE);
    const [isUpdating, setIsUpdating] = useState(false);

    const user = useSelector(getUserSelector(userId));

    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            setUsername(user.username);
            setSelectedRole(user.roles);
        }
    }, [user]);

    const updateUser = (user) => {
        return dispatch(userService.updateUser(user));
    };

    const handleUpdateUser = () => {
        let user = {
            id: userId,
            username: username,
            role: selectedRole
        };
        setIsUpdating(true);
        updateUser(user)
            .then(() => toastr.success("User updated"))
            .catch((err) => toastr.error(err.response?.data || err.message))
            .finally(() => setIsUpdating(false));
    };

    const onUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const onRoleChange = (roleName) => {
        setSelectedRole(roleName);
    };

    return (
        <CustomModal handleClose={handleClose} isOpen={open} dialogTitle={"Edit User"} onConfirm={handleUpdateUser} dialogType="update" disabled={isUpdating}>
            <div className="user-edit-view">
                {isUpdating && <LinearProgress className="no-margin-progress" />}
                <div className="container">
                    <div className="field">
                        <TextField id="filled-required" label="Name" placeholder="Name of dataset" value={username} variant="filled" onChange={onUsernameChange} fullWidth />
                    </div>
                    <div className="section">
                        <FormControl>
                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                {roles.map((role) => {
                                    return <FormControlLabel key={role} control={<Radio checked={selectedRole === role} onChange={() => onRoleChange(role)} />} label={role} />;
                                })}
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};

export default EditModal;

import React from "react";
import { Typography } from "@mui/material";
import { useStyles } from "./style.js";

const CustomTypography = (props, ref) => {
    const { variant, fontWeight, color, noWrap, className } = props;

    let weight;
    switch (fontWeight) {
        case "regular":
            weight = "400";
            break;
        case "semibold":
            weight = "600";
            break;
        case "bold":
            weight = "700";
            break;
        default:
            weight = "inherit";
    }

    const styleProps = { weight: weight, color: color };
    const classes = useStyles(styleProps);

    return (
        <Typography ref={ref} variant={variant} className={`${classes.textStyle} ${className ? className : ""}`} noWrap={noWrap} {...props}>
            {props.children}
        </Typography>
    );
};

export default React.forwardRef(CustomTypography);

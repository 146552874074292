import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export const getUsers = () => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_USERS });

        return axiosClient
            .get("admin/user")
            .then((res) => {
                return dispatch({ type: types.FETCH_USERS_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.USER_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const getUser = (userId) => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_USER });

        return axiosClient
            .get("admin/user/" + userId)
            .then((res) => {
                return dispatch({ type: types.FETCH_USER_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.USER_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const createUser = (createUserRequest) => {
    return (dispatch) => {
        dispatch({ type: types.CREATE_USER });

        return axiosClient
            .post("admin/user", createUserRequest)
            .then((res) => {
                return dispatch({
                    type: types.CREATE_USER_COMPLETE,
                    result: res.data
                });
            })
            .catch((err) => {
                dispatch({ type: types.USER_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const updateUser = (user) => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_USER });

        return axiosClient
            .put("admin/user/" + user.id, user)
            .then(() => {
                return dispatch({ type: types.UPDATE_USER_COMPLETE, result: { ...user, roles: user.role } });
            })
            .catch((err) => {
                dispatch({ type: types.USER_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const deleteUser = (userId) => {
    return (dispatch) => {
        dispatch({ type: types.DELETE_USER });

        return axiosClient
            .delete("admin/user/" + userId)
            .then(() => {
                return dispatch({ type: types.DELETE_USER_COMPLETE, result: { id: userId } });
            })
            .catch((err) => {
                dispatch({ type: types.USER_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { StyledTabs, StyledTab } from "../../../../../components/CustomTabs/CustomTabs";

import Config from "../../../../../config";

import ListView from "../../../../../components/listView";
import Raster from "../rasterListItem";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddCircle";
import RasterIcon from "@mui/icons-material/Wallpaper";
import LinkIcon from "@mui/icons-material/Link";
import { TextField, Select, InputLabel, FormControl, Divider } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { getRasters } from "../../../../../selectors/rasters";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import BasemapCard from "../BasemapCard/BasemapCard";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import useBasemapPickerStyles from "./styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DATA_TYPES } from "../../../../../utils/constants/dataType";

const PAGES = {
    raster: "raster",
    url: "url"
};

function sortByName(dataset1, dataset2) {
    return dataset1.name.localeCompare(dataset2.name);
}

function sortByNameReverse(dataset1, dataset2) {
    return dataset2.name.localeCompare(dataset1.name);
}

function sortByModified(dataset1, dataset2) {
    return new Date(dataset2.modifiedUtc) - new Date(dataset1.modifiedUtc);
}

function sortByModifiedReverse(dataset1, dataset2) {
    return new Date(dataset1.modifiedUtc) - new Date(dataset2.modifiedUtc);
}

let columns = [
    {
        type: "name",
        name: "Name",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByNameReverse : sortByName),
        start: true
    },
    {
        type: "date",
        name: "Modified",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByModifiedReverse : sortByModified)
    }
];

function BasemapPicker(props) {
    const [page, setPage] = useState(PAGES.raster);
    const [title, setTitle] = useState("");
    const [selectedRaster, setSelectedRaster] = useState("");
    const [type, setType] = useState(DATA_TYPES.vector);
    const [url, setUrl] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [, setAnchorEl] = useState(null);

    const rasters = useSelector(getRasters);
    let classes = useBasemapPickerStyles();

    function onTitleChange(e) {
        setTitle(e.target.value);
    }

    function onTypeChange(e) {
        setType(e.target.value);
    }

    function onUrlChange(e) {
        setUrl(e.target.value);
    }

    function onOpenDialog() {
        setDialogOpen(true);
    }
    function onCloseDialog() {
        setDialogOpen(false);
    }

    function onAddBasemap() {
        if (props.basemaps.some((basemap) => basemap.url === url)) {
            toastr.error("Basemap already added");
            return;
        }

        if (title === "") {
            toastr.error("Must provide basemap title");
            return;
        }

        if (page === PAGES.url && !isUrlValid()) {
            toastr.error("Please enter a valid url");
            return;
        }

        if (page === PAGES.raster && selectedRaster === "") {
            toastr.error("Please select a raster source");
            return;
        }

        let basemaps = [...props.basemaps];

        basemaps.push({
            title: title,
            type: type,
            url: url
        });

        props.onChange(basemaps);
        resetState();
    }

    const isUrlValid = () => {
        const tileFormatEnding = "/{z}/{x}/{y}";
        const parsedUrl = new URL(decodeURI(url));
        const protocol = parsedUrl.protocol;
        const pathname = decodeURI(parsedUrl.pathname);

        if (!(protocol === "https:" || protocol === "mapbox:")) return false;
        //mapbox vector urls don't necessarily have the /{z}/{x}/{y} format so nothing we can check
        if (protocol === "mapbox:" && type === DATA_TYPES.vector) return true;
        //we do not support external vector basemaps
        if (protocol === "https:" && type === DATA_TYPES.vector) {
            toastr.info("We do not currently support vector basemaps outside mapbox");
            return false;
        }

        return pathname.endsWith(tileFormatEnding);
    };

    function resetState() {
        setPage(PAGES.raster);
        setTitle("");
        setType(DATA_TYPES.vector);
        setUrl("");
        setDialogOpen(false);
    }

    const onMenuClose = (e) => {
        setAnchorEl(null);
    };

    function onDeleteBasemap(basemap) {
        const basemaps = props.basemaps.filter((x) => x !== basemap);
        props.onChange(basemaps);
    }

    function onPageChange(newPage) {
        setPage(newPage);
        setUrl("");
        setSelectedRaster("");
    }

    function onSelectRaster(raster) {
        setType(DATA_TYPES.raster);
        setSelectedRaster(raster.name);
        setUrl(`${Config.apiUrl}api/raster/${raster.id}/{z}/{x}/{y}`);
        setSelectedRaster(raster.name);
    }

    function generateBasemapCard(item, index) {
        return (
            <div className="row" key={item.url}>
                <Draggable draggableId={item.url} index={index}>
                    {(provided) => (
                        <div className="draggable-section" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                            <BasemapCard basemap={item} onDeleteClick={() => onDeleteBasemap(item)} onMenuClose={() => onMenuClose()} numberOfBasemaps={numberOfBasemaps} />
                        </div>
                    )}
                </Draggable>
            </div>
        );
    }

    function generateItem(item, index) {
        return <Raster raster={item} key={index} onClick={onSelectRaster} isChecked={selectedRaster} />;
    }

    function filterItem(item, query) {
        return item.name.toLowerCase().includes(query.toLowerCase());
    }

    function onDragEnd(result) {
        const { destination, source } = result;

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) return;
        const newBasemaps = [...props.basemaps];
        const reorderedBasemap = newBasemaps[source.index];

        newBasemaps.splice(source.index, 1);
        newBasemaps.splice(destination.index, 0, reorderedBasemap);

        props.onChange(newBasemaps);
    }

    const numberOfBasemaps = props.basemaps.length;
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="basemap-picker">
                <Droppable droppableId={"basemap-droppable"} type="basemap">
                    {(provided) => (
                        <div className="basemap-container" {...provided.droppableProps} ref={provided.innerRef}>
                            {props.basemaps.map((basemap, index) => generateBasemapCard(basemap, index))}

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                <div className="button-container">
                    <Button className="button" variant="text" color="primary" onClick={onOpenDialog}>
                        <AddIcon className="left-icon" fontSize="small" />
                        Add Basemap
                    </Button>
                </div>

                <CustomModal handleClose={onCloseDialog} isOpen={dialogOpen} onConfirm={onAddBasemap} dialogTitle={"Add Basemap"} dialogType={"add"}>
                    <StyledTabs className={classes.subHeader} value={page} TabIndicatorProps={<div />}>
                        <StyledTab
                            label={<div>RASTER</div>}
                            icon={<RasterIcon fontSize="small" />}
                            iconPosition="start"
                            onClick={() => onPageChange(PAGES.raster)}
                            value={PAGES.raster}
                        />
                        <StyledTab label={<div>URL</div>} icon={<LinkIcon fontSize="small" />} iconPosition="start" onClick={() => onPageChange(PAGES.url)} value={PAGES.url} />
                    </StyledTabs>
                    <div className={classes.modalContent}>
                        {page === PAGES.raster && (
                            <>
                                <TextField
                                    inputProps={{ autoComplete: "no" }}
                                    label="Title of basemap"
                                    value={title}
                                    onChange={onTitleChange}
                                    variant="filled"
                                    className="url"
                                    fullWidth
                                />
                                <ListView
                                    searchPlaceholder={"Search rasters"}
                                    searchWidth="12"
                                    data={rasters}
                                    columns={columns}
                                    generateItem={generateItem}
                                    filterFunction={filterItem}
                                    hideBottomBar
                                ></ListView>
                            </>
                        )}
                        {page === PAGES.url && (
                            <div className="url-page">
                                <div className="title">
                                    <TextField label="Title of basemap" value={title} onChange={onTitleChange} className="url" variant="filled" fullWidth></TextField>
                                </div>
                                <div className={`flex ${classes.fieldsContainer}`}>
                                    <div className={classes.selectField}>
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel>Data Type</InputLabel>
                                            <Select value={type} onChange={onTypeChange} className="type">
                                                <MenuItem value={DATA_TYPES.raster}>{DATA_TYPES.raster}</MenuItem>
                                                <MenuItem value={DATA_TYPES.vector}>{DATA_TYPES.vector}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="flex-grow">
                                        <TextField
                                            label="URL"
                                            placeholder="e.g https://, mapbox://"
                                            value={url}
                                            onChange={onUrlChange}
                                            className="url"
                                            variant="filled"
                                            fullWidth
                                        ></TextField>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </CustomModal>
            </div>
        </DragDropContext>
    );
}

export default BasemapPicker;

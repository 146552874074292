import makeStyles from "@mui/styles/makeStyles";

export const useCollapsibleCardStyles = makeStyles((theme) => ({
    collapsibleCard: {
        borderRadius: 3,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        "& .MuiCollapse-entered": {
            overflow: "auto"
        },
        "& ::-webkit-scrollbar-track:vertical": {
            marginBottom: 64
        },
        "& ::-webkit-scrollbar": {
            width: 20,
            height: 33
        }
    },
    collapsibleCardHeader: {
        minHeight: 54,
        fontSize: 16,
        fontWeight: "bold",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 16,
        cursor: "pointer"
    },
    border: {
        position: "sticky",
        top: 0
    },
    expandedLeft: {
        transform: "rotate(180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    collapsed: {
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    bottomContainer: {
        position: "sticky",
        bottom: 0,
        backgroundColor: theme.customColors.secondaryBackgroundColor,
        padding: 16,
        borderTop: "1px solid #e3e4e5"
    }
}));

export default useCollapsibleCardStyles;

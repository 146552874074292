import makeStyles from "@mui/styles/makeStyles";
import { lighten } from "@mui/material/styles";

export const useDatasetEditViewStyles = makeStyles((theme) => ({
    whiteText: { color: "white" },
    greenText: { color: "#149B62", fontSize: 14, fontWeight: 600 },
    redText: { color: "#EC3C3E", fontSize: 14, fontWeight: 600 },
    orangeText: { color: "#F67500", fontSize: 14, fontWeight: 600 },
    actionButton: {
        width: 113,
        height: 40,
        fontSize: 16,
        fontWeight: "bold",
        color: theme.palette.primary.main,
        backgroundColor: theme.customColors.secondaryColor,
        transition: "all 0.2s",
        padding: "7px 16px",
        display: "flex",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: lighten(theme.customColors.secondaryColor, 0.2)
        }
    },
    buttonRipple: {
        color: "white"
    },
    saveButton: {
        width: 165,
        height: 40,
        marginRight: 20,
        color: theme.customColors.whiteText,
        justifyContent: "space-between",
        fontSize: 16,
        backgroundColor: theme.customColors.primaryColor,
        "&:hover": {
            backgroundColor: theme.customColors.primaryColor
        },
        "&.Mui-disabled": {
            opacity: 0.5,
            color: theme.customColors.whiteText
        }
    },
    backButton: {
        padding: 10,
        margin: "0 0 0 -10px"
    },
    cacheIcon: {
        fontSize: 21.5,
        marginRight: 5,
        marginLeft: -5
    },
    title: {
        flexGrow: 1,
        color: "white"
    },
    backIcon: {
        color: "black",
        transform: "rotate(180deg)"
    },
    headerText: {
        lineHeight: "30px",
        fontWeight: "400",
        color: theme.customColors.appTextColor
    },
    stylerHeader: {
        padding: "10px 0",
        maxWidth: 320,
        height: 52,
        alignItems: "center",
        display: "flex",
        boxSizing: "border-box",
        flexGrow: 1
    }
}));

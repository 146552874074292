import React from "react";
import withStyles from "@mui/styles/withStyles";
import { TextField, LinearProgress, Tooltip, IconButton } from "@mui/material";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import CancelIcon from "@mui/icons-material/Close";

import upload_icon from "../../../utils/icons/upload_icon.svg";
import upload_disabled_icon from "../../../utils/icons/upload_icon_disabled.svg";

const styles = (theme) => ({
    warningProgress: {
        backgroundColor: theme.palette.warning.main
    },
    errorProgress: {
        backgroundColor: theme.palette.error.main
    }
});

function RasterUpload({ onChange, onCancel, onCancelUpload, onUpload, uploadStatus, classes }) {
    function onNameChanged(e) {
        let value = e.target.value;
        let upload = uploadStatus;

        upload.name = value;
        upload.nameError = value === "";
        onChange(upload);
    }

    function onCancelClicked(e) {
        onCancel();
    }

    function onUploadClicked(e) {
        if (validateInfo()) {
            onUpload(uploadStatus);
        }
    }

    function validateInfo() {
        let upload = uploadStatus;

        upload.nameError = upload.name === "";

        onChange(upload);

        return !upload.nameError;
    }

    function renderProgressBar(upload, classes) {
        switch (upload.status) {
            case "failed":
                return (
                    <div className={"progress error " + (uploadStatus.message ? "pointer-hover" : "")}>
                        <LinearProgress className="progress-bar" variant="determinate" value={upload.progress} />
                        <div className="status">Failed</div>
                    </div>
                );
            case "cancelled":
                return (
                    <div className={"progress warning " + (uploadStatus.message ? "pointer-hover" : "")}>
                        <LinearProgress className="progress-bar" variant="determinate" value={upload.progress} />
                        <div className="status">Cancelled</div>
                    </div>
                );
            default:
                return (
                    <div className={"progress " + (uploadStatus.message ? "pointer-hover" : "")}>
                        <LinearProgress className="progress-bar" variant="determinate" value={upload.progress} />
                        {upload.progress < 100 && upload.progress > 0 && <div className="status">{upload.progress}%</div>}
                        {(upload.progress === 100 || upload.progress === 0) && <div className="status">{upload.status}</div>}
                    </div>
                );
        }
    }

    const anyError = uploadStatus.nameError;
    return (
        <div className="upload">
            {!uploadStatus.uploading && (
                <div className="upload-container">
                    <Chip className="type-chip raster" label="Raster" />
                    <div className="section grow">
                        <TextField
                            id="full-width"
                            inputProps={{ autoComplete: "no" }}
                            label="Raster Name"
                            error={uploadStatus.nameError}
                            placeholder="Name of the raster"
                            value={uploadStatus.name}
                            onChange={onNameChanged}
                            fullWidth
                            margin="none"
                            variant="filled"
                        />
                    </div>
                    <Tooltip title="Upload">
                        <IconButton disabled={anyError} className={"upload-button"} variant="contained" color="primary" onClick={onUploadClicked} size="large">
                            {anyError ? <img alt="" src={upload_disabled_icon} /> : <img alt="" src={upload_icon} />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <IconButton className={"delete-button"} variant="contained" color="primary" onClick={onCancelClicked} size="large">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
            {uploadStatus.uploading && (
                <div className="uploading-container">
                    <div className="uploading-info">
                        <Tooltip title="Raster">
                            <Chip className={"type-chip raster " + uploadStatus.status} label="Raster" />
                        </Tooltip>
                        <div className={"section grow " + uploadStatus.status}>
                            <div className="readonly-field">
                                <div className="readonly-field__label">Dataset Name</div>
                                <div className="readonly-field__text">{uploadStatus.name}</div>
                            </div>
                        </div>

                        {uploadStatus.message ? (
                            <Tooltip title={uploadStatus.message}>{renderProgressBar(uploadStatus, classes)}</Tooltip>
                        ) : (
                            renderProgressBar(uploadStatus, classes)
                        )}

                        <Tooltip title={uploadStatus.status !== "uploading" ? "Remove" : "Cancel"}>
                            <div>
                                <IconButton className={"delete-button " + uploadStatus.status} variant="contained" onClick={() => onCancelUpload(uploadStatus)} size="large">
                                    {uploadStatus.status !== "uploading" ? <DeleteIcon /> : <CancelIcon />}
                                </IconButton>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            )}
        </div>
    );
}

export default withStyles(styles)(RasterUpload);

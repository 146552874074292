import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { store, history } from "./store.js";
import { Auth0Provider } from "@auth0/auth0-react";

import Config from "./config";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import theme from "./muiTheme";

import dayjs from "dayjs";
import "./utils/dayjs/customLocale";
import Auth0ErrorWrapper from "./Auth0ErrorWrapper";

const utc = require("dayjs/plugin/utc");
const relativeTime = require("dayjs/plugin/relativeTime");
const localizedFormat = require("dayjs/plugin/localizedFormat");

dayjs.extend(relativeTime).extend(utc).extend(localizedFormat);

const onRedirectCallback = (appState) => {
    if (appState?.returnTo === "/") {
        appState.returnTo = "/datasets";
    }

    history.replace(appState?.returnTo || window.location.pathname + "/datasets");
};

ReactDOM.render(
    <Auth0Provider
        domain={Config.auth0Domain}
        clientId={Config.auth0ClientId}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        audience={Config.auth0Audience}
        useRefreshTokens={true}
        scope="read:current_user update:current_user_metadata"
    >
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <Auth0ErrorWrapper>
                        <App />
                    </Auth0ErrorWrapper>
                </Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    </Auth0Provider>,

    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export const getSchema = () => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_METADATA_SCHEMA });

        return axiosClient.get(`metadata/`).then((res) => {
            return dispatch({ type: types.FETCH_METADATA_SCHEMA_COMPLETE, result: res.data });
        });
    };
};

export const updateSchema = (schemaChanges, metadataSchemaId) => {
    return () => {
        return axiosClient.put(`metadata/`, { schemaChanges: schemaChanges, metadataSchemaId: metadataSchemaId }).then(() => ({ result: schemaChanges }));
    };
};

import React, { useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { TextField, Select, MenuItem, FormControl, InputLabel, LinearProgress, Tooltip, IconButton } from "@mui/material";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import CancelIcon from "@mui/icons-material/Close";

import upload_icon from "../../../utils/icons/upload_icon.svg";
import upload_disabled_icon from "../../../utils/icons/upload_icon_disabled.svg";

import * as ValidationUtils from "../../../utils/validationUtils";

const styles = (theme) => ({
    warningProgress: {
        backgroundColor: theme.palette.warning.main
    },
    errorProgress: {
        backgroundColor: theme.palette.error.main
    }
});

const Upload = ({ schemaNames, onChange, onCancel, onCancelUpload, onUpload, uploadStatus, classes, dragging }) => {
    useEffect(() => {
        if (uploadStatus) validateInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNameChanged = (e) => {
        let value = e.target.value;
        let upload = uploadStatus;
        upload.name = value;
        upload.nameError = ValidationUtils.validateDatasetName(value);
        onChange(upload);
        //setNameError(value !== "");
    };

    const onSchemaNameChanged = (e) => {
        const value = e.target.value;
        onChange({ ...uploadStatus, schemaName: value });
    };

    const onTableNameChanged = (e) => {
        //table name should be lower case
        const value = e.target.value.toLowerCase();
        const upload = { ...uploadStatus };

        upload.tableName = value;
        upload.tableNameError = ValidationUtils.validatePostgresIdentifier(value);
        onChange(upload);
    };

    const onCancelClicked = (e) => {
        onCancel();
    };

    const onUploadClicked = (e) => {
        if (validateInfo()) {
            onUpload(uploadStatus);
        }
    };

    const validateInfo = () => {
        const upload = { ...uploadStatus };

        upload.nameError = ValidationUtils.validateDatasetName(upload.name);
        upload.schemaNameError = upload.schemaName === "";
        upload.tableNameError = ValidationUtils.validatePostgresIdentifier(upload.tableName);
        onChange(upload);

        return !upload.nameError && !upload.tableNameError && !upload.schemaNameError;
    };

    const renderSchemaNames = (schemaNames) => {
        return schemaNames.map((schema, index) => {
            return (
                <MenuItem key={index} value={schema}>
                    {schema}
                </MenuItem>
            );
        });
    };

    const renderProgressBar = (upload, classes) => {
        switch (upload.status) {
            case "failed":
                return (
                    <div className={"progress error " + (uploadStatus.message ? "pointer-hover" : "")}>
                        <LinearProgress className="progress-bar" classes={{ bar: classes.errorProgress }} variant="determinate" value={upload.progress} />
                        <div className="status">Failed</div>
                    </div>
                );
            case "cancelled":
                return (
                    <div className={"progress warning " + (uploadStatus.message ? "pointer-hover" : "")}>
                        <LinearProgress className="progress-bar" classes={{ bar: classes.warningProgress }} variant="determinate" value={upload.progress} />
                        <div className="status">Cancelled</div>
                    </div>
                );
            default:
                return (
                    <div className={"progress " + (uploadStatus.message ? "pointer-hover" : "")}>
                        <LinearProgress className="progress-bar" variant="determinate" value={upload.progress} />
                        {upload.progress < 100 && upload.progress > 0 && <div className="status">{upload.progress}%</div>}
                        {(upload.progress === 100 || upload.progress === 0) && <div className="status">{upload.status}</div>}
                    </div>
                );
        }
    };

    const isString = (str) => typeof str === "string" || str instanceof String;

    const anyError = uploadStatus.nameError || uploadStatus.tableNameError;

    return (
        <div className={`upload ${dragging ? `dragging` : ``}`}>
            {!uploadStatus.uploading && (
                <div className="upload-container">
                    <Chip className="type-chip vector" label="Vector" />
                    <div className="section grow">
                        <TextField
                            id="full-width"
                            inputProps={{ autoComplete: "no" }}
                            error={!!uploadStatus.nameError}
                            helperText={uploadStatus.nameError}
                            placeholder="Name of the map"
                            value={uploadStatus.name}
                            onChange={onNameChanged}
                            fullWidth
                            margin="none"
                            variant="filled"
                            label="Dataset Name"
                        />
                    </div>
                    <FormControl variant="filled" className="section">
                        <InputLabel id="schema-label">Schema</InputLabel>
                        <Select labelId="schema-label" label="Schema" value={uploadStatus.schemaName} error={uploadStatus.schemaNameError} onChange={onSchemaNameChanged}>
                            {renderSchemaNames(schemaNames)}
                        </Select>
                    </FormControl>

                    <div className="section grow">
                        <TextField
                            id="full-width"
                            inputProps={{ autoComplete: "no" }}
                            label="Table Name"
                            error={!!uploadStatus.tableNameError}
                            helperText={isString(uploadStatus.tableNameError) ? uploadStatus.tableNameError : ""}
                            placeholder="Name of the map"
                            value={uploadStatus.tableName}
                            onChange={onTableNameChanged}
                            fullWidth
                            margin="none"
                            variant="filled"
                        />
                    </div>

                    <Tooltip title={!anyError ? "Upload" : ""}>
                        <div className={"upload-button"}>
                            <IconButton disabled={!!anyError} variant="contained" color="primary" onClick={onUploadClicked} size="large">
                                {!!anyError ? <img alt="" src={upload_disabled_icon} /> : <img alt="" src={upload_icon} />}
                            </IconButton>
                        </div>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <IconButton className={"delete-button"} variant="contained" color="primary" onClick={onCancelClicked} size="large">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
            {uploadStatus.uploading && (
                <div className="uploading-container">
                    <div className="uploading-info">
                        <Tooltip title="Vector">
                            <Chip className={"type-chip vector " + uploadStatus.status} label="Vector" />
                        </Tooltip>
                        <div className={"section grow " + uploadStatus.status}>
                            <div className="readonly-field">
                                <div className="readonly-field__label">Dataset Name</div>
                                <div className="readonly-field__text">{uploadStatus.name}</div>
                            </div>
                        </div>

                        {uploadStatus.message ? (
                            <Tooltip title={uploadStatus.message}>{renderProgressBar(uploadStatus, classes)}</Tooltip>
                        ) : (
                            renderProgressBar(uploadStatus, classes)
                        )}

                        <Tooltip title={uploadStatus.status !== "uploading" ? "Remove" : "Cancel"}>
                            <div>
                                <IconButton className={"delete-button " + uploadStatus.status} variant="contained" onClick={() => onCancelUpload(uploadStatus)} size="large">
                                    {uploadStatus.status !== "uploading" ? <DeleteIcon /> : <CancelIcon />}
                                </IconButton>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withStyles(styles)(Upload);

import * as types from "../actions/actionTypes";

const navigationInit = {
    isNavigationOpen: false
};

export function navigationReducer(state = navigationInit, action) {
    switch (action.type) {
        case types.NAVIGATION_TOGGLE:
            return {
                ...state,
                isNavigationOpen: !state.isNavigationOpen
            };
        default:
            return state;
    }
}

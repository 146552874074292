import React, { useState } from "react";

import SingleTagEditor from "./singleTagEditor";
import TagCreator from "./tagCreator";

const TagEditor = ({ tags, onTagsChanged, setInvalidTag }) => {
    const [errorHelperText, setErrorHelperText] = useState("");
    const [tagWithErrorName, setTagWithErrorName] = useState("");

    const onAddTag = (tag) => {
        onTagsChanged([...tags, tag]);
    };

    const onDeleteTag = (tag) => {
        let newTags = tags.filter((t) => t.name !== tag.name);
        onTagsChanged(newTags);
    };

    const onEditTag = (tag, oldName) => {
        if (oldName !== tag.name) {
            let alreadyExisting = tags.some((t) => t.name === tag.name);
            let isEmpty = tag.name === "";
            if (isEmpty || alreadyExisting) {
                setErrorHelperText(alreadyExisting ? "Duplicate tag name" : "");
                setTagWithErrorName(oldName);
                setInvalidTag(true);
                return;
            }
        }

        let newTags = tags.map((t) => (t.name === oldName ? tag : t));
        setTagWithErrorName("");
        setErrorHelperText("");
        setInvalidTag(false);
        onTagsChanged(newTags);
    };

    return (
        <div>
            <TagCreator hideTags={true} tags={tags} onAddTag={onAddTag} />
            <div className="tag-list">
                {tags.map((tag, index) => {
                    return <SingleTagEditor key={index} tag={tag} onEdit={onEditTag} onDelete={onDeleteTag} error={tag.name === tagWithErrorName} errorHelper={errorHelperText} />;
                })}
            </div>
        </div>
    );
};

export default TagEditor;

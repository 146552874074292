import GeometryTypeIcon from "@mui/icons-material/Category";

const unhumanize = (text) => {
    var powers = { k: 1, m: 2, g: 3, t: 4 };
    var regex = /(\d+(?:\.\d+)?)\s?(k|m|g|t)?b?/i;

    var res = regex.exec(text);

    return res[1] * Math.pow(1024, powers[res[2].toLowerCase()]);
};

const sortByName = (dataset1, dataset2) => {
    return dataset1.name.localeCompare(dataset2.name);
};

const sortByNameReverse = (dataset1, dataset2) => {
    return dataset2.name.localeCompare(dataset1.name);
};

const sortBySize = (dataset1, dataset2) => {
    return unhumanize(dataset1.databaseSize) - unhumanize(dataset2.databaseSize);
};

const sortBySizeReverse = (dataset1, dataset2) => {
    return unhumanize(dataset2.databaseSize) - unhumanize(dataset1.databaseSize);
};

const sortBySchema = (dataset1, dataset2) => {
    return dataset1.schemaName.localeCompare(dataset2.schemaName);
};

const sortBySchemaReverse = (dataset1, dataset2) => {
    return dataset2.schemaName.localeCompare(dataset1.schemaName);
};

const sortByGeometryType = (dataset1, dataset2) => {
    return dataset1.geometryType.localeCompare(dataset2.geometryType);
};

const sortByGeometryTypeReverse = (dataset1, dataset2) => {
    return dataset2.geometryType.localeCompare(dataset1.geometryType);
};

const sortByCRS = (dataset1, dataset2) => {
    return dataset1.projection - dataset2.projection;
};

const sortByCRSReverse = (dataset1, dataset2) => {
    return dataset2.projection - dataset1.projection;
};

const sortByModified = (dataset1, dataset2) => {
    return new Date(dataset2.modifiedUtc) - new Date(dataset1.modifiedUtc);
};

const sortByModifiedReverse = (dataset1, dataset2) => {
    return new Date(dataset1.modifiedUtc) - new Date(dataset2.modifiedUtc);
};

const sortByCreated = (dataset1, dataset2) => {
    return new Date(dataset2.createdUtc) - new Date(dataset1.createdUtc);
};

const sortByCreatedReverse = (dataset1, dataset2) => {
    return new Date(dataset1.createdUtc) - new Date(dataset2.createdUtc);
};

const sortByZoom = (dataset1, dataset2) => {
    return dataset2.maxZoom - dataset1.maxZoom;
};

const sortByZoomReverse = (dataset1, dataset2) => {
    return dataset1.maxZoom - dataset2.maxZoom;
};

const sortByRowCount = (dataset1, dataset2) => {
    return dataset2.rowCount - dataset1.rowCount;
};

const sortByRowCountReverse = (dataset1, dataset2) => {
    return dataset1.rowCount - dataset2.rowCount;
};

const sortByTableName = (dataset1, dataset2) => {
    return dataset1.tableName.localeCompare(dataset2.tableName);
};

const sortByTableNameReverse = (dataset1, dataset2) => {
    return dataset2.tableName.localeCompare(dataset1.tableName);
};

const datasetsSortings = [
    {
        type: "geometry",
        icon: <GeometryTypeIcon />,
        name: "geometry type",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByGeometryTypeReverse : sortByGeometryType)
    },
    {
        type: "schema",
        name: "Schema",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortBySchemaReverse : sortBySchema)
    },
    {
        type: "name",
        name: "Name",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByNameReverse : sortByName)
    },
    {
        type: "table-name",
        name: "Database Name",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByTableNameReverse : sortByTableName)
    },
    {
        type: "text",
        name: "CRS",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByCRSReverse : sortByCRS)
    },
    {
        type: "text",
        name: "Zoom",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByZoomReverse : sortByZoom)
    },
    {
        type: "text",
        name: "Size",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortBySizeReverse : sortBySize)
    },
    {
        type: "text",
        name: "Row Count",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByRowCountReverse : sortByRowCount)
    },
    {
        type: "date",
        name: "Modified",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByModifiedReverse : sortByModified),
        start: true
    },
    {
        type: "date",
        name: "Created",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByCreatedReverse : sortByCreated)
    }
];

export default datasetsSortings;

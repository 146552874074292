import { Button, Tooltip } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/AddCircle";
import { getAppCacheStatusSelector } from "../../../../../selectors/map";
import SearchBar from "../../../../../components/SearchBar/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { addGroup, setSelectedTool } from "../../../../../reducers/appData/appData";
import { APP_EDIT_TOOLS } from "../../../../../utils/constants/appEditTools";
import { v4 as uuidv4 } from "uuid";

const ActionsSection = ({ searchText, onSearchChange }) => {
    const cacheStatus = useSelector(getAppCacheStatusSelector);
    const dispatch = useDispatch();

    const addLayerGroup = () => {
        dispatch(
            addGroup({
                resourceId: uuidv4(),
                name: "New group",
                layers: [],
                options: {
                    collapsed: true
                },
                totalLayersCount: 0,
                visibleLayersCount: 0
            })
        );
    };

    const onOpenTool = (toolName) => {
        dispatch(setSelectedTool(toolName));
    };

    return (
        <>
            <div className="adding-buttons">
                <Button onClick={addLayerGroup} variant="text" color="primary" className="add-group-button">
                    <AddIcon className="left-icon" fontSize="small" />
                    <div>Add Group</div>
                </Button>
                <Tooltip title={cacheStatus === 1 ? "Wait for the app to finish the caching process" : ""}>
                    <div>
                        <Button disabled={cacheStatus === 1} color="primary" variant="text" onClick={() => onOpenTool(APP_EDIT_TOOLS.addData)}>
                            <AddIcon className="left-icon" fontSize="small" />
                            Add Data
                        </Button>
                    </div>
                </Tooltip>
            </div>
            <SearchBar searchPlaceholder="Search Layer" value={searchText} onChange={onSearchChange} noBg />
        </>
    );
};

export default ActionsSection;

import * as types from "../actions/actionTypes";

const appsInit = {
    fetching: false,
    isPanelClosed: false,
    apps: []
};

export function appsReducer(state = appsInit, action) {
    switch (action.type) {
        case types.FETCH_APPS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_APPS_COMPLETE:
            return {
                ...state,
                fetching: false,
                apps: action.result
            };
        case types.CREATE_APP:
            return {
                ...state,
                fetching: true
            };
        case types.CREATE_APP_COMPLETE:
            return {
                ...state,
                fetching: false,
                apps: [...state.apps, action.result]
            };
        case types.UPDATE_APP:
            return {
                ...state,
                fetching: true
            };
        case types.UPDATE_APP_COMPLETE:
            return {
                ...state,
                fetching: false,
                apps: updateArray(state.apps, action.result)
            };
        case types.DELETE_APP:
            return {
                ...state,
                fetching: true
            };
        case types.DELETE_APP_COMPLETE:
            return {
                ...state,
                fetching: false,
                apps: removeFromArray(state.apps, action.result)
            };
        case types.APP_ACTION_FAILED:
            return {
                ...state,
                fetching: false
            };
        case types.PANEL_TOGGLE:
            return {
                ...state,
                isPanelClosed: !state.isPanelClosed
            };
        default:
            return state;
    }
}

function removeFromArray(array, item) {
    return array.filter((x) => item.id !== x.id);
}

function updateArray(array, item) {
    return array.map((x) => {
        if (item.id === x.id) {
            return item;
        }
        return x;
    });
}

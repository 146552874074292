import { Button, TextField, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import ReadOnlyField from "../../../../../components/ReadOnlyField/ReadOnlyField";
import { fromNowWithCheck } from "../../../../../utils/timeUtils";
import { useInfoTabStyles } from "./styles";
import fileCopyIcon from "../../../../../utils/icons/copy-icon.svg";
import { useFormikContext } from "formik";

const InfoTab = ({ dataset, setBasicDataChanged }) => {
    const classes = useInfoTabStyles();
    const { values, errors, handleChange } = useFormikContext();
    return (
        <>
            <div className="field copyable">
                <TextField
                    id="filled-required"
                    label="Dataset Name"
                    placeholder="Name of dataset"
                    variant="filled"
                    fullWidth
                    name="datasetName"
                    value={values.datasetName}
                    onChange={(e) => {
                        handleChange(e);
                        setBasicDataChanged(true);
                    }}
                    helperText={errors.datasetName}
                    error={!!errors.datasetName}
                />
                <Tooltip title="Copy to clipboard">
                    <Button
                        TouchRippleProps={{ classes: { root: classes.buttonRipple } }}
                        className="copy-button"
                        onClick={() => {
                            navigator.clipboard.writeText(values.datasetName);
                            toastr.success("The text has been copied");
                        }}
                    >
                        <img alt="" src={fileCopyIcon} />
                    </Button>
                </Tooltip>
            </div>
            <div className="field flex light-grey-border">
                <ReadOnlyField label={"Creation Date"} text={fromNowWithCheck(dayjs(dataset.createdUtc))} tooltipTitle={dayjs(dataset.createdUtc).format("LLL")} tooltip={true} />
                <ReadOnlyField label={"Modified"} text={fromNowWithCheck(dayjs(dataset.modifiedUtc))} tooltipTitle={dayjs(dataset.modifiedUtc).format("LLL")} tooltip={true} />
            </div>
            <div className="field flex light-grey-border">
                <ReadOnlyField label="Schema Name" text={dataset.schemaName} />
                <ReadOnlyField label="Table Name" text={dataset.tableName} />
            </div>
            <div className="field light-grey-border copyable">
                <ReadOnlyField label="ID" text={dataset.id} />
                <Tooltip title="Copy to clipboard">
                    <Button
                        TouchRippleProps={{ classes: { root: classes.buttonRipple } }}
                        className="copy-button"
                        onClick={() => {
                            navigator.clipboard.writeText(dataset.id);
                            toastr.success("The text has been copied");
                        }}
                    >
                        <img alt="" src={fileCopyIcon} />
                    </Button>
                </Tooltip>
            </div>
            <div className="field flex light-grey-border">
                <ReadOnlyField label="Rows" text={dataset.rowCount} />
                <ReadOnlyField label="Size" text={dataset.databaseSize} />
            </div>
            <div className="field">
                <ReadOnlyField label="CRS" text={dataset.projection} />
            </div>
        </>
    );
};

export default InfoTab;

import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";

import Typography from "@mui/material/Typography";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

import CsvHandler from "./csvHandler";

import CsvUploadInfo from "./csvUploadInfo";
import toastr from "../CustomToastr/CustomToastr";

const styles = () => ({
    root: {}
});

class FileLoader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dragging: false,
            file: undefined,
            title: props.title,
            accept: props.accept,
            requiresAdditionalInfo: false,
            additionalInfoError: false
        };
    }

    onFileDragOver = (e) => {
        e.preventDefault();

        this.setState({
            dragging: true
        });
    };

    onFileDragLeave = (e) => {
        e.preventDefault();
        this.setState({
            dragging: false
        });
    };

    onFileDrop = (e) => {
        e.preventDefault();

        this.setState({
            dragging: false
        });

        this.handleFile(e.dataTransfer.files[0]);
    };

    onFileChanged = (e) => {
        if (e.target.files.length === 0) return;

        this.handleFile(e.target.files[0]);
    };

    handleFile(file) {
        let fileExtension = file.name.split(".").pop().toLowerCase();

        switch (fileExtension) {
            case "kml":
            case "zip":
            case "geojson":
                this.loadFile(file);
                break;
            case "csv":
                this.setState({
                    requiresAdditionalInfo: true,
                    file: file
                });
                break;
            default:
                toastr.error("Invalid file type");
        }
    }

    onInfoNextClick = (options) => {
        new CsvHandler().convertToGeoJson(this.state.file, options).then((data) => {
            if (data.features.length === 0) {
                this.setState({
                    additionalInfoError: true
                });
            } else {
                this.setState({
                    requiresAdditionalInfo: false
                });
                this.props.onFileChanged(this.state.file, options);
            }
        });
    };

    onCancel = () => {
        this.setState({
            requiresAdditionalInfo: false,
            file: undefined
        });
    };

    loadFile(file) {
        this.setState({
            file: file
        });
        this.props.onFileChanged(file, {});
    }

    render() {
        let { classes } = this.props;

        return (
            <div className="file-upload">
                {!this.state.requiresAdditionalInfo && (
                    <div
                        className={this.state.dragging ? "file-upload-box dragging" : "file-upload-box"}
                        onDrop={this.onFileDrop}
                        onDragOver={this.onFileDragOver}
                        onDragLeave={this.onFileDragLeave}
                        onClick={() => {
                            document.getElementById("file-button").click();
                        }}
                    >
                        <div className="pointer-events-none">
                            <Typography variant="h6" className="text">
                                Choose or Drop
                            </Typography>

                            <Typography variant="body1" className="text">
                                ({this.state.accept})
                            </Typography>
                        </div>
                        <FileCopyOutlinedIcon className="pointer-events-none icon" />
                        <Typography variant="subtitle1" className="file-name">
                            {this.state.file ? this.state.file.name : ""}
                        </Typography>
                        <div className={classes.buttonContainer}>
                            <input
                                accept={this.state.accept}
                                className={classes.input}
                                style={{ display: "none" }}
                                multiple={false}
                                id="file-button"
                                type="file"
                                onChange={this.onFileChanged}
                            />
                        </div>
                    </div>
                )}
                {this.state.requiresAdditionalInfo && <CsvUploadInfo error={this.state.additionalInfoError} onCancel={this.onCancel} onNext={this.onInfoNextClick}></CsvUploadInfo>}
            </div>
        );
    }
}

export default withStyles(styles)(FileLoader);

const sortByName = (group1, group2) => {
    return group1.name.localeCompare(group2.name);
};

const sortByNameReverse = (group1, group2) => {
    return group2.name.localeCompare(group1.name);
};

const groupsSorting = [
    {
        type: "name",
        name: "name",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByNameReverse : sortByName),
        start: true
    }
];

export default groupsSorting;

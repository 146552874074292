import React from "react";
import ListView from "../../../../../components/listView";
import UserMembership from "../UserMembership/UserMembership";

const sortByUsername = (user1, user2) => {
    return user1.username.toLowerCase().localeCompare(user2.username.toLowerCase());
};

const sortByUsernameReverse = (user1, user2) => {
    return user2.username.toLowerCase().localeCompare(user1.username.toLowerCase());
};

const userPermissionsColumns = [
    {
        name: "username",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByUsernameReverse : sortByUsername),
        start: true
    }
];

const UserPermissionsList = ({ users, userMembershipChangePromise }) => {
    const userFilterFunction = (user, searchedUsername) => {
        return user.username.includes(searchedUsername);
    };

    const generateUser = (user) => {
        return <UserMembership key={user.id} user={user} userMembershipChangePromise={userMembershipChangePromise} />;
    };

    return <ListView searchPlaceholder="Search user" data={users} columns={userPermissionsColumns} filterFunction={userFilterFunction} generateItem={generateUser} hideBottomBar />;
};

export default UserPermissionsList;

import * as types from "../actions/actionTypes";
import { refreshToken } from "../utils/refreshToken";

export default function authMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        if (typeof action === "function") {
            const authState = getState().auth;

            if (isExpired(authState.expiresOn)) {
                // make sure we are not already refreshing the token
                if (!authState.authPromise) {
                    return authenticate(dispatch).then(() => next(action));
                } else {
                    const newAuthPromise = authState.authPromise.then(() => next(action));
                    dispatch({
                        type: types.AUTHENTICATING,
                        // we want to keep track of token promise in the state so that we don't try to refresh the token again while refreshing is in process
                        authPromise: newAuthPromise
                    });
                    return newAuthPromise;
                }
            }
        }
        return next(action);
    };
}

function isExpired(expiresOn) {
    const currentTime = new Date();
    const expires_date = new Date(expiresOn);
    const msDifference = 30000;
    // Get a new token if within 30 seconds of expiring
    // Mapbox limit is 60, so no reason to do it sooner https://github.com/auth0/auth0-spa-js/blob/9c834f11093487828251fe61e0f5e4173b1662af/src/Auth0Client.ts#L1243
    return currentTime.getTime() > expires_date.getTime() - msDifference;
}

function authenticate(dispatch) {
    const accessTokenPromise = refreshToken()
        .then((token) => {
            authSuccess(token, dispatch);
        })
        .catch((ex) => authFail(ex, dispatch));

    dispatch({
        type: types.AUTHENTICATING,
        // we want to keep track of token promise in the state so that we don't try to refresh the token again while refreshing is in process
        authPromise: accessTokenPromise
    });

    return accessTokenPromise;
}

function authSuccess(token, dispatch) {
    dispatch({
        type: types.AUTHENTICATED,
        result: token
    });
}

function authFail(ex, dispatch) {
    console.error("Exception getAccessTokenSilently:", ex);
    dispatch({
        type: types.AUTHENTICATING_FAILED,
        exception: ex
    });
    window.location.reload();
}

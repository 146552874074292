import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { toggleGroupLayers } from "../../actions/globalActions";
import { CONFIG } from "../../utils/constants/appDefaults";
import moveResourceActionFunction from "./moveResource";
import * as actions from "../../actions/actionTypes";
import { addDatasetToAppThunk, addRasterToAppThunk, removeDatasetFromAppThunk, removeRasterFromAppThunk } from "../../actions/apps";

const initialState = {
    app: {
        configJson: { ...CONFIG },
        maps: [{ minZoom: 0 }]
    },
    lastSaveDatasetCount: 0,
    layerGroups: [],
    maps: [],
    includedDatasets: {}, //{datasetId: true}
    includedRasters: {}, //{rasterId: true}
    layerVisibilityMap: {}, //{layerId: false/true}
    layerStylesMap: {}, //{layerId: [styleOne, ...]}
    layerStyleErrorsMap: {}, //{layerId: boolean}
    selectedLayer: null,
    selectedGroupId: null,
    selectedTool: null,
    appLayersCount: 0,
    fetching: false
};

const appDataSlice = createSlice({
    name: "appData",
    initialState,
    reducers: {
        clearAppData: () => initialState,
        setAppDetails: (state, { payload: { app, layerGroups, maps, includedDatasets, includedRasters, layerVisibilityMap, layerStylesMap } }) => {
            if (app) state.app = app;
            if (layerGroups) {
                state.layerGroups = layerGroups;
                let layerCount = 0;
                layerGroups.forLayersRecursive(() => layerCount++);
                state.appLayersCount = layerCount;
            }
            if (maps) state.maps = maps;
            if (includedDatasets) state.includedDatasets = includedDatasets;
            if (includedRasters) state.includedRasters = includedRasters;
            if (layerVisibilityMap) state.layerVisibilityMap = layerVisibilityMap;
            if (layerStylesMap) state.layerStylesMap = layerStylesMap;
        },
        setLastSaveDatasetCount: (state, { payload: datasetCount }) => {
            state.lastSaveDatasetCount = datasetCount;
        },
        setLayerStyleErrorsMap: (state, { payload: layerStyleErrorsMap }) => {
            state.layerStyleErrorsMap = layerStyleErrorsMap;
        },
        changeLayerStylesErrorStatus: (state, { payload: { layerId, newErrorStatus } }) => {
            state.layerStyleErrorsMap[layerId] = newErrorStatus;
        },
        addStyleToAppLayer: ({ selectedLayer, layerStylesMap }, { payload: newStyle }) => {
            layerStylesMap[selectedLayer.resourceId].push(newStyle);
        },
        removeStyleFromAppLayer: ({ layerStylesMap, selectedLayer: { resourceId } }, { payload: styleId }) => {
            layerStylesMap[resourceId] = layerStylesMap[resourceId].filter((s) => s.styleId !== styleId);
        },
        changeStyleTypeOfAppLayer: ({ selectedLayer: { resourceId }, layerStylesMap }, { payload: { styleId, properties, type } }) => {
            layerStylesMap[resourceId].forEach((style) => {
                if (style.styleId === styleId) {
                    style.properties = properties;
                    style.type = type;
                }
            });
        },
        changeStyleOrder: ({ layerStylesMap }, { payload: { layerId, styleId, beforeStyleId } }) => {
            const styles = layerStylesMap[layerId];

            const movedStyleIndex = styles.findIndex((s) => s.styleId === styleId);
            const destinationIndex = styles.findIndex((s) => s.styleId === beforeStyleId);

            const style = styles.splice(movedStyleIndex, 1)[0];

            styles.splice(destinationIndex, 0, style);
        },
        changePropertiesOfAppLayer: ({ selectedLayer: { resourceId }, layerStylesMap }, { payload: { styleId, newProperties } }) => {
            layerStylesMap[resourceId].forEach((style) => {
                if (style.styleId === styleId) {
                    style.properties = newProperties;
                }
            });
        },
        changeZoomLimitsOfAppLayer: ({ layerStylesMap, selectedLayer: { resourceId } }, { payload: { styleId, minZoom, maxZoom } }) => {
            layerStylesMap[resourceId].forEach((style) => {
                if (style.styleId === styleId) {
                    style.minZoom = minZoom;
                    style.maxZoom = maxZoom;
                }
            });
        },
        moveResource: moveResourceActionFunction,
        addGroup: ({ layerGroups }, { payload: newGroup }) => {
            layerGroups.unshift(newGroup);
        },
        removeGroup: (state, { payload: groupId }) => {
            if (state.selectedGroupId === groupId) {
                state.selectedGroupId = null;
            }
            if (state.selectedLayer && state.layerGroups.isChildOf(state.selectedLayer.resourceId, groupId)) {
                state.selectedLayer = null;
            }
            const group = state.layerGroups.getRecursive(groupId);

            state.layerGroups.forParentsRecursive(groupId, (parent) => {
                parent.totalLayersCount -= group.totalLayersCount;
                parent.visibleLayersCount -= group.visibleLayersCount;
            });

            state.layerGroups.unshift(...group.layers);
            state.layerGroups.removeOneRecursive(groupId);
        },
        addAppLayer: (state, { payload: { newLayer, styles } }) => {
            const { layerGroups, includedRasters, includedDatasets, layerVisibilityMap, layerStylesMap } = state;
            layerStylesMap[newLayer.resourceId] = styles;
            layerGroups.unshift(newLayer);
            const dataMap = newLayer.type === "raster" ? includedRasters : includedDatasets;
            dataMap[newLayer.resourceId] = true;
            layerVisibilityMap[newLayer.resourceId] = true;
            state.appLayersCount++;
        },
        removeAppLayer: (state, { payload: layerId }) => {
            const { includedRasters, includedDatasets, layerGroups, layerVisibilityMap, layerStylesMap } = state;

            if (state.selectedLayer?.resourceId === layerId) {
                state.selectedLayer = null;
            }
            const layer = layerGroups.getRecursive(layerId);

            layerGroups.forParentsRecursive(layerId, (parent) => {
                parent.totalLayersCount -= 1;
                if (layerVisibilityMap[layerId]) parent.visibleLayersCount -= 1;
            });
            delete layerStylesMap[layerId];
            delete layerVisibilityMap[layerId];

            layerGroups.removeOneRecursive(layerId);
            const dataMap = layer.type === "raster" ? includedRasters : includedDatasets;
            delete dataMap[layerId];
            state.appLayersCount--;
        },
        setAppModifiedTime: ({ app }, { payload: newTime }) => {
            app.modifiedUtc = newTime;
        },
        setAppPublishedStatus: ({ app }, { payload: newPublishedStatus }) => {
            app.isPublished = newPublishedStatus;
        },
        setAppName: ({ app }, { payload: newAppName }) => {
            app.name = newAppName;
        },
        setAppConfig: ({ app }, { payload: newConfig }) => {
            app.configJson = newConfig;
        },
        toggleGroupCollapse: ({ layerGroups }, { payload: { groupId, newCollapseValue } }) => {
            const group = layerGroups.getRecursive(groupId);
            group.collapsed = newCollapseValue;
        },
        setResourceOptions: ({ layerGroups, selectedLayer }, { payload: { resourceId, newOptions } }) => {
            const resource = layerGroups.getRecursive(resourceId);
            resource.options = newOptions;
            if (selectedLayer?.resourceId === resourceId) {
                selectedLayer.options = newOptions;
            }
        },
        setLayerVisibility: ({ layerGroups, layerVisibilityMap }, { payload: { layerId, newVisibility } }) => {
            layerVisibilityMap[layerId] = newVisibility;
            layerGroups.forParentsRecursive(layerId, (parent) => {
                if (newVisibility) parent.visibleLayersCount += 1;
                else parent.visibleLayersCount -= 1;
            });
        },
        setSelectedLayer: (state, { payload: layer }) => {
            state.selectedLayer = layer;
            state.selectedTool = null;
            state.selectedGroupId = null;
        },
        setSelectedGroupId: (state, { payload: groupId }) => {
            state.selectedLayer = null;
            state.selectedTool = null;
            state.selectedGroupId = groupId;
        },
        deselectResources: (state) => {
            state.selectedLayer = null;
            state.selectedGroupId = null;
        },
        setResourceName: ({ layerGroups, selectedLayer }, { payload: { resourceId, newName } }) => {
            const resource = layerGroups.getRecursive(resourceId);
            resource.name = newName;
            if (selectedLayer?.resourceId === resourceId) {
                selectedLayer.name = newName;
            }
        },
        setSelectedTool: (state, { payload: toolName }) => {
            state.selectedTool = toolName;
            state.selectedLayer = null;
            state.selectedGroupId = null;
        }
    },
    extraReducers: (builder) =>
        builder
            .addCase(toggleGroupLayers, ({ layerGroups, layerVisibilityMap }, { payload: { groupId, newVisibility } }) => {
                //It should be alright not to update the layout property "visibility".
                //Group toggle can't be used when a layer is selected so we can also omit that check
                const group = layerGroups.getRecursive(groupId);

                group.layers.forLayersRecursive((layer) => {
                    layerVisibilityMap[layer.resourceId] = newVisibility;
                });
                //Set the counts of the parent groups.
                layerGroups.forParentsRecursive(groupId, (parent) => {
                    if (newVisibility) {
                        parent.visibleLayersCount += group.totalLayersCount - group.visibleLayersCount;
                    } else {
                        parent.visibleLayersCount -= group.visibleLayersCount;
                    }
                });
                //Set the counts of the child groups.
                group.layers.forGroupsRecursive((subGroup) => {
                    subGroup.visibleLayersCount = newVisibility ? subGroup.totalLayersCount : 0;
                });
                //Set the count of the current group
                group.visibleLayersCount = newVisibility ? group.totalLayersCount : 0;
            })
            .addCase(actions.UPDATE_APP_COMPLETE, (state) => {
                state.lastSaveDatasetCount = state.appLayersCount;
            })
            .addMatcher(isAnyOf(...pendingThunks), (state) => {
                state.fetching = true;
            })
            .addMatcher(isAnyOf(...fulfilledThunks, ...rejectedThunks), (state) => {
                state.fetching = false;
            })
});

const pendingThunks = [addDatasetToAppThunk.pending, addRasterToAppThunk.pending, removeRasterFromAppThunk.pending, removeDatasetFromAppThunk.pending];
const fulfilledThunks = [addDatasetToAppThunk.fulfilled, addRasterToAppThunk.fulfilled, removeRasterFromAppThunk.fulfilled, removeDatasetFromAppThunk.fulfilled];
const rejectedThunks = [addDatasetToAppThunk.rejected, addRasterToAppThunk.rejected, removeRasterFromAppThunk.rejected, removeDatasetFromAppThunk.rejected];

export const {
    clearAppData,
    setAppDetails,
    setLastSaveDatasetCount,
    setLayerStyleErrorsMap,
    changeLayerStylesErrorStatus,
    addStyleToAppLayer,
    removeStyleFromAppLayer,
    changeStyleTypeOfAppLayer,
    changePropertiesOfAppLayer,
    changeZoomLimitsOfAppLayer,
    changeStyleOrder,
    moveResource,
    addGroup,
    removeGroup,
    addAppLayer,
    removeAppLayer,
    setAppModifiedTime,
    setAppPublishedStatus,
    setAppName,
    setAppConfig,
    toggleGroupCollapse,
    setResourceOptions,
    setLayerVisibility,
    setSelectedLayer,
    setSelectedGroupId,
    setResourceName,
    deselectResources,
    setSelectedTool
} = appDataSlice.actions;

export default appDataSlice.reducer;

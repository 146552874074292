import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as UserService from "../../../actions/userService";
import withStyles from "@mui/styles/withStyles";
import UsersListView from "../usersListView/usersListView";
import Header from "../../../components/header/header";
import HeaderButtons from "../../../components/HeaderButtons/HeaderButtons";

const styles = (theme) => ({});

const UsersRootView = ({ match, unseenNotifications, getUsers }) => {
    useEffect(() => {
        getUsers();
        //eslint-disable-next-line
    }, []);

    return (
        <div className="users-rootview">
            <div className="sidebar">
                <div className="header">
                    <Header />
                    <HeaderButtons unseenNotifications={unseenNotifications} />
                </div>
                <UsersListView />
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        unseenNotifications: state.notifications.unseenNotifications
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUsers: () => dispatch(UserService.getUsers())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UsersRootView));

import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Context from "./contextMenu";
import { layerJss } from "./jss/layerJss";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedLayer, getLayerVisibility, getLayerErrorStatus } from "../../../../selectors/appData";
import * as appDataActions from "../../../../reducers/appData/appData";
import isNewDepthValid from "../../../../utils/layerGroups/isNewDepthValid";
import toastr from "../../../../components/CustomToastr/CustomToastr";
import layerGroupSettings from "../../../../utils/constants/layerGroupSettings";
import StaticMouseTooltip from "../../../../components/StaticMouseTooltip/StaticMouseTooltip";
import WarningIcon from "@mui/icons-material/WarningAmber";
import { Tooltip } from "@mui/material";
import { useDragAndDropStyles } from "../../../../utils/styles/dragAndDrop";
import { toggleAppLayer } from "../../../../actions/globalActions";

function Layer({ classes, layer, onRemoveDataset, depth, vListLayerTools: { vListIndex, markIndexForRecomputation } }) {
    const dragAndDropClasses = useDragAndDropStyles();
    const [contextOpen, setContextOpen] = useState(false);
    const [isBeingDragged, setIsBeingDragged] = useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [dragAboveMiddle, setDragAboveMiddle] = useState(null);
    const [eventTarget, setEventTarget] = useState(null);

    const selectedLayer = useSelector(getSelectedLayer);
    const dispatch = useDispatch();
    const anchorRef = React.useRef(null);

    const visible = useSelector(getLayerVisibility(layer.resourceId));
    const errorStatus = useSelector(getLayerErrorStatus(layer.resourceId));

    const toggleCMenu = (e) => {
        if (e) e.stopPropagation();
        setContextOpen((contextOpen) => !contextOpen);
        return null;
    };

    function onDragOver(event) {
        event.stopPropagation();

        if (isBeingDragged) {
            return false;
        }

        event.preventDefault();

        const rect = event.target.getBoundingClientRect();

        setDragAboveMiddle(event.clientY < rect.y + rect.height / 2);

        setDragOver(true);
        setEventTarget(event.target);

        return true;
    }

    function onDragLeave(event) {
        event.preventDefault();
        if (eventTarget === event.target) {
            setDragOver(false);
        }
    }

    function onDrop(event, asChild) {
        event.preventDefault();
        event.stopPropagation();
        const dropData = JSON.parse(event.dataTransfer.getData("text"));
        setDragOver(false);
        //You can't drop something on itself
        if (dropData.resourceId === layer.resourceId) {
            return;
        }
        if (!isNewDepthValid(depth, dropData.depth, asChild)) {
            toastr.error(`You can't nest groups deeper than ${layerGroupSettings.MAX_DEPTH} levels`);
            return;
        }
        markIndexForRecomputation(vListIndex);
        markIndexForRecomputation(dropData.vListIndex);
        dispatch(appDataActions.moveResource({ resourceId: dropData.resourceId, destinationId: layer.resourceId, moveAsChild: asChild, moveAbove: dragAboveMiddle }));
    }

    function onDragStart(e) {
        e.stopPropagation();

        const dropData = {
            resourceId: layer.resourceId,
            type: "layer",
            depth: 0,
            vListIndex
        };
        e.dataTransfer.setData("text/plain", JSON.stringify(dropData));

        setIsBeingDragged(true);

        return false;
    }

    function onDragEnd(e) {
        e.stopPropagation();
        setIsBeingDragged(false);
    }

    const toggleVisibility = (visible) => {
        dispatch(toggleAppLayer(layer.resourceId, visible));
    };

    const onLayerSelected = () => {
        dispatch(appDataActions.setSelectedLayer(layer));
        if (!visible) toggleVisibility(true);
    };

    const dragIndicatorClass = dragAboveMiddle ? dragAndDropClasses.dragIndicatorAbove : dragAndDropClasses.dragIndicatorBelow;
    const selected = selectedLayer && layer.resourceId === selectedLayer.resourceId;

    return (
        <div
            className={(selected ? "layer selected" : "layer") + " " + (dragOver ? dragIndicatorClass : "") + " " + (depth === 0 ? classes.depthZeroPadding : "")}
            draggable={true}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDragStart={onDragStart}
            onDragEnter={onDragOver}
            onDrop={onDrop}
            onDragEnd={onDragEnd}
            style={{ opacity: isBeingDragged ? 0.4 : 1 }}
        >
            <Switch
                className={classes.switch}
                edge="end"
                color={selected ? "default" : "primary"}
                checked={!!visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
                onClick={(e) => toggleVisibility(!visible)}
            />
            {errorStatus && (
                <Tooltip title="This layer has a style with invalid values.">
                    <WarningIcon className={classes.iconSpaceRight} color="error" fontSize="small" />
                </Tooltip>
            )}
            <StaticMouseTooltip title={layer.name}>
                {/* Title causes an extra tooltip to appear  */}
                <Typography noWrap variant="body1" title="" color="inherit" className={classes.layerText} onClick={onLayerSelected}>
                    {layer.name}
                </Typography>
            </StaticMouseTooltip>

            <span className={classes.grow}></span>

            <IconButton
                className={classes.contextMenuBtn}
                ref={anchorRef}
                aria-controls={contextOpen ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={(e) => {
                    e.currentTarget.blur();
                    toggleCMenu();
                }}
                size="large"
            >
                <MoreHoriz />
            </IconButton>
            <Context
                anchorEl={anchorRef.current}
                layer={layer}
                open={contextOpen}
                toggleCMenu={toggleCMenu}
                onLayerSelected={onLayerSelected}
                markForRecomputation={() => markIndexForRecomputation(vListIndex)}
            />
        </div>
    );
}

export default withStyles(layerJss)(Layer);

import React, { Component } from "react";
import { connect } from "react-redux";
import GlMap from "mapbox-gl";
import config from "../../config";
import * as mapActions from "../../reducers/map";
import { BASEMAPS } from "../../utils/constants/appDefaults";
import ResizeObserver from "resize-observer-polyfill";
import { validate } from "@mapbox/mapbox-gl-style-spec";
import * as appActions from "../../reducers/appData/appData";

// Quick fix for https://github.com/mapbox/mapbox-gl-js/issues/10173 (might be fixed in the future, so this can be removed)
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
GlMap.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

var MAPBOX_APIKEY = config.mapboxApiKey;

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapLoaded: false,
            selectedFeatures: [],
            //mapBounds doubles down the fitBounds state from redux in order to circumvent the render race condition
            mapBounds: null //{bbox, options}
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this._resize);

        this.initMap();
    }

    initMap() {
        GlMap.accessToken = MAPBOX_APIKEY;

        let glMapOptions = {
            container: "map",
            style: config.basemap,
            zoom: 1.5,
            center: [15, 40],
            maxZoom: 24,
            transformRequest: (url, resourceType) => {
                if (resourceType === "Tile" && url.startsWith(config.apiUrl)) {
                    return {
                        url: url + "?key=" + this.props.auth.token
                    };
                }
            }
        };

        this.map = new GlMap.Map(glMapOptions);
        this.map.on("load", () => this._mapLoad());
        this.map.on("data", (e) => this.onMapDataEvent(e));
        this.map.setStyle(BASEMAPS[0].url);

        const resizeObserver = new ResizeObserver(() => {
            setTimeout(() => {
                this._resize();
            }, 25);
        });

        let map = document.getElementById("map");
        resizeObserver.observe(map);
    }

    componentWillUnmount() {
        let map = document.getElementById("map");
        map.removeEventListener("resize", this._resize);
        this.map.remove();
    }

    onMapDataEvent = (e) => {
        let style = this.map.getStyle();

        if (style.sprite !== config.apiUrl + "api/sprite/") {
            style.sprite = config.apiUrl + "api/sprite/";
            this.map.setStyle(style);
        }
    };

    _mapLoad = () => {
        // map.mapObject.on('click', (e) => this._onMapClick(e));
        // map.mapObject.on('draw.create', this._drawnFeaturesChanged);
        // map.mapObject.on('draw.delete', this._drawnFeaturesChanged);
        // map.mapObject.on('draw.update', this._drawnFeaturesChanged);
        //this.map.on("zoomend", () => this.updatePosition());
        this.map.on("moveend", () => this.updatePosition());

        // this.map.loadImage("https://localhost:55008/api/sprite/test", (err,image) => {
        //     this.map.addImage('test',image);
        // })
        this.addSources(this.props.mapState.sources);
        this.addLayers(this.props.mapState.layers);
        this.addPaint(Object.values(this.props.mapState.paints));
        this.changeLayout(Object.values(this.props.mapState.layouts));
        this.setState({
            mapLoaded: true
        });
    };

    _resize = () => {
        this.map.resize();
    };

    updatePosition = () => {
        let bounds = this.map.getBounds();

        this.props.setPosition({
            zoom: this.map.getZoom(),
            bounds: [
                [bounds._sw.lng, bounds._sw.lat],
                [bounds._ne.lng, bounds._ne.lat]
            ]
        });
    };

    _onViewportChange = (viewport) => this.setState({ viewport });

    _onStyleChange = (mapStyle) => this.setState({ mapStyle });

    _onMapClick = (e) => {};

    addSources(sources) {
        for (let i = 0; i < sources.length; i++) {
            let source = sources[i];
            if (!this.map.getSource(source.id)) {
                let endpointName = source.type === "raster" ? "raster" : "tile";
                this.map.addSource(source.id, {
                    type: source.type === "raster" ? "raster" : "vector",
                    tiles: [config.apiUrl + `api/${endpointName}/${source.id}/{z}/{x}/{y}`],
                    minzoom: source.minZoom,
                    maxzoom: source.maxZoom
                });
            }
        }
    }

    addLayers(layers) {
        // Find the index of the first symbol layer in the map style
        let FirstSymbolId;
        let mapLayers = this.map.getStyle().layers;

        for (let i = 0; i < mapLayers.length; i++) {
            if (mapLayers[i].type === "symbol") {
                FirstSymbolId = mapLayers[i].id;
                break;
            }
        }
        const symbolLayers = layers.filter((x) => x.type === "symbol");
        const normalLayers = layers.filter((x) => x.type !== "symbol");
        const arrangedLayers = [...normalLayers, ...symbolLayers];
        //Draw layers in reverse order
        for (let i = 0; i < arrangedLayers.length; i++) {
            let layer = arrangedLayers[i];
            const vectorLayer = {
                id: layer.layerId,
                type: layer.type,
                source: layer.sourceId,
                "source-layer": layer.sourceName,
                minzoom: layer.minZoom,
                maxzoom: layer.maxZoom
            };

            if (layer.type !== "symbol") {
                this.map.addLayer(vectorLayer, FirstSymbolId);
                FirstSymbolId = layer.layerId;
                if (!this.map.firstLayer) this.map.firstLayer = vectorLayer.id;
            } else {
                this.map.addLayer(vectorLayer);
            }
        }
    }

    buildStyleForValidation(listOfLayers, listOfSources, paintsDict, layoutsDict) {
        const layers = listOfLayers.map((layer) => {
            const paint = paintsDict[layer.layerId].properties.reduce((acc, paintProp) => {
                if (layer.type === "raster" && paintProp.name === "fill-color") return acc; //fill-color is an improvization for legend
                acc[paintProp.name] = paintProp.value;
                return acc;
            }, {});

            const layout = layoutsDict[layer.layerId].properties.reduce((acc, layoutProp) => {
                acc[layoutProp.name] = layoutProp.value;
                return acc;
            }, {});

            return {
                type: layer.type,
                id: layer.layerId,
                source: layer.sourceId,
                "source-layer": layer.sourceName,
                paint,
                layout
            };
        });

        const sources = listOfSources.reduce((acc, source) => {
            acc[source.id] = { type: source.type };
            return acc;
        }, {});

        return {
            version: 8,
            glyphs: "http://fonts.openmaptiles.org/{fontstack}/{range}.pbf", //needed for text-field property validation
            layers,
            sources
        };
    }

    registerLayerErrors(errors, layers) {
        const layerStyleErrorsMap = {}; //this refers to app layers, not map layers
        errors.forEach(({ message }) => {
            const layerText = message.split(":")[0];

            // This is done for the situation when we have layers[128].paint.line-width[5]
            const layerNumberSection = layerText.split(".")[0];

            const numberBetweenSquareBracketsExpression = /\[(.*?)\]/;
            const layerIndex = layerNumberSection.match(numberBetweenSquareBracketsExpression)[1];
            layerStyleErrorsMap[layers[layerIndex].resourceId] = true;
        });
        this.props.setLayerStyleErrorsMap(layerStyleErrorsMap);
    }

    validateStyles() {
        const { layers, sources, paints, layouts } = this.props.mapState;
        const styleForValidation = this.buildStyleForValidation(layers, sources, paints, layouts);
        const errors = validate(styleForValidation);

        this.registerLayerErrors(errors, layers);
    }

    removeLayers(previousLayers, currentLayers) {
        let LayersMap = currentLayers.reduce((a, b, index) => {
            a[b.layerId] = index;
            return a;
        }, {});

        for (let i = 0; i < previousLayers.length; i++) {
            let layer = previousLayers[i];

            if (!LayersMap.hasOwnProperty(layer.layerId)) {
                this.map.removeLayer(layer.layerId);
            }
        }
    }

    changeLayers(previousLayers, currentLayers) {
        // let previousLayersMap = previousLayers.reduce((a, b, index) => {
        //     a[b.layerId] = index;
        //     return a;
        // }, {});

        for (let i = 0; i < currentLayers.length; i++) {
            let layer = currentLayers[i];
            let previousLayer = previousLayers[i];

            if (layer.changed) {
                this.map.removeLayer(layer.layerId);

                var vectorSource = {
                    id: layer.layerId,
                    type: layer.type,
                    source: layer.sourceId,
                    "source-layer": layer.sourceName,
                    paint: {}
                };

                if (layer.drawBefore !== null) {
                    this.map.addLayer(vectorSource, layer.drawBefore);
                } else {
                    this.map.addLayer(vectorSource);
                }

                let paint = this.props.mapState.paints[layer.layerId] || { properties: [] };
                let layout = this.props.mapState.layouts[layer.layerId] || { properties: [] };

                this.changePaint([paint]);
                this.changeLayout([layout]);
            } else if (layer.layerId !== previousLayer.layerId) {
                this.map.moveLayer(layer.layerId, i === 0 ? null : currentLayers[i - 1].layerId);
            }
        }
    }

    moveLayer(layerId, beforeLayerId) {
        this.map.moveLayer(layerId, beforeLayerId);
    }

    changePaint(paints) {
        for (let k = 0; k < paints.length; k++) {
            const paint = paints[k];
            for (let i = 0; i < paint.properties.length; i++) {
                const paintProperty = paint.properties[i];
                paintProperty.title !== "Legend" && this.map.setPaintProperty(paint.layerId, paintProperty.name, paintProperty.value);
            }
        }
    }

    addPaint(paints) {
        for (let k = 0; k < paints.length; k++) {
            const paint = paints[k];

            for (let i = 0; i < paint.properties.length; i++) {
                const paintProperty = paint.properties[i];
                paintProperty.title !== "Legend" && this.map.setPaintProperty(paint.layerId, paintProperty.name, paintProperty.value);
            }
        }
        if (!this.props.mapState.addedInitialPaints && paints.length) {
            this.props.addedInitialPaints();
        }
    }

    changeLayout(layouts) {
        for (let k = 0; k < layouts.length; k++) {
            let layout = layouts[k];
            for (let i = 0; i < layout.properties.length; i++) {
                let layoutProperty = layout.properties[i];
                try {
                    this.map.setLayoutProperty(layout.layerId, layoutProperty.name, layoutProperty.value);
                } catch (styleErr) {
                    console.log({ styleErr });
                }
            }
        }
        if (!this.props.mapState.addedInitialLayouts && layouts.length) {
            this.props.addedInitialLayouts();
        }
    }

    changeLayerZoomRanges(layerZooms) {
        for (let k = 0; k < layerZooms.length; k++) {
            let layerZoom = layerZooms[k];
            this.map.setLayerZoomRange(layerZoom.layerId, layerZoom.minZoom, layerZoom.maxZoom);
        }
    }

    jumpTo(options) {
        this.map.jumpTo(options);
    }

    fitBounds(bbox, options) {
        this.map.fitBounds(bbox, options);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.mapState.addedInitialLayouts &&
            this.props.mapState.addedInitialPaints &&
            (!prevProps.mapState.addedInitialLayouts || !prevProps.mapState.addedInitialPaints)
        ) {
            this.validateStyles();
        }

        if (!this.state.mapLoaded) {
            return;
        }

        if (prevProps.mapState.sources !== this.props.mapState.sources) {
            this.addSources(this.props.mapState.sources);
        }

        if (prevProps.mapState.layers.length < this.props.mapState.layers.length) {
            this.addLayers(this.props.mapState.layers.slice(prevProps.mapState.layers.length, this.props.mapState.layers.length));
        } else if (prevProps.mapState.layers.length > this.props.mapState.layers.length) {
            this.removeLayers(prevProps.mapState.layers, this.props.mapState.layers);
        } else if (prevProps.mapState.layers !== this.props.mapState.layers) {
            this.changeLayers(prevProps.mapState.layers, this.props.mapState.layers);
        }

        if (prevProps.mapState.paints !== this.props.mapState.paints) {
            this.changePaint(Object.values(this.props.mapState.paints));
        }

        if (prevProps.mapState.layouts !== this.props.mapState.layouts) {
            this.changeLayout(Object.values(this.props.mapState.layouts));
        }

        if (prevProps.mapState.zoomRanges !== this.props.mapState.zoomRanges) {
            this.changeLayerZoomRanges(Object.values(this.props.mapState.zoomRanges));
        }

        if (prevProps.mapState.jumpLocation !== this.props.mapState.jumpLocation) {
            this.jumpTo(this.props.mapState.jumpLocation);
        }

        if (this.state.mapBounds !== this.props.mapState.fitBounds) {
            this.fitBounds(this.props.mapState.fitBounds.bbox, this.props.mapState.fitBounds.options);
            this.setState({ mapBounds: this.props.mapState.fitBounds });
        }

        if (prevProps.mapState.moveLayer !== this.props.mapState.moveLayer) {
            this.moveLayer(this.props.mapState.moveLayer.layerId, this.props.mapState.moveLayer.beforeLayerId);
        }
    }

    render() {
        return <div id="map">{this.props.children}</div>;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mapState: state.map,
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setPosition: (position) => dispatch(mapActions.setMapPosition(position)),
        setLayerStyleErrorsMap: (layerStyleErrorsMap) => dispatch(appActions.setLayerStyleErrorsMap(layerStyleErrorsMap)),
        addedInitialPaints: () => dispatch(mapActions.addedInitialPaints()),
        addedInitialLayouts: () => dispatch(mapActions.addedInitialLayouts())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);

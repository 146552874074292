import React from "react";

import "./utils/validators/utilFunctions.js";
import "@emblautec/rescursive-array-extensions";
import "./scss/main.scss";
import "./App.css";

import { connect } from "react-redux";
import { Switch, Router, Redirect } from "react-router-dom";
import { history } from "./store";
import ReduxToastr from "react-redux-toastr";
import NavigationMenu from "./components/NavigationMenu/NavigationMenu";
import Notifications from "./components/notifications/notifications";
import Uploader from "./components/uploader/uploader";
import LoaderComponent from "./components/auth0Loader/LoaderComponent";
import GuardedRoute from "./components/guardedRoute/GuardedRouteComponent";
import * as AuthActions from "./actions/auth";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { LOCATIONS } from "./utils/routeUtils";
import Bootstrap from "./Bootstrap.js";
//Webpack 5 doesn't include Buffer anymore and we need it for the style validation library
global.Buffer = global.Buffer || require("buffer").Buffer;

function App(props) {
    return (
        <div className="app">
            <Bootstrap>
                <Uploader />
                <Router history={history}>
                    <div className={`app-wrapper menu-is-${props.isNavigationOpen ? `open` : `closed`}`}>
                        <NavigationMenu />
                        <Switch>
                            {LOCATIONS.map((location) => (
                                <GuardedRoute
                                    exact={location.exact}
                                    path={location.route}
                                    component={location.component}
                                    condition={location.allowedRoles.includes(props.currentUserRole)}
                                    key={location.route}
                                />
                            ))}
                            <Redirect to="/datasets" />
                        </Switch>
                    </div>
                </Router>
            </Bootstrap>
            <Notifications />
            <ReduxToastr
                timeOut={4000}
                newestOnTop={true}
                position="top-right"
                getState={(state) => state.toastr} // This is the default
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                closeOnToastrClick
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    currentUserRole: state.auth.userRole,
    isNavigationOpen: state.nav.isNavigationOpen
});

const mapDispatchToProps = {
    updateAuthToken: AuthActions.setAuthToken
};

export default withAuthenticationRequired(connect(mapStateToProps, mapDispatchToProps)(App), {
    // Show a message while the user waits to be redirected to the login page.
    onRedirecting: () => <LoaderComponent text="Authenticating" />
});

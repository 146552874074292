import { Radio } from "@mui/material";
import React, { useState } from "react";
import useIfMounted from "../../../../../utils/customHooks/useIfMounted";
import OverflowTip from "../../../../../components/OverflowTip/OverflowTip";

const ResourcePermissions = ({ resource, permissions, permissionChangePromise }) => {
    const [updatingPermission, setUpdatingPermission] = useState(false);
    const execIfMounted = useIfMounted();

    const handlePermissionChange = (newPermission) => {
        setUpdatingPermission(true);
        permissionChangePromise(resource, newPermission.value).finally(() => execIfMounted(() => setUpdatingPermission(false)));
    };

    return (
        <div className="resourcePermissionItem">
            <div className="name">
                <OverflowTip>{resource.name}</OverflowTip>
            </div>

            {permissions.map((permission, index) => {
                return (
                    <div key={index} className="checkbox-container">
                        <Radio disabled={updatingPermission} checked={resource.permissionType === permission.value} onChange={() => handlePermissionChange(permission)} />
                        <div className="radio-label">{permission.name}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default ResourcePermissions;

import * as types from "../actions/actionTypes";

const uploaderInit = {
    open: false
};

export function uploaderReducer(state = uploaderInit, action) {
    switch (action.type) {
        case types.OPEN_UPLOADER:
            return {
                ...state,
                open: true
            };
        case types.CLOSE_UPLOADER:
            return {
                ...state,
                open: false
            };
        default:
            return state;
    }
}

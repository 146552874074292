import { makeStyles } from "@mui/styles";
import styles from "../../../../../scss/themeVariables.module.scss";

export const useRasterRowStyles = makeStyles((theme) => ({
    nameCell: {
        color: styles.primaryColor,

        "&:hover": {
            textDecoration: "underline"
        }
    }
}));

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    logoutButton: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        marginLeft: 16,
        justifyContent: "center",
        height: "100%",

        "&:hover": {
            color: theme.customColors.appBgColorDark
        }
    },
    profileInfo: {
        display: "flex",
        textAlign: "center",
        flexDirection: "column"
    },
    signOut: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-evenly"
    },
    headerButtons: {
        height: 48,
        display: "flex",
        alignItems: "center",
        position: "absolute",
        zIndex: 1,
        right: theme.customSpacing.generalSidePadding
    },
    avatar: {
        width: 30,
        height: 30,
        fontSize: 12,
        backgroundColor: theme.customColors.appBgColorDark
    },
    username: {
        marginLeft: 8,
        display: "flex",
        alignItems: "center",
        lineHeight: 1,
        textTransform: "capitalize"
    },
    arrow: {
        marginLeft: 8
    }
}));

import React, { useState } from "react";
import { Tooltip, MenuItem, Typography, Divider, Popover, MenuList, Avatar } from "@mui/material";
import NotificationButton from "../notifications/notificationButton";
import ExitToApp from "@mui/icons-material/ExitToApp";
import { useStyles } from "../HeaderButtons/style.js";
import { useAuth0 } from "@auth0/auth0-react";

import packageJson from "../../../package.json";

const HeaderButtons = ({ unseenNotifications }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { user, logout } = useAuth0();
    const id = open ? "user-menu" : undefined;

    const [isAccountClicked, setisAccountClicked] = useState(false);

    const signOut = (e) => {
        logout({ returnTo: window.location.origin });
        setAnchorEl(null);
    };

    const onAccountclick = (e) => {
        setAnchorEl(e.currentTarget);
        setisAccountClicked(!isAccountClicked);
    };

    const handleCloseAccountMenu = () => {
        setAnchorEl(null);
        setisAccountClicked(!isAccountClicked);
    };

    const initialsAvatar = (name) => {
        return `${name[0]}${name[1]}`;
    };

    return (
        <div className={classes.headerButtons}>
            <NotificationButton unseenNotifications={unseenNotifications} />
            <Tooltip title="Account" placement="bottom">
                <div className={classes.logoutButton} onClick={onAccountclick}>
                    <Avatar className={classes.avatar}>{initialsAvatar(user.nickname.toUpperCase())}</Avatar>
                </div>
            </Tooltip>
            <Popover
                PaperProps={{ classes: { root: classes.paperRoot } }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseAccountMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
            >
                <MenuList id="user-menu-aria">
                    {user && (
                        <MenuItem>
                            <div className={classes.profileInfo}>
                                <Typography variant="body2">{user.nickname.toUpperCase()}</Typography>
                                <Typography variant="caption">{user.email}</Typography>
                                <Typography variant="caption">v {packageJson.version}</Typography>
                            </div>
                        </MenuItem>
                    )}
                    <Divider />
                    <MenuItem onClick={signOut}>
                        <div className={classes.signOut}>
                            <ExitToApp />
                            <Typography variant="body2">Sign out</Typography>
                        </div>
                    </MenuItem>
                </MenuList>
            </Popover>
        </div>
    );
};

export default HeaderButtons;

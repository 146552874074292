function sortByName(raster1, raster2) {
    return raster1.name.localeCompare(raster2.name);
}

function sortByNameReverse(raster1, raster2) {
    return raster2.name.localeCompare(raster1.name);
}

function sortByCreated(raster1, raster2) {
    return new Date(raster2.createdUtc) - new Date(raster1.createdUtc);
}

function sortByCreatedReverse(raster1, raster2) {
    return new Date(raster1.createdUtc) - new Date(raster2.createdUtc);
}
const rasterColumns = [
    {
        type: "name",
        name: "name",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByNameReverse : sortByName),
        start: true
    },
    {
        type: "date",
        name: "Created",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByCreatedReverse : sortByCreated)
    }
];

export default rasterColumns;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import Map from "../../../components/map/mapComponent";
import RasterListView from "../rastersListView/rastersListView";
import EditView from "../rasterEditView/rasterEditView";
import Header from "../../../components/header/header";
import HeaderButtons from "../../../components/HeaderButtons/HeaderButtons";
import MapTools from "../../../components/map/mapTools/MapTools";
import ZoomLevelWidget from "../../../components/map/mapTools/ZoomLevelWidget/ZoomLevelWidget";

const styles = () => ({});

class RasterRootView extends Component {
    render() {
        let { match, isNavigationOpen, unseenNotifications, location } = this.props;

        return (
            <div className="rasters-rootview">
                <div className={isNavigationOpen ? "sidebar" : "sidebar closed"}>
                    <div className="header">
                        <Header />
                        <HeaderButtons unseenNotifications={unseenNotifications} />
                    </div>
                    <Switch>
                        <Route exact path={match.path} component={RasterListView} />
                        <Route path={`${match.path}/edit/:rasterId`} component={EditView} />
                    </Switch>
                </div>
                <div className={location.pathname !== "/rasters" ? "map" : "map hidden"}>
                    <Map>
                        <MapTools>
                            <ZoomLevelWidget />
                        </MapTools>
                    </Map>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        unseenNotifications: state.notifications.unseenNotifications,
        isNavigationOpen: state.nav.isNavigationOpen
    };
};

export default connect(mapStateToProps)(withStyles(styles)(RasterRootView));

import * as types from "../actions/actionTypes";

const datasetsInit = {
    fetching: true,
    datasets: [],
    selected: {}
};

export function datasetsReducer(state = datasetsInit, action) {
    switch (action.type) {
        case types.FETCH_DATASETS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_DATASETS_COMPLETE:
            return {
                ...state,
                fetching: false,
                datasets: [...action.result]
            };
        case types.CREATE_DATASET_COMPLETE:
            return {
                ...state,
                datasets: [...state.datasets, action.result]
            };
        case types.DELETE_DATASET:
            return {
                ...state,
                fetching: true
            };
        case types.DELETE_DATASET_COMPLETE:
            return {
                ...state,
                fetching: false,
                datasets: state.datasets.filter((x) => x.id !== action.result)
            };
        case types.DATASET_ACTION_FAILED:
            return {
                ...state,
                fetching: false
            };
        case types.SIGNALR_PROGRESS_UPDATE:
            return {
                ...state,
                datasets: updateArrayCacheStatus(state.datasets, action.result)
            };
        case types.SIGNALR_ERROR_UPDATE:
            return {
                ...state,
                datasets: updateArrayWithCacheStatus(state.datasets, action.result.id, 0)
            };
        case types.CANCEL_GENERATE_DATASET_CACHE_COMPLETE:
            return {
                ...state,
                datasets: updateArrayWithCacheStatus(state.datasets, action.result, 0)
            };
        default:
            return state;
    }
}

const datasetDetailsInit = {
    fetching: true,
    cacheStatus: 0,
    datasetId: ""
};

export function datasetDetailsReducer(state = datasetDetailsInit, action) {
    switch (action.type) {
        case types.FETCH_DATASET_DETAILS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_DATASET_DETAILS_COMPLETE:
            return {
                ...state,
                fetching: false,
                cacheStatus: action.result.cacheStatus,
                datasetId: action.result.id
            };
        case types.UPDATE_DATASET:
            return {
                ...state,
                fetching: true
            };
        case types.UPDATE_DATASET_COMPLETE:
            return {
                ...state,
                fetching: false
            };
        case types.UPDATE_DATASET_ADVANCED:
            return {
                ...state,
                fetching: true
            };
        case types.UPDATE_DATASET_ADVANCED_COMPLETE:
            return {
                ...state,
                fetching: false,
                cacheStatus: 0
            };
        case types.GENERATE_DATASET_CACHE:
            return {
                ...state,
                fetching: true
            };
        case types.GENERATE_DATASET_CACHE_COMPLETE:
            return {
                ...state,
                cacheStatus: 1,
                fetching: false
            };
        case types.DOWNLOAD_DATASET:
            return {
                ...state,
                fetching: true
            };
        case types.DOWNLOAD_DATASET_COMPLETE:
            return {
                ...state,
                fetching: false
            };
        case types.LINK_DATASET_GOOGLE_DRIVE:
            return {
                ...state,
                fetching: true
            };
        case types.LINK_DATASET_GOOGLE_DRIVE_COMPLETE:
            return {
                ...state,
                fetching: false
            };

        case types.DATASET_ACTION_FAILED:
            return {
                ...state,
                fetching: false
            };
        case types.SIGNALR_PROGRESS_UPDATE:
            return {
                ...state,
                cacheStatus: updateCacheStatus(state.cacheStatus, state.datasetId, action.result)
            };
        case types.SIGNALR_ERROR_UPDATE:
            return {
                ...state,
                cacheStatus: 0
            };
        case types.CANCEL_GENERATE_DATASET_CACHE_COMPLETE:
            return {
                ...state,
                cacheStatus: state.datasetId === action.result ? 0 : state.cacheStatus,
                fetching: state.datasetId === action.result ? false : state.fetching
            };
        default:
            return state;
    }
}

function updateCacheStatus(cacheStatus, datasetId, notification) {
    if (datasetId === notification.id) {
        return notification.cacheStatus;
    }
    return cacheStatus;
}

function updateArrayCacheStatus(array, notification) {
    let newArray = array.map((item) => {
        if (item.id === notification.id) {
            return {
                ...item,
                cacheStatus: notification.cacheStatus
            };
        }
        return item;
    });

    return newArray;
}

function updateArrayWithCacheStatus(array, notificationId, cacheStatus) {
    let newArray = array.map((item) => {
        if (item.id === notificationId) {
            return {
                ...item,
                cacheStatus: cacheStatus
            };
        }
        return item;
    });

    return newArray;
}

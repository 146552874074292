import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export const getGroups = () => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_GROUPS });

        return axiosClient.get("admin/group").then(function (res) {
            return dispatch({ type: types.FETCH_GROUPS_COMPLETE, result: res.data });
        });
    };
};

export const getGroup = (groupId) => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_GROUP });

        return axiosClient.get("group/" + groupId).then(function (res) {
            return dispatch({ type: types.FETCH_GROUP_COMPLETE, result: res.data });
        });
    };
};

export const createGroup = (createGroupRequest) => {
    return (dispatch) => {
        dispatch({ type: types.CREATE_GROUP });

        return axiosClient
            .post("group", createGroupRequest)
            .then(function (res) {
                return dispatch({
                    type: types.CREATE_GROUP_COMPLETE,
                    result: res.data
                });
            })
            .catch((err) => {
                dispatch({ type: types.GROUP_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const updateGroup = (groupId, newGroup) => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_GROUP });
        return axiosClient
            .put("group/" + groupId, newGroup)
            .then(function (res) {
                return dispatch({
                    type: types.UPDATE_GROUP_COMPLETE,
                    result: { id: groupId, name: newGroup.name }
                });
            })
            .catch((err) => {
                dispatch({ type: types.GROUP_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const deleteGroup = (groupId) => {
    return (dispatch) => {
        dispatch({ type: types.DELETE_GROUP });

        return axiosClient
            .delete("group/" + groupId)
            .then(() => {
                return dispatch({ type: types.DELETE_GROUP_COMPLETE, result: { id: groupId } });
            })
            .catch((err) => {
                dispatch({ type: types.GROUP_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const addGroupUser = (groupId, userId) => {
    return () => {
        return axiosClient.post(`group/${groupId}/user/${userId}`).then(() => ({ result: { id: groupId } }));
    };
};

export const removeGroupUser = (groupId, userId) => {
    return () => {
        return axiosClient.delete(`group/${groupId}/user/${userId}`).then(() => ({ result: { id: groupId } }));
    };
};

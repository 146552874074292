import { validRasterLayouts } from "../constants/validRasterLayouts";
import { validRasterPaints } from "../constants/validRasterPaints";

export const buildValidationStyleFromLayerStyles = (layerStyles) => {
    const layers = layerStyles.map((style) => {
        const paint = {},
            layout = {};
        style.properties.forEach(({ type, name, value }) => {
            if (type === "paint") {
                if (style.type === "raster" && !validRasterPaints[name]) return;
                paint[name] = value;
            }
            if (type === "layout") {
                if (style.type === "raster" && !validRasterLayouts[name]) return;

                layout[name] = value;
            }
        });
        const source = style.type === "raster" ? "raster-source" : "vector-source";
        return { type: style.type, id: style.styleId, source, "source-layer": "placeholder", paint, layout };
    });

    return {
        version: 8,
        glyphs: "http://fonts.openmaptiles.org/{fontstack}/{range}.pbf", //needed for text-field property validation
        layers,
        sources: { "vector-source": { type: "vector" }, "raster-source": { type: "raster" } }
    };
};

import makeStyles from "@mui/styles/makeStyles";

const useBeforeUploadStyles = makeStyles((theme) => ({
    grey: {
        color: theme.customColors.darkGrey
    },
    black: {
        color: "#000000"
    },
    closeButton: {
        margin: -12
    },
    selectButton: {
        height: 36,
        fontSize: 14,
        fontWeight: "bold",
        transition: "all 0.2s",
        padding: "7px 16px",
        display: "flex",
        cursor: "pointer",
        margin: "auto",
        "& .MuiButton-label": {
            textTransform: "initial"
        },
        "&:hover": {
            backgroundColor: "rgba(211, 226, 234, 0.8)"
        }
    },
    centered: {
        textAlign: "center"
    },
    actions: {
        display: "flex",
        justifyContent: "center"
    }
}));

export default useBeforeUploadStyles;

import React, { useState } from "react";

import Typography from "@mui/material/Typography";

import { TextField, Checkbox, InputAdornment, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import * as appDataActions from "../../../../reducers/appData/appData";
import { getDatasets } from "../../../../selectors/datasets";
import { getSelectedLayer } from "../../../../selectors/appData";
import ReadOnlyField from "../../../../components/ReadOnlyField/ReadOnlyField";

const useStyles = makeStyles((theme) => ({
    okBtn: {
        borderRadius: 3,
        height: 24,
        width: 24,
        color: theme.customColors.primaryColor,
        backgroundColor: theme.customColors.secondaryColor
    },
    clearBtn: {
        borderRadius: 3,
        height: 24,
        width: 24,
        color: theme.customColors.semantic2,
        backgroundColor: "#FCE2E1",
        marginRight: 8
    }
}));

const LayerEditView = () => {
    const layer = useSelector(getSelectedLayer);
    const [newDisplayName, setNewDisplayName] = useState(layer.name);
    const datasets = useSelector(getDatasets);
    const classes = useStyles();
    const dispatch = useDispatch();

    function onStartsEnabledChanged(layer) {
        let newOptions = { ...layer.options };

        newOptions.enabled = !newOptions.enabled;
        dispatch(appDataActions.setResourceOptions({ resourceId: layer.resourceId, newOptions }));
    }

    function onDownloadableChanged(layer) {
        let newOptions = { ...layer.options };

        newOptions.downloadable = !newOptions.downloadable;
        dispatch(appDataActions.setResourceOptions({ resourceId: layer.resourceId, newOptions }));
    }

    const onCommitChanges = () => {
        dispatch(appDataActions.setResourceName({ resourceId: layer.resourceId, newName: newDisplayName }));
    };

    const onDiscardChanges = () => {
        setNewDisplayName(layer.name);
    };

    const dataset = datasets.find((x) => x.id === layer.resourceId);
    let zoom = `${dataset.minZoom} - ${dataset.maxZoom}`;
    return (
        <div className="edit-page">
            <div className="field">
                <TextField
                    value={newDisplayName}
                    fullWidth
                    label="Display name"
                    variant="filled"
                    onChange={(e) => setNewDisplayName(e.target.value)}
                    InputProps={
                        newDisplayName !== layer.name
                            ? {
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <IconButton className={classes.clearBtn} onClick={onDiscardChanges} size="large">
                                              <ClearIcon className="icon" fontSize="small" />
                                          </IconButton>

                                          <IconButton className={classes.okBtn} onClick={onCommitChanges} size="large">
                                              <CheckIcon className="icon" fontSize="small" />
                                          </IconButton>
                                      </InputAdornment>
                                  )
                              }
                            : null
                    }
                />
            </div>
            <div className="field flex light-grey-border">
                <ReadOnlyField label={"Original name"} text={dataset.name} />
            </div>
            <div className="field flex light-grey-border">
                <ReadOnlyField label={"Zoom"} text={zoom} />
                <ReadOnlyField label={"Rows"} text={dataset.rowCount} />
            </div>
            <div className="field flex light-grey-border">
                <ReadOnlyField label={"Size"} text={dataset.databaseSize} />
            </div>
            <div className="container">
                <div className="checkbox">
                    <Typography variant="subtitle1">Starts enabled</Typography>
                    <Checkbox style={{ marginLeft: -12 }} checked={layer.options.enabled} onChange={() => onStartsEnabledChanged(layer)} />
                </div>
                <div className="checkbox">
                    <Typography variant="subtitle1">Downloadable</Typography>
                    <Checkbox style={{ marginLeft: -12 }} checked={layer.options.downloadable} onChange={() => onDownloadableChanged(layer)} />
                </div>
            </div>
        </div>
    );
};

export default LayerEditView;

import React, { useMemo, useState } from "react";
import withStyles from "@mui/styles/withStyles";

import IconButton from "@mui/material/IconButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import Typography from "@mui/material/Typography";

import Layer from "./layer";
import GroupNameInput from "./groupNameInput";

import LayerGroup from "./group";
import { groupJss } from "./jss/groupJss";
import { Checkbox, Tooltip, Divider, Collapse } from "@mui/material";

import { isGroup } from "@emblautec/rescursive-array-extensions";
import { getLayerStyleErrorsMap, getSelectedGroupId } from "../../../../selectors/appData";
import { useDispatch, useSelector } from "react-redux";
import * as appDataActions from "../../../../reducers/appData/appData";
import { toggleGroupLayers } from "../../../../actions/globalActions";
import isNewDepthValid from "../../../../utils/layerGroups/isNewDepthValid";
import toastr from "../../../../components/CustomToastr/CustomToastr";
import layerGroupSettings from "../../../../utils/constants/layerGroupSettings";
import findDepth from "../../../../utils/layerGroups/findDepth";
import OverflowTip from "../../../../components/OverflowTip/OverflowTip";
import WarningIcon from "@mui/icons-material/WarningAmber";
import { useDragAndDropStyles } from "../../../../utils/styles/dragAndDrop";

const COLLAPSE_TIMEOUT = 200;

function Group({ classes, depth, group, vListGroupTools: { vListIndex, markIndexForRecomputation, recomputeRowHeights } }) {
    const dragAndDropClasses = useDragAndDropStyles();

    const [isBeingDragged, setIsBeingDragged] = useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [dragAboveMiddle, setDragAboveMiddle] = useState(null);
    const [eventTarget, setEventTarget] = useState(null);
    const dispatch = useDispatch();
    const selectedGroupId = useSelector(getSelectedGroupId);
    const layerStyleErrorsMap = useSelector(getLayerStyleErrorsMap);

    const groupContainsLayerWithStyleErrors = useMemo(() => {
        let containsLayerWithErrors = false;
        group.layers.forLayersRecursive((layer) => {
            if (layerStyleErrorsMap[layer.resourceId]) containsLayerWithErrors = true;
        });
        return containsLayerWithErrors;
    }, [layerStyleErrorsMap, group]);

    function onStartsCollapsedChanged() {
        let newOptions = { ...group.options };

        newOptions.collapsed = !newOptions.collapsed;

        dispatch(appDataActions.setResourceOptions({ resourceId: group.resourceId, newOptions }));
    }

    const onToggleAllLayers = (e) => {
        e.stopPropagation();
        const newVisibility = group.totalLayersCount !== group.visibleLayersCount;
        const appLayersMap = {};

        group.layers.forLayersRecursive((layer) => {
            appLayersMap[layer.resourceId] = true;
        });

        dispatch(toggleGroupLayers({ groupId: group.resourceId, newVisibility, appLayersMap }));
    };

    function onDragOver(event) {
        event.stopPropagation();

        if (isBeingDragged) {
            return false;
        }

        event.preventDefault();

        let rect = event.currentTarget.getBoundingClientRect();

        setDragAboveMiddle(event.clientY < rect.y + rect.height / 2);

        setDragOver(true);

        setEventTarget(event.target);
    }

    //Remove drop indicator while over drop box
    function onDragOverDropBox(event) {
        event.stopPropagation();
        event.preventDefault();
        setDragOver(false);
    }

    function onDragLeave(event) {
        event.stopPropagation();
        event.preventDefault();
        if (eventTarget && eventTarget === event.target) {
            setDragOver(false);
        }
    }

    function onDrop(event, asChild) {
        event.preventDefault();
        event.stopPropagation();
        const dropData = JSON.parse(event.dataTransfer.getData("text"));

        setDragOver(false);

        //You can't drop something on itself
        if (dropData.resourceId === group.resourceId) {
            return;
        }

        if (!isNewDepthValid(depth, dropData.depth, asChild)) {
            toastr.error(`You can't nest groups deeper than ${layerGroupSettings.MAX_DEPTH} levels`);
            return;
        }
        markIndexForRecomputation(vListIndex);
        markIndexForRecomputation(dropData.vListIndex);
        dispatch(appDataActions.moveResource({ resourceId: dropData.resourceId, destinationId: group.resourceId, moveAsChild: asChild, moveAbove: dragAboveMiddle }));
    }

    function onDragStart(e) {
        e.stopPropagation();
        const dropData = {
            resourceId: group.resourceId,
            type: "group",
            depth: findDepth(group.layers),
            vListIndex
        };
        e.dataTransfer.setData("text/plain", JSON.stringify(dropData));

        setIsBeingDragged(true);
    }

    function onDragEnd(e) {
        e.stopPropagation();
        setIsBeingDragged(false);
    }

    function onDeleteClick(e, group) {
        e.stopPropagation();
        const toastrConfirmOptions = {
            onOk: () => onDeleteGroup(e, group),
            onCancel: () => {}
        };
        toastr.confirm(`Are you sure you want to delete group: ${group.name}?`, toastrConfirmOptions);
    }

    function onDeleteGroup(e, group) {
        markIndexForRecomputation(vListIndex);
        dispatch(appDataActions.removeGroup(group.resourceId));
    }

    function onToggleOpen(e, group) {
        e.stopPropagation();
        dispatch(appDataActions.toggleGroupCollapse({ groupId: group.resourceId, newCollapseValue: !group.collapsed }));

        //This makes sure that virtualized list does not recalculate height until the group is collapsed
        setTimeout(
            () => {
                recomputeRowHeights(vListIndex);
            },
            group.collapsed ? 0 : COLLAPSE_TIMEOUT
        );
    }

    const onGroupSelected = (id) => {
        dispatch(appDataActions.setSelectedGroupId(id));
    };

    function onUpdateGroupName(groupName) {
        dispatch(appDataActions.setResourceName({ resourceId: group.resourceId, newName: groupName }));
        onGroupSelected(null);
    }

    function renderGroup(depth, selected) {
        if (depth === 0) {
            if (selected) {
                return (
                    <div className={selected ? "group selected" : "group"} onClick={() => onGroupSelected(group.resourceId)}>
                        <GroupNameInput value={group.name} onChange={onUpdateGroupName} />
                    </div>
                );
            }

            return (
                <div className={selected ? "group selected" : "group"} onClick={() => onGroupSelected(group.resourceId)}>
                    {groupContainsLayerWithStyleErrors && (
                        <Tooltip title="This group contains a layer with styling errors.">
                            <WarningIcon className={classes.iconSpaceRight} color="error" fontSize="small" />
                        </Tooltip>
                    )}
                    <OverflowTip variant="body1" color="inherit" className={depth === 0 ? classes.groupName : classes.subGroupName}>
                        {group.name}
                    </OverflowTip>
                    <IconButton onClick={(e) => onToggleAllLayers(e)} className={classes.squareBtn} size="large">
                        <Typography className={classes.layerCount}>
                            {group.visibleLayersCount}/{group.totalLayersCount}
                        </Typography>
                    </IconButton>
                    <div className={classes.layerCountSeperator}></div>
                    <IconButton className={classes.deleteBtn} onClick={(e) => onDeleteClick(e, group)} size="large">
                        <DeleteIcon className="icon" fontSize="small" />
                    </IconButton>
                    <Tooltip title={group.collapsed ? "Expand" : "Collapse"}>
                        <IconButton onClick={(e) => onToggleOpen(e, group)} className={classes.collapseBtn} size="large">
                            <ExpandLess className={group.collapsed ? classes.expandedRight : classes.collapsed} />
                        </IconButton>
                    </Tooltip>
                </div>
            );
        } else {
            if (selected) {
                return (
                    <div className={selected ? "sub-group selected" : "sub-group"} onClick={() => onGroupSelected(group.resourceId)}>
                        <GroupNameInput value={group.name} onChange={onUpdateGroupName} />
                    </div>
                );
            }

            return (
                <div className={selected ? "sub-group selected" : "sub-group"} onClick={() => onGroupSelected(group.resourceId)}>
                    <Tooltip title={group.collapsed ? "Expand" : "Collapse"}>
                        <IconButton onClick={(e) => onToggleOpen(e, group)} className={classes.innerCollapseBtn} size="large">
                            <ExpandLess className={group.collapsed ? classes.expandedLeft : classes.collapsed} />
                        </IconButton>
                    </Tooltip>
                    {groupContainsLayerWithStyleErrors && (
                        <Tooltip title="This group contains a layer with styling errors.">
                            <WarningIcon className={classes.iconSpaceRight} color="error" fontSize="small" />
                        </Tooltip>
                    )}
                    <OverflowTip variant="body1" color="inherit" className={depth === 0 ? classes.groupName : classes.subGroupName}>
                        {group.name}
                    </OverflowTip>
                    <IconButton onClick={(e) => onToggleAllLayers(e)} className={classes.squareBtn} size="large">
                        <Typography className={classes.layerCount}>
                            {group.visibleLayersCount}/{group.totalLayersCount}
                        </Typography>
                    </IconButton>
                    <div className={classes.layerCountSeperator}></div>
                    <IconButton className={classes.deleteBtn} onClick={(e) => onDeleteClick(e, group)} size="large">
                        <DeleteIcon className="icon" fontSize="small" />
                    </IconButton>
                </div>
            );
        }
    }

    const dragIndicatorClass = dragAboveMiddle ? dragAndDropClasses.dragIndicatorAbove : dragAndDropClasses.dragIndicatorBelow;
    const selected = selectedGroupId && group.resourceId === selectedGroupId;
    const groupElement = renderGroup(depth, selected);

    return (
        <div
            className={(depth === 0 ? classes.rootContainer : "") + " " + (dragOver ? dragIndicatorClass : "")}
            draggable="true"
            onDragOver={onDragOver}
            onDragStart={onDragStart}
            onDragEnter={onDragOver}
            onDragLeave={onDragLeave}
            onDragEnd={onDragEnd}
            onDrop={(e) => onDrop(e, false)}
            id={group.resourceId}
        >
            <div className={depth === 0 ? "group-root" : "sub-group-root"}>
                {groupElement}
                {group.layers.length === 0 && (
                    <div onDrop={(e) => onDrop(e, true)} onDragOver={onDragOverDropBox} className={depth === 0 ? classes.dropZone : classes.subDropzone}>
                        Drop inside
                    </div>
                )}

                <div className={depth === 0 ? classes.optionsMenu : classes.subOptionsMenu} style={{ height: selected ? 42 : 0, borderWidth: selected ? 1 : 0 }}>
                    <Checkbox style={{ marginLeft: -12 }} checked={group.options.collapsed} onChange={onStartsCollapsedChanged} />
                    <Typography variant="body2">Start collapsed</Typography>
                </div>

                <Collapse in={!group.collapsed && group.layers.length !== 0} timeout={COLLAPSE_TIMEOUT} unmountOnExit>
                    {depth === 0 && <Divider className={classes.groupSeperator} />}
                    <div className={depth === 0 ? "" : classes.borderBox}>
                        <div className={depth === 0 ? classes.layerContainer : classes.innerLayerContainer}>
                            {group.layers.map((layer) => {
                                return isGroup(layer) ? (
                                    <LayerGroup
                                        group={layer}
                                        depth={depth + 1}
                                        vListGroupTools={{ vListIndex, markIndexForRecomputation, recomputeRowHeights }}
                                        key={layer.resourceId}
                                    />
                                ) : (
                                    <Layer layer={layer} depth={depth + 1} vListLayerTools={{ vListIndex, markIndexForRecomputation }} key={layer.resourceId} />
                                );
                            })}
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    );
}

export default withStyles(groupJss)(Group);

import * as Yup from "yup";
import { metadataTypes } from "../constants/metadataTypes";
import { postgresReservedWords } from "../constants/postgresReservedWords";

export const maxDate = new Date("2099-12-31");
export const minDate = new Date("1900-01-01");

const metadataTypesValidators = {
    [metadataTypes.TEXT]: Yup.string().nullable(),
    [metadataTypes.DATE]: Yup.date()
        .typeError("Invalid date")
        .min(minDate, `Date should not be before ${minDate.toLocaleDateString()}`)
        .max(maxDate, `Date should not be after ${maxDate.toLocaleDateString()}`)
        .nullable(),
    [metadataTypes.TAG_LIST]: Yup.array(),
    [metadataTypes.NUMBER]: Yup.number().nullable().typeError("Field needs to be a number.")
};

const columnsValidator = Yup.array(Yup.object({ prettyName: Yup.string().required("Required") })) //In this part we validate that no names are empty
    .unique({ prettyName: "Column names must be unique" }, (metaTag) => metaTag.prettyName); //unique function def can be found in ./utilFunctions

export const datasetNameValidator = Yup.string().required("Required").min(2, "Dataset name is too short!").max(150, "Dataset name is too long!");

export const tableNameValidator = Yup.string()
    .required("Required")
    .max(63, "Table name is too long!")
    .test("Test that it isn't reserved word", "This is a reserved word", (val) => !postgresReservedWords.includes(val.toLowerCase()))
    .matches(/^[a-z_][a-z0-9_]*$/, "Can only contain a-z, 0-9 or _ and cannot start with a number");

export const DatasetEditViewSchema = (metadata) =>
    Yup.object().shape({
        columns: columnsValidator,
        datasetName: datasetNameValidator,
        tileName: Yup.string().required("Required").min(2, "Tile name is too short!").max(150, "Tile name is too long!"),
        //this is a dynamic schema because of the fact that the form is also dynamic
        ...metadata.reduce((acc, metadata) => {
            acc[metadata.name] = metadataTypesValidators[metadata.type];
            return acc;
        }, {})
    });

import makeStyles from '@mui/styles/makeStyles';

const useAddDataToolStyles = makeStyles((theme) => ({
    marginLeft: {
        marginLeft: 4
    },
    infoColor: {
        opacity: 0.5
    },
    errorInfoColor: {
        color: theme.palette.error.main,
        opacity: 0.8
    },
    countText: {
        fontSize: 17
    }
}));

export default useAddDataToolStyles;

import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";

import AddIcon from "@mui/icons-material/AddCircle";

import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import * as uploaderActions from "../../../actions/uploader";
import * as rasterService from "../../../actions/rasterService";
import { history } from "../../../store";
import toastr from "../../../components/CustomToastr/CustomToastr";

import RasterRow from "./components/RasterRow/RasterRow";

import { Button } from "@mui/material";
import rastersSorting from "./components/rastersSorting";
import TableView from "../../../components/TableView/TableView";

const styles = (theme) => ({
    title: {
        flexGrow: 1
    },
    icon: {
        marginRight: 8
    }
});

class RasterListView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: null
        };

        this.columns = rastersSorting;
    }

    componentDidMount() {
        this.props.getRasters();
    }

    onRasterClick = (item) => {
        this.setState({
            selected: item.id
        });
        history.push("/rasters/edit/" + item.id);
    };

    onAddRasterClick = () => {
        this.props.openUploader();
    };

    deleteRaster = (raster) => {
        this.props.deleteRaster(raster.id).then(
            (res) => {
                toastr.success("Raster deleted");
            },
            (err) => {
                toastr.error(err.message);
            }
        );
    };

    onDeleteRaster = (raster) => {
        const toastrConfirmOptions = {
            onOk: () => this.deleteRaster(raster),
            onCancel: () => {}
        };
        toastr.confirm("Delete Raster " + raster.name, toastrConfirmOptions);
    };

    generateItem = (item, index) => {
        return <RasterRow raster={item} key={index} onDelete={this.onDeleteRaster} onClick={this.onRasterClick} />;
    };

    filterItem(item, query) {
        return item.name.toLowerCase().includes(query.toLowerCase());
    }

    render() {
        let { classes } = this.props;

        return (
            <div className="sidebar-container raster-overview">
                <div className="header">
                    <Typography variant="h6" className={classes.title}>
                        Rasters
                    </Typography>
                    <Button variant="contained" color="primary" onClick={this.onAddRasterClick}>
                        <AddIcon className={classes.icon} fontSize="small" />
                        Add Dataset
                    </Button>
                </div>
                {this.props.fetching && <LinearProgress className="no-margin-progress" />}
                <TableView
                    searchPlaceholder={"Search Rasters by Name"}
                    data={this.props.rasters}
                    fetchingData={this.props.fetching}
                    columns={this.columns}
                    generateItem={this.generateItem}
                    filterFunction={this.filterItem}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        rasters: state.rasters.rasters,
        fetching: state.rasters.fetching
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        openUploader: () => dispatch(uploaderActions.open()),
        getRasters: () => dispatch(rasterService.getRasters()),
        deleteRaster: (rasterId) => dispatch(rasterService.deleteRaster(rasterId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RasterListView));

import { Tooltip } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/MoreHoriz";
import { useMenuButtonCellStyles } from "./styles";
import StickyCell from "../StickyCell/StickyCell";

const MenuButtonCell = ({ onMenuClick }) => {
    const classes = useMenuButtonCellStyles();
    return (
        <Tooltip id="tooltip-top" title="Options" placement="bottom">
            <StickyCell>
                <div className={classes.iconContainer}>
                    <div className={classes.buttonContainer}>
                        <MenuIcon onClick={onMenuClick} className={classes.menuIcon} />
                    </div>
                </div>
            </StickyCell>
        </Tooltip>
    );
};

export default MenuButtonCell;

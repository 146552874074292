import useCollapsibleCardStyles from "./styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider, Collapse, Box } from "@mui/material";

const CollapsibleCard = ({ innerPadding, maxHeight, paddingBottom, ...props }) => {
    const classes = useCollapsibleCardStyles();
    return (
        <div className={classes.collapsibleCard}>
            <div className={classes.collapsibleCardHeader} onClick={props.onToggleClick}>
                <div>{props.title}</div>
                <ExpandMoreIcon className={props.isToggled ? classes.expandedLeft : classes.collapsed} />
            </div>
            <Collapse in={props.isToggled} unmountOnExit={props.unmountOnExit}>
                <Divider className={classes.border} />
                <Box padding={innerPadding} paddingBottom={paddingBottom} maxHeight={maxHeight}>
                    <div className={classes.scrollContainer}>{props.children}</div>
                </Box>
            </Collapse>
        </div>
    );
};

CollapsibleCard.defaultProps = {
    //Decided to default this to 2 because this is quite common in the design
    innerPadding: 2, //these are spacing units, so a 1 would mean 8px
    paddingBottom: 2
};

export default CollapsibleCard;

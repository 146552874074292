import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import InfoIcon from "@mui/icons-material/Info";
import { Backdrop, Button, Dialog } from "@mui/material";
import Box from "@mui/material/Box";
import withStyles from "@mui/styles/withStyles";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as datasetsActions from "../../actions/datasets";
import * as rasterServiceActions from "../../actions/rasterService";
import * as uploaderActions from "../../actions/uploader";
import upload_icon_nobackground from "../../utils/icons/upload_icon_nobackground.svg";
import * as ValidationUtils from "../../utils/validationUtils";
import toastr from "../CustomToastr/CustomToastr";
import Typography from "../CustomTypography/CustomTypography";
import AfterUpload from "./components/AfterUpload/AfterUpload";
import BeforeUpload from "./components/BeforeUpload/BeforeUpload";
import CsvDialog from "./components/csvDialog";
import RasterUpload from "./components/rasterUpload";
import Upload from "./components/upload";

const styles = (theme) => ({
    warningProgress: {
        backgroundColor: theme.palette.warning.main
    },
    errorProgress: {
        backgroundColor: theme.palette.error.main
    },
    closeButton: {
        cursor: "pointer"
    }
});

class Uploader extends Component {
    state = {
        dragging: false,
        uploads: [],
        csvUploads: [],
        schemaNames: []
    };

    acceptedFormats = ".geojson,.csv,.zip,.mbtiles,.gpkg";
    uploadQueue = [];
    uploadQueuer = null;
    uploading = false;
    dragCounter = 0;

    componentDidMount() {
        this.uploadQueuer = setInterval(() => {
            if (!this.uploading && this.uploadQueue.length > 0) {
                this.uploading = true;
                let uploadJob = this.uploadQueue.shift();

                let uploadPromise = this.buildUpload(uploadJob, uploadJob.cancelTokenSource);
                uploadPromise.then(
                    (res) => {
                        this.uploading = false;
                        if (this.props.onUploadCompleted) {
                            this.props.onUploadCompleted(res.result);
                        }

                        this.setState({
                            uploads: this.state.uploads.map((item) => {
                                if (item.id === uploadJob.id) {
                                    return {
                                        ...item,
                                        status: "completed"
                                    };
                                }
                                return item;
                            })
                        });
                    },
                    (err) => {
                        this.uploading = false;

                        if (axios.isCancel(err)) {
                            this.onUploadFailed(uploadJob.id, "cancelled", null);
                        } else {
                            this.onUploadFailed(uploadJob.id, "failed", err.response.data.error);
                        }
                    }
                );
            }
        }, 500);
    }

    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) {
            this.setState({
                uploads: []
            });

            if (this.props.open) {
                this.props.getSchemaNames().then((res) => {
                    this.setState({
                        schemaNames: res.result
                    });
                });
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.uploadQueuer);
    }

    onUploadFailed(id, status, message) {
        this.setState({
            uploads: this.state.uploads.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        status: status,
                        progress: 100,
                        message: message,
                        cancelTokenSource: null
                    };
                }
                return item;
            })
        });
    }

    buildUpload(uploadJob, cancelTokenSource) {
        switch (uploadJob.type) {
            case "geojson":
                return this.buildGeojsonUpload(uploadJob, cancelTokenSource);
            case "memoryGeojson": //This covers the csv case
                return this.buildMemoryGeojsonUpload(uploadJob, cancelTokenSource);
            case "zip":
                return this.buildShapeZipUpload(uploadJob, cancelTokenSource);
            case "gpkg":
                return this.buildGeoPackageUpload(uploadJob, cancelTokenSource);
            case "kml":
                return this.buildKmlUpload(uploadJob, cancelTokenSource);
            case "mbtiles":
                return this.buildMbtilesUpload(uploadJob, cancelTokenSource);
            default:
                console.log("Invalid file type");
        }
    }

    buildMemoryGeojsonUpload(uploadJob, cancelTokenSource) {
        let formData = new FormData();
        let blob = new Blob([JSON.stringify(uploadJob.file)]);
        formData.append("file", blob, uploadJob.name + ".geojson");
        formData.append("name", uploadJob.name);
        formData.append("tableName", uploadJob.tableName);
        formData.append("schemaName", uploadJob.schemaName);
        formData.append("generateCache", true);
        let config = {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progress) => this.onUploadProgress(progress, uploadJob.id)
        };

        return this.props.createDataset(formData, config);
    }

    buildGeojsonUpload(uploadJob, cancelTokenSource) {
        let formData = new FormData();
        formData.append("file", uploadJob.file);
        formData.append("name", uploadJob.name);
        formData.append("tableName", uploadJob.tableName);
        formData.append("schemaName", uploadJob.schemaName);
        formData.append("generateCache", true);

        let config = {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progress) => this.onUploadProgress(progress, uploadJob.id)
        };

        return this.props.createDataset(formData, config);
    }

    buildKmlUpload(uploadJob, cancelTokenSource) {
        let formData = new FormData();
        formData.append("file", uploadJob.file);
        formData.append("name", uploadJob.name);
        formData.append("tableName", uploadJob.tableName);
        formData.append("schemaName", uploadJob.schemaName);
        formData.append("generateCache", true);

        let config = {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progress) => this.onUploadProgress(progress, uploadJob.id)
        };

        return this.props.createDatasetKml(formData, config);
    }

    buildShapeZipUpload(uploadJob, cancelTokenSource) {
        let formData = new FormData();
        formData.append("file", uploadJob.file);
        formData.append("name", uploadJob.name);
        formData.append("tableName", uploadJob.tableName);
        formData.append("schemaName", uploadJob.schemaName);
        formData.append("generateCache", true);

        let config = {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progress) => this.onUploadProgress(progress, uploadJob.id)
        };

        return this.props.createDatasetZip(formData, config);
    }

    buildGeoPackageUpload(uploadJob, cancelTokenSource) {
        let formData = new FormData();
        formData.append("file", uploadJob.file);
        formData.append("name", uploadJob.name);
        formData.append("tableName", uploadJob.tableName);
        formData.append("schemaName", uploadJob.schemaName);
        formData.append("generateCache", true);

        let config = {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progress) => this.onUploadProgress(progress, uploadJob.id)
        };

        return this.props.createDatasetGeoPackage(formData, config);
    }

    buildMbtilesUpload(uploadJob, cancelTokenSource) {
        let formData = new FormData();

        formData.append("file", uploadJob.file);
        formData.append("name", uploadJob.name);

        let config = {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progress) => this.onUploadProgress(progress, uploadJob.id)
        };

        return this.props.createRasterMbtiles(formData, config);
    }

    onFileDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;
        this.setState({
            dragging: true
        });
    };

    onFileDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter === 0)
            this.setState({
                dragging: false
            });
    };

    onHoverDone = (e) => {
        e.preventDefault();
        if (this.state.dragging) {
            this.dragCounter = 0;
            this.setState({
                dragging: false
            });
        }
    };

    onFileDrop = (e) => {
        e.preventDefault();
        this.dragCounter = 0;

        this.setState({
            dragging: false
        });

        this.handleFiles(e.dataTransfer.files);
    };

    onFileChanged = (e) => {
        let files = e.target.files;
        this.handleFiles(files);
    };

    isFileExtensionValid = (fileExtension) => {
        return this.acceptedFormats.split(",").includes("." + fileExtension);
    };

    handleFiles = (files) => {
        let newUploads = [];
        let newCsvUploads = [];

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let fileNameWithoutExtension = file.name.split(".")[0];
            let fileExtension = file.name.split(".").pop().toLowerCase();

            if (!this.isFileExtensionValid(fileExtension)) continue;

            let tableName = fileNameWithoutExtension.toLowerCase().replaceAll(" ", "_");

            switch (fileExtension) {
                case "csv":
                case "tsv":
                    newCsvUploads.push(file);
                    break;
                default:
                    newUploads.push({
                        name: fileNameWithoutExtension,
                        schemaName: this.state.schemaNames[0],
                        tableName: tableName,
                        nameError: false,
                        schemaNameError: false,
                        tableNameError: !ValidationUtils.validatePostgresIdentifier(tableName),
                        uploading: false,
                        id: uuidv4(),
                        file: file,
                        progress: 0,
                        status: "Queued",
                        type: fileExtension,
                        cancelTokenSource: null
                    });
                    break;
            }
        }
        document.getElementById("file-button").value = null;
        this.setState({
            csvUploads: [...this.state.csvUploads, ...newCsvUploads],
            uploads: [...this.state.uploads, ...newUploads]
        });
    };

    onQueueUpload = (upload) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let newUpload = {
            ...upload,
            cancelTokenSource: source
        };

        this.uploadQueue.push(newUpload);

        this.setState({
            uploads: this.state.uploads.map((item) => {
                if (item.id === upload.id) {
                    return {
                        ...item,
                        uploading: true,
                        cancelTokenSource: source
                    };
                }
                return item;
            })
        });
    };

    onQueueAllUploads = () => {
        const uploads = this.state.uploads;
        const newUploads = [];
        const uploadMap = {};
        const CancelToken = axios.CancelToken;
        uploads.forEach((upload) => {
            if (!upload.cancelTokenSource && this.validateInfo(upload)) {
                const source = CancelToken.source();
                uploadMap[upload.id] = upload;
                const updatedUpload = { ...upload, cancelTokenSource: source, uploading: true };
                this.uploadQueue.push(updatedUpload);
                newUploads.push(updatedUpload);
                return;
            }
            newUploads.push(upload);
        });

        this.setState({
            uploads: newUploads
        });
    };

    validateInfo(upload) {
        switch (upload.type) {
            case "geojson":
            case "memoryGeojson":
            case "zip":
            case "gpkg":
                return !upload.nameError && !upload.tableNameError && !upload.schemaNameError;
            case "mbtiles":
                return !upload.nameError;
            default:
                return false;
        }
    }

    onCancelUpload = (upload) => {
        if (upload.status === "uploading") {
            upload.cancelTokenSource.cancel();
        } else {
            this.setState({
                uploads: this.state.uploads.filter((x) => x.id !== upload.id)
            });
        }
    };

    onUploadProgress = (progressEvent, id) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.setState({
            uploads: this.state.uploads.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        progress: percentCompleted,
                        status: percentCompleted === 100 ? "processing" : "uploading"
                    };
                }
                return item;
            })
        });
    };

    onCancelPreUpload = (upload) => {
        this.setState({
            uploads: this.state.uploads.filter((x) => x.id !== upload.id)
        });
    };

    onUploadChanged = (upload) => {
        this.setState({
            uploads: this.state.uploads.map((item) => {
                if (item.id === upload.id) {
                    return upload;
                }
                return item;
            })
        });
    };

    onCloseModal = () => {
        if (this.uploading || this.uploadQueue.length > 0) {
            const toastrConfirmOptions = {
                onOk: () => this.props.closeUploader(),
                onCancel: () => {}
            };
            toastr.confirm("Are you sure you want to cancel uploads?", toastrConfirmOptions);
        } else {
            this.props.closeUploader();
        }
    };

    clearCsvUploads = () => {
        this.setState({ csvUploads: [] });
    };

    onCsvDialogAddGeojson = (file, geojson) => {
        let fileNameWithoutExtension = file.name.split(".")[0];
        let tableName = fileNameWithoutExtension.toLowerCase().replace(" ", "_");

        let newUpload = {
            name: fileNameWithoutExtension,
            schemaName: "data",
            tableName: tableName,
            nameError: false,
            schemaNameError: false,
            tableNameError: false,
            uploading: false,
            id: uuidv4(),
            file: geojson,
            progress: 0,
            status: "Queued",
            type: "memoryGeojson",
            cancelTokenSource: null
        };
        this.setState({
            uploads: [...this.state.uploads, newUpload],
            csvUploads: this.state.csvUploads.filter((x) => x.name !== file.name)
        });
    };

    render() {
        let { classes } = this.props;

        let uploads = this.state.uploads.map((upload, index) => {
            switch (upload.type) {
                case "geojson":
                case "gpkg":
                case "zip":
                case "memoryGeojson":
                    return (
                        <Upload
                            uploadStatus={upload}
                            schemaNames={this.state.schemaNames}
                            onCancel={() => this.onCancelPreUpload(upload)}
                            onCancelUpload={this.onCancelUpload}
                            onChange={this.onUploadChanged}
                            onUpload={this.onQueueUpload}
                            key={index}
                        />
                    );
                case "mbtiles":
                    return (
                        <RasterUpload
                            uploadStatus={upload}
                            onCancel={() => this.onCancelPreUpload(upload)}
                            onCancelUpload={this.onCancelUpload}
                            onChange={this.onUploadChanged}
                            onUpload={this.onQueueUpload}
                            key={index}
                        />
                    );
                default:
                    return null;
            }
        });

        return (
            <Dialog
                className="atlas-dialog uploader"
                open={this.props.open}
                onClose={this.onCloseModal}
                closeAfterTransition
                maxWidth={false}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <div className="dialog-header">
                    <Typography variant="h2" className="dialog-title">
                        Add Datasets
                    </Typography>

                    <CloseIcon color="inherit" className={classes.closeButton} onClick={this.onCloseModal} />
                </div>
                <div className="container">
                    <div className="page">
                        {uploads.length !== 0 && (
                            <div className="upper-container">
                                <Typography variant="body2" fontWeight="bold" className="datasets-nr">
                                    {uploads.length} Datasets
                                </Typography>
                                <Button variant="text" color="primary" disabled={uploads.length === 0} onClick={this.onQueueAllUploads}>
                                    <img className="icon" alt="" src={upload_icon_nobackground} />
                                    Upload All
                                </Button>
                            </div>
                        )}

                        {uploads.length === 0 ? (
                            <div className="upload-list">
                                <div className="no-uploads-indicator">
                                    <div className="list">
                                        <Box mb={4}>
                                            <Typography variant="subtitle1" fontWeight="bold" className={`${classes.uploadListSubtitle} ${classes.black}`} gutterBottom>
                                                Supported Vector Types:
                                            </Typography>
                                            <Typography variant="subtitle1" fontWeight="semibold">
                                                <DescriptionIcon className="icon" />
                                                Shapefile (ZIP archive containing all shapefile files)
                                            </Typography>
                                            <Typography variant="subtitle1" fontWeight="semibold">
                                                <DescriptionIcon className="icon gray" />
                                                CSV Files (comma, semi-colon or tab delimited)
                                            </Typography>
                                            <Typography variant="subtitle1" fontWeight="semibold">
                                                <DescriptionIcon className="icon light-gray" />
                                                GeoJSON (open standard format for simple geographical features)
                                            </Typography>
                                            <Typography variant="subtitle1" fontWeight="semibold">
                                                <DescriptionIcon className="icon light-gray" />
                                                GeoPackage (data format implemented as a SQLite database container)
                                            </Typography>
                                            <Typography variant="subtitle1" fontWeight="semibold">
                                                <InfoIcon className="icon" />
                                                Invalid column names will be formatted
                                            </Typography>
                                        </Box>
                                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                                            Supported Raster Types:
                                        </Typography>
                                        <Typography variant="subtitle1" fontWeight="semibold">
                                            <DescriptionIcon className="icon" />
                                            MBTiles (open standard based on sqlite)
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`upload-list ${this.state.dragging ? `dragging` : ``}`}
                                onDrop={this.onFileDrop}
                                onDragLeave={this.onFileDragLeave}
                                onDragOver={this.onFileDragOver}
                                onMouseLeave={this.onHoverDone}
                            >
                                {uploads}
                            </div>
                        )}

                        {uploads.length === 0 ? (
                            <BeforeUpload
                                uploads={uploads}
                                dragging={this.state.dragging}
                                acceptedFormats={this.acceptedFormats}
                                onFileDrop={this.onFileDrop}
                                onFileDragLeave={this.onFileDragLeave}
                                onFileDragOver={this.onFileDragOver}
                                onHoverDone={this.onHoverDone}
                                onQueueAllUploads={this.onQueueAllUploads}
                                onFileChanged={this.onFileChanged}
                            />
                        ) : (
                            <AfterUpload
                                dragging={this.state.dragging}
                                acceptedFormats={this.acceptedFormats}
                                onFileChanged={this.onFileChanged}
                                onCloseModal={this.onCloseModal}
                            />
                        )}
                    </div>
                </div>
                <CsvDialog
                    open={this.state.csvUploads.length > 0}
                    handleClose={this.clearCsvUploads}
                    file={this.state.csvUploads[0]}
                    onAddGeojson={this.onCsvDialogAddGeojson}
                ></CsvDialog>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.uploader.open
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeUploader: () => dispatch(uploaderActions.close()),
        createDataset: (formData, config) => dispatch(datasetsActions.createDataset(formData, config)),
        createDatasetKml: (formData, config) => dispatch(datasetsActions.createDatasetKml(formData, config)),
        createDatasetZip: (formData, config) => dispatch(datasetsActions.createDatasetZip(formData, config)),
        createDatasetGeoPackage: (formData, config) => dispatch(datasetsActions.createDatasetGeoPackage(formData, config)),
        createRasterMbtiles: (formData, config) => dispatch(rasterServiceActions.createRaster(formData, config)),
        getSchemaNames: () => dispatch(datasetsActions.getSchemaNames())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Uploader));

import { Checkbox, SvgIcon, TableRow } from "@mui/material";
import React from "react";
import OverflowCell from "../../../../../../../components/CustomTable/components/customCells/OverflowCell/OverflowCell";
import TooltipCell from "../../../../../../../components/CustomTable/components/customCells/TooltipCell/TooltipCell";
import StickyCell from "../../../../../../../components/CustomTable/components/customCells/StickyCell/StickyCell";

import NameCell from "../../../../../../../components/CustomTable/components/customCells/NameCell/NameCell";
import { CELL_SIZES } from "../../../../../../../utils/constants/cellSizes";
import * as EnumUtils from "../../../../../../../utils/enumUtils";
import { getGeometryIcon } from "../../../../../../../utils/datasets/getGeometryIcon";
import { cacheStateColor } from "../../../../../../../utils/datasets/cacheStateColor";

const DatasetRow = ({ dataset, disabled, ...props }) => {
    const onToggle = () => {
        props.onToggle(dataset);
    };

    const cacheStatusToClass = (cacheStatus) => {
        return EnumUtils.toCacheStatusString(cacheStatus);
    };

    const cacheStatus = cacheStatusToClass(dataset.cacheStatus);

    return (
        <TableRow>
            <TooltipCell tooltipTitle={cacheStatusToClass(dataset.cacheStatus) + " " + dataset.geometryType}>
                <SvgIcon color={cacheStateColor[cacheStatus]}>{getGeometryIcon(dataset.geometryType)}</SvgIcon>
            </TooltipCell>
            <OverflowCell cellSize={CELL_SIZES.small}>{dataset.schemaName?.replace(/_/g, " ")}</OverflowCell>
            <NameCell>{dataset.name}</NameCell>
            <StickyCell>
                <Checkbox disabled={disabled} checked={dataset.included} onChange={onToggle} />
            </StickyCell>
        </TableRow>
    );
};

export default DatasetRow;

export const layerJss = (theme) => ({
    contextMenuBtn: {
        borderRadius: 3,
        height: 24,
        width: 24,
        backgroundColor: "#e3e4e5",
        color: "black"
    },
    grow: {
        flexGrow: 1
    },
    grayIcon: {
        color: "rgba(0, 0, 0, 0.54)",
        paddingLeft: 5
    },
    switch: {
        marginLeft: -12,
        marginRight: 8
    },
    iconSpaceRight: { marginRight: 8 },
    layerText: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        cursor: "pointer",
        textTransform: "capitalize",
        flexGrow: 1,
        fontSize: 14,
        "&:hover": {
            color: theme.palette.primary.main
        }
    },
    zoomIndicator: {
        padding: 8,
        backgroundColor: "#004b76",
        color: "white",
        fontSize: 12
    },
    depthZeroPadding: {
        padding: "8px 0",
        margin: "4px 20px"
    }
});

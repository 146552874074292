import { createAsyncThunk } from "@reduxjs/toolkit";
import { addAppLayer, removeAppLayer } from "../reducers/appData/appData";
import { addMapLayer, addMapLayout, addMapPaint, addMapSource, addMapZoomRange, removeMapSource, removeResourceMapLayers, setMapSourceUncached } from "../reducers/map";
import * as types from "./actionTypes";
import axiosClient from "./apiClient";
import { v4 as uuidv4 } from "uuid";
import * as GeometryUtils from "../utils/GeometryUtils";

export const getApps = () => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_APPS });

        return axiosClient
            .get("application")
            .then((res) => {
                return dispatch({ type: types.FETCH_APPS_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.APP_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const getApp = createAsyncThunk("getApp", async ({ applicationId, cancelToken }, { dispatch }) => {
    return axiosClient
        .get(`application/${applicationId}`, { cancelToken })
        .then((res) => res.data)
        .catch((err) => {
            dispatch({ type: types.APP_ACTION_FAILED, result: err });
            throw err;
        });
});

export const createApp = (request) => {
    return (dispatch) => {
        dispatch({ type: types.CREATE_APP });

        return axiosClient
            .post(`application`, request)
            .then((res) => {
                return dispatch({ type: types.CREATE_APP_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.APP_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const updateApp = (applicationId, request) => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_APP });

        return axiosClient
            .put(`application/${applicationId}`, request)
            .then((res) => {
                return dispatch({ type: types.UPDATE_APP_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.APP_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const deleteApp = (applicationId) => {
    return (dispatch) => {
        dispatch({ type: types.DELETE_APP });

        return axiosClient
            .delete(`application/${applicationId}`)
            .then(() => {
                return dispatch({ type: types.DELETE_APP_COMPLETE, result: { id: applicationId } });
            })
            .catch((err) => {
                dispatch({ type: types.APP_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const publishApp = (applicationId) => {
    return (dispatch) => {
        return axiosClient
            .post(`application/${applicationId}/publish`)
            .then(() => ({ result: { id: applicationId } }))
            .catch((err) => {
                dispatch({ type: types.APP_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const addMapToApp = (applicationId, mapId) => {
    return (dispatch) => {
        return axiosClient
            .post(`application/${applicationId}/map/${mapId}`)
            .then(() => ({ result: { id: applicationId } }))
            .catch((err) => {
                dispatch({ type: types.APP_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const addDatasetToAppThunk = createAsyncThunk("addDatasetToApp", async ({ appId, dataset }, { dispatch, getState, rejectWithValue }) => {
    const res = await axiosClient.post(`application/${appId}/dataset/${dataset.id}`).catch((err) => rejectWithValue(err));

    const styleConfig = getState().style.config;

    const sourceId = res.data.id;
    const layerType = GeometryUtils.geometryTypeToMapboxType(dataset.geometryType);
    const properties = styleConfig[layerType];
    const minZoom = dataset.minZoom;
    const maxZoom = 24;
    const style = {
        styleId: uuidv4(),
        properties,
        minZoom,
        maxZoom,
        type: layerType
    };
    const layer = {
        name: dataset.name,
        sourceId,
        sourceName: dataset.tileName,
        resourceId: dataset.id,
        type: "vector",
        geometryType: dataset.geometryType,
        bounds: dataset.bounds,
        options: {
            enabled: false,
            downloadable: true
        },
        styles: [style],
        visible: true
    };
    dispatch(
        addMapSource({
            id: res.data.id,
            minZoom: res.data.minZoom,
            maxZoom: res.data.maxZoom,
            type: "vector",
            cacheStatus: res.data.cacheStatus,
            isMap: true
        })
    ); //Initially add the dataset as a layer from its own tiles, when the app is reloaded it will come from the map

    dispatch(
        addMapSource({
            id: dataset.id,
            minZoom: dataset.minZoom,
            maxZoom: dataset.maxZoom,
            type: "vector",
            isMap: false
        })
    );
    dispatch(
        addMapLayer({
            sourceId: dataset.id,
            layerId: style.styleId,
            resourceId: dataset.id,
            sourceName: dataset.tileName,
            type: style.type,
            minZoom,
            maxZoom
        })
    );

    const paintProperties = properties.filter((x) => x.type === "paint");
    const paint = {
        layerId: style.styleId,
        properties: paintProperties
    };
    const layoutProperties = properties.filter((x) => x.type === "layout");
    layoutProperties.push({
        type: "layout",
        name: "visibility",
        value: "visible"
    });
    const layout = {
        layerId: style.styleId,
        properties: layoutProperties
    };
    dispatch(addMapPaint(paint));
    dispatch(addMapLayout(layout));

    dispatch(
        addMapZoomRange({
            layerId: style.styleId,
            minZoom,
            maxZoom
        })
    );
    dispatch(addAppLayer({ newLayer: layer, styles: [style] }));
});

export const removeDatasetFromAppThunk = createAsyncThunk("removeDatasetFromApp", async ({ appId, datasetId }, { dispatch, getState, rejectWithValue }) => {
    dispatch(removeAppLayer(datasetId));
    dispatch(removeResourceMapLayers(datasetId));

    const { mapId, mapStatus } = await axiosClient
        .delete(`application/${appId}/dataset/${datasetId}`)
        .then((res) => res.data)
        .catch((err) => {
            rejectWithValue(err);
        });

    if (mapStatus === 1) {
        dispatch(removeMapSource(mapId));
    } else {
        dispatch(setMapSourceUncached(mapId));
    }
});

export const addRasterToAppThunk = createAsyncThunk("addRasterToApp", async ({ appId, raster }, { dispatch, getState, rejectWithValue }) => {
    await axiosClient.post(`application/${appId}/raster/${raster.id}`).catch((err) => rejectWithValue(err));

    const styleConfig = getState().style.config;
    const properties = styleConfig["raster"];

    const style = {
        styleId: uuidv4(),
        properties,
        minZoom: raster.minZoom,
        maxZoom: 24,
        type: "raster"
    };
    const layer = {
        name: raster.name,
        sourceId: raster.id,
        sourceName: raster.name,
        resourceId: raster.id,
        type: "raster",
        geometryType: "raster",
        bounds: raster.bounds,
        options: {
            enabled: false
        },
        visible: true
    };
    dispatch(
        addMapSource({
            id: raster.id,
            minZoom: raster.minZoom,
            maxZoom: raster.maxZoom,
            type: "raster"
        })
    );
    dispatch(
        addMapLayer({
            sourceId: raster.id,
            layerId: style.styleId,
            resourceId: raster.id,
            sourceName: raster.name,
            type: style.type,
            minZoom: style.minZoom,
            maxZoom: style.maxZoom
        })
    );
    const layout = {
        layerId: style.styleId,
        properties: [
            {
                name: "visibility",
                value: "visible"
            }
        ]
    };
    dispatch(addMapLayout(layout));
    dispatch(addAppLayer({ newLayer: layer, styles: [style] }));
    dispatch(
        addMapZoomRange({
            layerId: raster.id,
            minZoom: 0,
            maxZoom: 24
        })
    );
});

export const removeRasterFromAppThunk = createAsyncThunk("removeRasterFromApp", async ({ appId, rasterId }, { dispatch, getState, rejectWithValue }) => {
    dispatch(removeAppLayer(rasterId));
    dispatch(removeResourceMapLayers(rasterId));

    await axiosClient.delete(`application/${appId}/raster/${rasterId}`).catch((err) => {
        rejectWithValue(err);
    });
});

export function generateCache(appId) {
    return (dispatch) => {
        dispatch({ type: types.GENERATE_APP_CACHE });

        return axiosClient.post("application/" + appId + "/generate").catch((_) => dispatch({ type: types.GENERATE_APP_CACHE_FAILED }));
    };
}

export const panelToggle = () => ({ type: types.PANEL_TOGGLE });

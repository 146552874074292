const sortByName = (user1, user2) => {
    return user1.username.localeCompare(user2.username);
};

const sortByNameReverse = (user1, user2) => {
    return user2.username.localeCompare(user1.username);
};

const sortByRoles = (user1, user2) => {
    return user1.roles.localeCompare(user2.roles);
};

const sortByRolesReverse = (user1, user2) => {
    return user2.roles.localeCompare(user1.roles);
};

const usersSorting = [
    {
        type: "name",
        name: "username",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByNameReverse : sortByName),
        start: true
    },
    {
        type: "roles",
        name: "role",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByRolesReverse : sortByRoles)
    }
];

export default usersSorting;

import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import * as schemaService from "../../../../actions/schemaService";
import ListView from "../../../../components/listView";

import toastr from "../../../../components/CustomToastr/CustomToastr";
import * as NetworkErrorUtils from "../../../../utils/networkErrorUtils";
import Schema from "./schema";

const styles = () => ({});

class EditSchemasView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            newSchemaName: "",
            nameError: false
        };

        this.columns = [
            {
                type: "name",
                name: "schema name",
                sortingFunction: (sortingReverse) => (sortingReverse ? this.sortByNameReverse : this.sortByName),
                start: true
            }
        ];
    }

    sortByName(schema1, schema2) {
        return schema1.localeCompare(schema2);
    }

    sortByNameReverse(schema1, schema2) {
        return schema2.localeCompare(schema1);
    }

    componentDidMount() {
        this.props.getSchemas();
    }

    onDeleteSchema = (schema) => {
        this.props.deleteSchema(schema).then(
            (res) => {
                toastr.success("Schema deleted");
                this.props.setDatabaseSchema(this.props.schemas);
            },
            (err) => NetworkErrorUtils.handleError(err)
        );
    };

    onSelect = () => {};

    generateItem = (schema, index) => {
        return <Schema schema={schema} selected={schema === this.state.selected} key={index} onSelect={this.onSelect} onDelete={this.onDeleteSchema} />;
    };

    filterItem(item, query) {
        return item.toLowerCase().includes(query.toLowerCase());
    }

    render() {
        return (
            <div className="setting-edit-view schemas">
                <div className="schema-container">
                    <ListView
                        searchPlaceholder={"Search schemas"}
                        data={this.props.schemas}
                        columns={this.columns}
                        generateItem={this.generateItem}
                        filterFunction={this.filterItem}
                    ></ListView>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        schemas: state.schemas.schemas
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSchemas: () => dispatch(schemaService.getSchemas()),
        deleteSchema: (schemaName) => dispatch(schemaService.deleteSchema(schemaName))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditSchemasView));

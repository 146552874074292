import React from "react";
import OverflowCell from "../OverflowCell/OverflowCell";
import styles from "../../../../../scss/themeVariables.module.scss";
import { useNameCellStyles } from "./styles";
import { CELL_SIZES } from "../../../../../utils/constants/cellSizes";

const NameCell = ({ children, size }) => {
    const classes = useNameCellStyles();

    return (
        <OverflowCell cellSize={size} color={styles.primaryColor} className={classes.nameCell}>
            {children}
        </OverflowCell>
    );
};

NameCell.defaultProps = {
    size: CELL_SIZES.medium
};

export default NameCell;

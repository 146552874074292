import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddCircle";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import CreateModal from "./createAppModal";

import * as appActions from "../../../actions/apps";

import { history } from "../../../store";
import toastr from "../../../components/CustomToastr/CustomToastr";

import appSorting from "./components/appSorting";
import AppRow from "./components/AppRow/AppRow";
import TableView from "../../../components/TableView/TableView";

const styles = () => ({});

class AppsListView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modelOpen: false,
            sorting: "name",
            sortingReverse: false,
            rowsPerPage: 10,
            page: 0
        };

        this.columns = appSorting;
    }

    componentDidMount() {
        this.props.getApps();
    }

    componentWillUnmount() {}

    onEditClick = (item) => {
        history.push("/applications/edit/" + item.id);
    };

    onClickDeleteApp(item) {
        const toastrConfirmOptions = {
            onOk: () => this.deleteApp(item),
            onCancel: () => {}
        };
        toastr.confirm("Delete application " + item.name, toastrConfirmOptions);
    }

    onClickAddApp = () => {
        this.setState({
            modelOpen: true
        });
    };

    onModelClose = () => {
        this.setState({
            modelOpen: false
        });
    };

    deleteApp(item) {
        this.props
            .deleteApp(item.id)
            .then(() => {
                toastr.success("App deleted");
            })
            .catch((err) => {
                toastr.error(err.message);
            });
    }

    onSetSort = (sort) => {
        this.setState({
            sorting: sort,
            sortingReverse: this.state.sorting === sort ? !this.state.sortingReverse : false
        });
    };

    onPageChange = (event, page) => {
        this.setState({
            page: page
        });
    };

    onChangeRowsPerPage = (e) => {
        this.setState({
            rowsPerPage: e.target.value
        });
    };

    generateItem = (app, index) => {
        return <AppRow key={index} app={app} onClick={() => this.onEditClick(app)} onEditClick={() => this.onEditClick(app)} onDeleteClick={() => this.onClickDeleteApp(app)} />;
    };

    filterItem(item, query) {
        return item.name.toLowerCase().includes(query.toLowerCase());
    }

    render() {
        return (
            <div className="sidebar-container apps-overview">
                <CreateModal open={this.state.modelOpen} handleClose={this.onModelClose} />
                <div className="header">
                    <Typography variant="h2" className="subtitle">
                        Applications
                    </Typography>
                    <Button color="primary" variant="contained" onClick={this.onClickAddApp}>
                        <AddIcon className="button-icon" fontSize="small" />
                        Add App
                    </Button>
                </div>
                {this.props.fetching && <LinearProgress className="no-margin-progress" />}
                <TableView
                    searchPlaceholder={"Search apps"}
                    data={this.props.apps}
                    fetchingData={this.props.fetching}
                    columns={this.columns}
                    generateItem={this.generateItem}
                    filterFunction={this.filterItem}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        apps: state.apps.apps,
        fetching: state.apps.fetching
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getApps: () => dispatch(appActions.getApps()),
        deleteApp: (appId) => dispatch(appActions.deleteApp(appId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppsListView));

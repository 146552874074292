import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import useIfMounted from "../../../../../utils/customHooks/useIfMounted";

const UserMembership = ({ user, userMembershipChangePromise }) => {
    const [updatingMembership, setUpdatingMembership] = useState(false);
    const execIfMounted = useIfMounted();

    const handleUserMembershipChange = () => {
        setUpdatingMembership(true);
        userMembershipChangePromise(user).finally(() => execIfMounted(() => setUpdatingMembership(false)));
    };

    return (
        <div className="resourcePermissionItem">
            <div className="name">{user.username}</div>
            <div className="checkbox-container">
                <div className="radio-label">Member</div>
                <Checkbox disabled={updatingMembership} checked={user.isMember} onChange={handleUserMembershipChange} />
            </div>
        </div>
    );
};

export default UserMembership;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SettingsIcon from "@mui/icons-material/Settings";
import { LinearProgress } from "@mui/material";
import LayerEditView from "./components/LayerEditView/LayerEditView";
import AppSettingsView from "./components/appSettingsView";
import AddDataTool from "./components/AddDataTool/addDataTool";
import { StyledTabs, StyledTab } from "../../../components/CustomTabs/CustomTabs";
import AppEditHeader from "./components/AppEditHeader/AppEditHeader";
import AppEditFooter from "./components/AppEditFooter/AppEditFooter";
import { setSelectedTool } from "../../../reducers/appData/appData";
import { getFetching, getLayerGroups, getSelectedGroupId, getSelectedLayer, getSelectedTool } from "../../../selectors/appData";
import VirtualizedLayerGroups from "./components/VirtualizedLayerGroups/VirtualizedLayerGroups";
import { useParams } from "react-router-dom";
import { getIsPanelClosed } from "../../../selectors/apps";
import { isGroup } from "@emblautec/rescursive-array-extensions";
import { useAppInit } from "./logic/useAppInit";
import { APP_EDIT_TOOLS } from "../../../utils/constants/appEditTools";
import ActionsSection from "./components/ActionsSection/ActionsSection";

const PAGES = {
    layerGroups: "layerGroups",
    settings: "settings"
};

const AppEditView = () => {
    const { appId } = useParams();

    const [page, setPage] = useState(PAGES.layerGroups);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState("");

    const fetching = useSelector(getFetching);
    const layerGroups = useSelector(getLayerGroups);
    const selectedLayer = useSelector(getSelectedLayer);
    const selectedTool = useSelector(getSelectedTool);
    const isPanelClosed = useSelector(getIsPanelClosed);
    const selectedGroupId = useSelector(getSelectedGroupId);

    const dispatch = useDispatch();

    const { loading, initApp } = useAppInit();

    useEffect(() => {
        const clearApp = initApp(appId);
        return () => clearApp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isPanelClosed) {
            dispatch(setSelectedTool(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPanelClosed]);

    useEffect(() => {
        setFilteredData(filterLayer(searchText));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layerGroups]);

    const changeTab = (_, newTab) => {
        setPage(newTab);
    };

    const onCloseTool = () => {
        dispatch(setSelectedTool(null));
    };

    const cleanEmptySubGroups = (group) => {
        //This function shouldn't modify the original object
        const mappedData = [];
        group.layers.forEach((layer) => {
            if (isGroup(layer)) {
                const cleanedGroup = cleanEmptySubGroups(layer);
                if (cleanedGroup.layers.length > 0) {
                    mappedData.push(cleanedGroup);
                }
            } else mappedData.push(layer);
        });
        return { ...group, layers: mappedData };
    };

    const filterLayer = (newValue) => {
        if (newValue === "") return layerGroups;
        let filteredData = layerGroups.filterLayersRecursive((layer) => layer.name.toLowerCase().includes(newValue.toLowerCase()));
        filteredData = cleanEmptySubGroups({ layers: filteredData });
        return filteredData.layers;
    };

    const onSearchChange = (newValue) => {
        const filteredData = filterLayer(newValue);
        setFilteredData(filteredData);
        setSearchText(newValue);
    };

    return (
        <div className="sidebar-container edit-app-view">
            {selectedLayer !== null && <LayerEditView />}
            <div className="header">
                <AppEditHeader />
            </div>
            {(fetching || loading) && <LinearProgress className="no-margin-progress" />}
            <div className="content">
                <div className="layer-groups">
                    <StyledTabs value={page} TabIndicatorProps={<div />} onChange={changeTab}>
                        <StyledTab label="Layer Groups" icon={<FormatListBulletedIcon fontSize="small" />} iconPosition="start" value={PAGES.layerGroups} />
                        <StyledTab label="Settings" icon={<SettingsIcon fontSize="small" />} iconPosition="start" value={PAGES.settings} />
                    </StyledTabs>
                    <div className={page === PAGES.layerGroups ? "list-container" : "hidden"}>
                        <ActionsSection onSearchChange={onSearchChange} searchText={searchText} />
                        <div className="list">
                            <div className="item-container">
                                <VirtualizedLayerGroups layerGroups={filteredData} selectedGroupId={selectedGroupId} />
                            </div>
                        </div>
                    </div>
                    {page === PAGES.settings && <AppSettingsView />}
                    <div className="bottom-container">
                        <AppEditFooter />
                    </div>
                </div>
            </div>
            {selectedTool === APP_EDIT_TOOLS.addData && (
                <div className="tools">
                    <AddDataTool disabled={fetching} onClose={onCloseTool} disableCheckbox={fetching} />
                </div>
            )}
        </div>
    );
};

export default AppEditView;

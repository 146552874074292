import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

import * as UserServiceActions from "../../../../actions/userService";
import toastr from "../../../../components/CustomToastr/CustomToastr";
import { Radio, Tooltip } from "@mui/material";
import { handleError } from "../../../../utils/networkErrorUtils";
import CustomModal from "../../../../components/CustomModal/CustomModal";

const styles = () => ({});

class CreateUserModal extends Component {
    state = {
        username: "",
        password: "",
        roles: [
            {
                name: "admin",
                checked: false
            },
            {
                name: "user",
                checked: false
            },
            {
                name: "uploader",
                checked: false
            }
        ],
        selectedRole: "user",
        validated: { username: true, password: false, roles: true }
    };

    componentDidMount() {}

    changeUsername = (e) => {
        // eslint-disable-next-line no-useless-escape
        let usernameValid = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(e.target.value);

        this.setState({
            username: e.target.value,
            validated: {
                ...this.state.validated,
                username: usernameValid
            }
        });
    };

    changePassword = (e) => {
        // eslint-disable-next-line no-useless-escape
        let passwordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(e.target.value);
        this.setState({
            password: e.target.value,
            validated: {
                ...this.state.validated,
                password: passwordValid
            }
        });
    };

    onRoleChange(prop) {
        this.setState({
            selectedRole: prop
        });
    }

    validateInfo() {
        // eslint-disable-next-line no-useless-escape
        let usernameValid = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.username);
        // eslint-disable-next-line no-useless-escape
        let passwordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(this.state.password);

        this.setState({
            validated: {
                username: usernameValid,
                password: passwordValid
            }
        });

        return usernameValid && passwordValid;
    }

    createUser = () => {
        if (!this.validateInfo()) {
            return;
        }

        let request = {
            username: this.state.username,
            password: this.state.password,
            role: this.state.selectedRole
        };

        this.setState({
            disabled: true
        });

        this.props.createUser(request).then(
            () => {
                this.props.handleClose();
                this.setState({
                    username: "",
                    password: "",
                    disabled: false
                });
                toastr.success("User created");
            },
            (err) => {
                handleError(err);
                this.setState({
                    disabled: false
                });
            }
        );
    };

    render() {
        let { props } = this;
        return (
            <div>
                <CustomModal
                    handleClose={props.handleClose}
                    isOpen={props.open}
                    onConfirm={this.createUser}
                    dialogTitle={"Create User"}
                    dialogType={"create"}
                    disabled={this.state.disabled}
                >
                    <div className="field">
                        {this.state.roles.map((role) => {
                            return (
                                <FormControlLabel
                                    control={<Radio checked={this.state.selectedRole === role.name} onChange={() => this.onRoleChange(role.name)} />}
                                    label={role.name}
                                    key={role.name}
                                />
                            );
                        })}
                    </div>
                    <div className="field">
                        <TextField
                            id="filled-required"
                            placeholder="email"
                            inputProps={{ autoComplete: "off" }}
                            value={this.state.username}
                            onChange={this.changeUsername}
                            type="email"
                            fullWidth
                            error={!this.state.validated.username}
                            variant="filled"
                            label="Email"
                            style={{ marginBottom: 32 }}
                        />
                    </div>
                    <div className="field">
                        <Tooltip
                            title={
                                <div>
                                    <div>8 Characters in length</div>
                                    <div>1 Special characters (!@#$%^&*)</div>
                                    <div>1 Lower case (a-z)</div>
                                    <div>1 Upper case (A-Z)</div>
                                    <div>1 Number (0-9)</div>
                                </div>
                            }
                        >
                            <TextField
                                id="filled-required"
                                placeholder="password"
                                inputProps={{ autoComplete: "no" }}
                                value={this.state.password}
                                onChange={this.changePassword}
                                fullWidth
                                error={!this.state.validated.password}
                                type="password"
                                variant="filled"
                                label="Password"
                            />
                        </Tooltip>
                    </div>
                </CustomModal>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        createUser: (createUserRequest) => dispatch(UserServiceActions.createUser(createUserRequest))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateUserModal));

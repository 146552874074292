import * as types from "../actions/actionTypes";

const metadataSchemaInit = {
    fetching: false,
    schema: {}
};

export function metadataSchemaReducer(state = metadataSchemaInit, action) {
    switch (action.type) {
        case types.FETCH_METADATA_SCHEMA:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_METADATA_SCHEMA_COMPLETE:
            return {
                ...state,
                fetching: false,
                schema: action.result.jsonData
            };
        default:
            return state;
    }
}

import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import * as types from "../actions/actionTypes";
import Config from "../config";
import { AUTHENTICATED } from "../actions/actionTypes";

let connection;

export default function signalRMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        if (action.type === AUTHENTICATED) {
            if (connection) {
                connection.off("tileGenerationProgress");
                connection.off("tileGenerationError");

                return connection.stop().then(() => {
                    connection = null;
                    initHub(action.result, dispatch);
                });
            } else {
                initHub(action.result, dispatch);
            }
        }

        return next(action);
    };
}

function initHub(accessToken, dispatch) {
    let logLevel = process.env.NODE_ENV === "production" ? LogLevel.None : LogLevel.Information;

    connection = new HubConnectionBuilder()
        .configureLogging(logLevel)
        .withAutomaticReconnect()
        .withUrl(Config.apiUrl + "progresshub", { accessTokenFactory: () => accessToken })
        .build();

    connection.on("tileGenerationProgress", (data) => {
        dispatch({ type: types.SIGNALR_PROGRESS_UPDATE, result: data });
    });
    connection.on("tileGenerationError", (data) => {
        dispatch({ type: types.SIGNALR_ERROR_UPDATE, result: data });
    });

    connection.start();
}

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { lighten } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Cached";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import LinearProgress from "@mui/material/LinearProgress";
import dayjs from "dayjs";
import { Tooltip } from "@mui/material";

function createData(object, index) {
    return { id: index, ...object };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
    return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
    createSortHandler = (property) => (event) => {
        this.props.onRequestSort(event, property);
    };

    constructor(props) {
        super(props);

        this.state = {
            rows: props.rows
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rows !== this.props.rows) {
            this.setState({
                rows: this.props.rows
            });
        }
    }

    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox indeterminate={numSelected > 0 && numSelected < rowCount} checked={numSelected === rowCount && rowCount > 0} onChange={onSelectAllClick} />
                    </TableCell>
                    {this.state.rows.map(
                        (row) => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? "right" : "left"}
                                padding={row.disablePadding ? "none" : "default"}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip title="Sort" placement={row.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
                                    <TableSortLabel active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)}>
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this
                    )}
                    <TableCell>Options</TableCell>
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing.unit,
        backgroundColor: theme.palette.primary.main
    },
    highlight:
        theme.palette.mode === "light"
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85)
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark
              },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    },
    titleText: {
        color: "white"
    },
    flex: {
        display: "flex"
    },
    action: {
        color: "white"
    }
});

let EnhancedTableToolbar = (props) => {
    const { numSelected, classes, title, onAddClick, onRefreshClick, onDeleteClick, onFilterClick } = props;

    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography variant="h6" className={classes.titleText} id="tableTitle">
                        {title}
                    </Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete" onClick={onDeleteClick} size="large">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <div className={classes.flex}>
                        <Tooltip title="Add">
                            <IconButton aria-label="Add" className={classes.action} onClick={onAddClick} size="large">
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Refresh">
                            <IconButton aria-label="Refresh" className={classes.action} onClick={onRefreshClick} size="large">
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Filter list">
                            <IconButton aria-label="Filter list" className={classes.action} onClick={onFilterClick} size="large">
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background
    },
    table: {
        minWidth: 1020
    },
    tableWrapper: {
        overflowX: "auto",
        paddingRight: 16,
        paddingLeft: 16,
        maxHeight: "70vh"
    },
    RunBtn: {
        marginRight: "8px"
    },
    optionsColumn: {
        width: "200px"
    },
    checkboxCell: {
        cursor: "pointer"
    }
});

class EnhancedTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: props.title,
            order: "asc",
            orderBy: "calories",
            selected: [],
            rows: props.rows,
            page: 0,
            rowsPerPage: 10
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rows !== this.props.rows) {
            this.setState({
                rows: this.props.rows,
                page: 0
            });
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            this.setState((state) => ({ selected: this.props.data.map((n) => n.id) }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        event.stopPropagation();

        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        this.setState({ selected: newSelected });
    };

    handleEditClick = (e, id) => {
        e.stopPropagation();
        this.props.onEditClick(id);
    };

    handleRunClick = (e, id) => {
        e.stopPropagation();
        this.props.onRunClick(id);
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = (id) => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes, onItemClick, onEditClick, onRunClick, onAddClick, onRefreshClick, onDeleteClick, onFilterClick } = this.props;
        const { order, orderBy, selected, rowsPerPage, page, rows, title } = this.state;

        let data = this.props.data.map((x, index) => createData(x, index));
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        return (
            <Paper className={classes.root}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    title={title}
                    onAddClick={onAddClick}
                    onRefreshClick={onRefreshClick}
                    onDeleteClick={() => onDeleteClick(this.state.selected)}
                    onFilterClick={onFilterClick}
                />
                {this.props.fetching && <LinearProgress className="no-margin-progress" />}
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            rows={rows}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={data.length}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item) => {
                                    const isSelected = this.isSelected(item.id);
                                    let tableCells = rows.map((row, index) => {
                                        let itemValue = item.properties[row.id];

                                        if (row.date) {
                                            return (
                                                <Tooltip title={dayjs(itemValue).calendar()}>
                                                    <TableCell key={index}>{dayjs(itemValue).fromNow()}</TableCell>
                                                </Tooltip>
                                            );
                                        }

                                        if (row.boolean) {
                                            return (
                                                <Tooltip title={itemValue ? "Paused" : "Running"}>
                                                    <TableCell key={index}>{itemValue ? "Paused" : "Running"}</TableCell>
                                                </Tooltip>
                                            );
                                        }

                                        return <TableCell key={index}>{itemValue}</TableCell>;
                                    });
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={item.id}
                                            selected={isSelected}
                                            onClick={() => onItemClick(item)}
                                        >
                                            <TableCell padding="checkbox" className={classes.checkboxCell} onClick={(event) => this.handleClick(event, item.id)}>
                                                <Checkbox checked={isSelected} />
                                            </TableCell>
                                            {tableCells}
                                            <TableCell className={classes.optionsColumn}>
                                                {onRunClick && (
                                                    <Button variant="outlined" className={classes.RunBtn} onClick={(e) => this.handleRunClick(e, item.id)}>
                                                        Run
                                                    </Button>
                                                )}
                                                {onEditClick && (
                                                    <Button variant="outlined" onClick={(e) => this.handleEditClick(e, item.id)}>
                                                        Edit
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                        "aria-label": "Next Page"
                    }}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EnhancedTable);

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "rc-slider/assets/index.css";

import Typography from "../../../components/CustomTypography/CustomTypography";
import CustomModal from "../../../components/CustomModal/CustomModal";
import AddMetadataModal from "../editSettingsViews/components/AddMetadataModal";
import toastr from "../../../components/CustomToastr/CustomToastr";

import SettingsIcon from "@mui/icons-material/Settings";
import { TextField, Button } from "@mui/material";
import { StyledTabs, StyledTab } from "../../../components/CustomTabs/CustomTabs";
import EditMetadataSchemaView from "../editSettingsViews/editMetadataSchemaView";
import EditSchemasView from "../editSettingsViews/schemas/editSchemasView";
import AddIcon from "@mui/icons-material/AddCircle";
import { v4 as uuidv4 } from "uuid";

import * as MetadataSchemaService from "../../../actions/metadataSchema";
import * as NetworkErrorUtils from "../../../utils/networkErrorUtils";
import * as ValidationUtils from "../../../utils/validationUtils";
import * as schemaService from "../../../actions/schemaService";
import { metadataTypes } from "../../../utils/constants/metadataTypes";

const settings = [
    { name: "Metadata schemas", icon: <SettingsIcon className="icon" fontSize="small" />, path: "/metadata" },
    { name: "Database schemas", icon: <SettingsIcon className="icon" fontSize="small" />, path: "/schemas" }
];

const EditOperations = {
    ADD: "ADD",
    EDIT: "EDIT",
    REMOVE: "REMOVE"
};

const SettingsView = (props) => {
    const [databaseSchemaTab, setDatabaseSchemaTab] = useState(false);
    const [schema, setSchema] = useState([]);
    const [newDatabaseSchemaName, setNewDatabaseSchemaName] = useState("");
    const [addMetadataOpen, setAddMetadataOpen] = useState(false);
    const [addDatabaseOpen, setAddDatabaseOpen] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [databaseSchemas, setDatabaseSchemas] = useState([]);

    const dispatch = useDispatch();

    const getMetadataSchema = () => {
        return dispatch(MetadataSchemaService.getSchema());
    };
    const updateMetadataSchema = (schemaChanges) => {
        return dispatch(MetadataSchemaService.updateSchema(schemaChanges, 1));
    };
    const getDatabaseSchemas = () => {
        return dispatch(schemaService.getSchemas());
    };
    const createDatabaseSchema = (schemaName) => {
        return dispatch(schemaService.createSchema(schemaName));
    };

    useEffect(() => {
        getMetadataSchema().then((res) => {
            setSchema(res.result.schema);
        });
        getDatabaseSchemas().then((res) => {
            setDatabaseSchemas(res.result);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeTab = (event, value) => {
        setDatabaseSchemaTab(value === 0 ? false : true);
    };

    const onOpenAddMetadataModal = () => {
        setAddMetadataOpen(true);
    };

    const onCloseAddMetadataModal = () => {
        setAddMetadataOpen(false);
    };

    const onOpenAddDatabaseModal = () => {
        setAddDatabaseOpen(true);
    };

    const onCloseAddDatabaseModal = () => {
        setAddDatabaseOpen(false);
        setNameError(false);
        setNewDatabaseSchemaName("");
    };

    const getMetadata = () => {
        getMetadataSchema()
            .then((res) => {
                const newSchema = res.result.schema;
                setSchema(newSchema);
                setAddMetadataOpen(false);
            })
            .catch((err) => {
                NetworkErrorUtils.handleError(err);
            });
    };

    const onAddMetadataProperty = (newPropertyType, newPropertyName, newTags, setNameError, setNewPropertyName) => {
        const newProp = {
            type: newPropertyType,
            name: newPropertyName,
            id: uuidv4()
        };

        const isEmpty = newProp.name === "";
        const alreadyExisting = schema.find((x) => newProp.name === x.name);
        if (isEmpty || alreadyExisting) {
            setNameError(alreadyExisting ? "Duplicate name" : "");
            return;
        }

        if (newProp.type === metadataTypes.TAG_LIST) {
            newProp.value = newTags;
        }

        const operation = { type: EditOperations.ADD, property: newProp };
        setNameError("");
        setNewPropertyName("");

        updateMetadataSchema([operation])
            .then(() => {
                getMetadata();
            })
            .catch((err) => {
                NetworkErrorUtils.handleError(err);
            });
    };

    const onAddSchema = () => {
        const alreadyExisting = databaseSchemas.find((x) => newDatabaseSchemaName === x);
        if (alreadyExisting) {
            setNameError("Duplicate name");
            return;
        }

        createDatabaseSchema(newDatabaseSchemaName.toLowerCase()).then(
            (res) => {
                setDatabaseSchemas([...databaseSchemas, newDatabaseSchemaName]);
                setNewDatabaseSchemaName("");
                toastr.success("Schema created");
            },
            (err) => NetworkErrorUtils.handleError(err)
        );
        setAddDatabaseOpen(false);
        setNameError(false);
        setNewDatabaseSchemaName("");
    };

    const onDatabaseSchemaNameChange = (e) => {
        const value = e.target.value;
        const error = ValidationUtils.validatePostgresIdentifier(value);
        setNewDatabaseSchemaName(value);
        setNameError(error);
    };

    const settingElements = () =>
        settings.map((item, index) => {
            return <StyledTab label={item.name} icon={item.icon} iconPosition="start" key={index} />;
        });

    return (
        <div className="sidebar-container settings-view">
            <div className="header">
                <Typography variant="h2">Settings</Typography>
                <Button color="primary" variant="contained" onClick={!databaseSchemaTab ? onOpenAddMetadataModal : onOpenAddDatabaseModal}>
                    <AddIcon fontSize="small" className="button-icon" />
                    {!databaseSchemaTab ? "Add Metadata Schema" : "Add Database Schema"}
                </Button>
            </div>
            <StyledTabs value={databaseSchemaTab ? 1 : 0} onChange={changeTab} indicatorColor="primary">
                {settingElements()}
            </StyledTabs>

            {!databaseSchemaTab ? <EditMetadataSchemaView schema={schema} setNewSchema={setSchema} /> : <EditSchemasView setDatabaseSchema={setDatabaseSchemas} />}

            <AddMetadataModal onCloseAddMetadataModal={onCloseAddMetadataModal} isOpen={addMetadataOpen} onAddMetadataProperty={onAddMetadataProperty} />
            <CustomModal
                handleClose={onCloseAddDatabaseModal}
                isOpen={addDatabaseOpen}
                onConfirm={onAddSchema}
                dialogTitle={"Add new Schema"}
                dialogType={"add"}
                disabled={Boolean(nameError)}
            >
                <TextField
                    id="filled-required"
                    placeholder="name"
                    value={newDatabaseSchemaName}
                    onChange={onDatabaseSchemaNameChange}
                    fullWidth
                    error={Boolean(nameError)}
                    helperText={nameError}
                    variant="filled"
                    label="Schema name"
                />
            </CustomModal>
        </div>
    );
};

export default SettingsView;

import * as types from "../actions/actionTypes";

const groupsInit = {
    fetching: false,
    groups: [],
    error: null
};

export function groupsReducer(state = groupsInit, action) {
    switch (action.type) {
        case types.FETCH_GROUPS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_GROUPS_COMPLETE:
            return {
                ...state,
                fetching: false,
                groups: action.result
            };
        case types.FETCH_GROUP:
            return {
                ...state,
                fetching: true,
                error: null
            };
        case types.FETCH_GROUP_COMPLETE:
            return {
                ...state,
                fetching: false,
                group: action.result
            };
        case types.CREATE_GROUP:
            return {
                ...state,
                fetching: true
            };
        case types.CREATE_GROUP_COMPLETE:
            return {
                ...state,
                fetching: false,
                groups: [...state.groups, action.result]
            };
        case types.UPDATE_GROUP:
            return {
                ...state,
                fetching: true
            };
        case types.UPDATE_GROUP_COMPLETE:
            return {
                ...state,
                groups: state.groups.map((group) => (group.id === action.result.id ? { ...group, name: action.result.name } : group)),
                fetching: false
            };
        case types.DELETE_GROUP:
            return {
                ...state,
                fetching: true
            };
        case types.DELETE_GROUP_COMPLETE:
            return {
                ...state,
                fetching: false,
                groups: removeFromArray(state.groups, action.result)
            };
        case types.GROUP_ACTION_FAILED:
            return {
                ...state,
                fetching: false,
                error: action.result
            };
        default:
            return state;
    }
}

function removeFromArray(array, item) {
    return array.filter((x) => item.id !== x.id);
}

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

import Button from "@mui/material/Button";

import MenuIcon from "@mui/icons-material/Menu";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import { history } from "../../store";
import { useStyles } from "./styles.js";

import { LOCATIONS } from "../../utils/routeUtils";

import * as navigationActions from "../../actions/navigation";

function NavigationMenu(props) {
    const classes = useStyles();

    function onClick(route) {
        history.push(route);
    }

    return (
        <div className="navigation-menu">
            <div className="header" onClick={() => props.toggleNavigation()}>
                {props.isNavigationOpen ? (
                    <Button className={`${classes.button} ${props.isNavigationOpen ? classes.justifyStart : classes.justifyCenter}`}>
                        <div className="header__item">
                            <span className="item__icon">
                                <DoubleArrowIcon className="icon rotate180" />
                            </span>
                            <span className="item__label">Navigation</span>
                        </div>
                    </Button>
                ) : (
                    <Button className={classes.button}>
                        <div className="header__item">
                            <MenuIcon className="icon" />
                        </div>
                    </Button>
                )}
            </div>
            <div className="container">
                {LOCATIONS.map((item, index) => {
                    return item.allowedRoles.includes(props.userRole) && item.displayInNavbar ? (
                        <div className={classes.itemWrapper} key={item.name}>
                            {!props.isNavigationOpen ? (
                                <Tooltip
                                    title={item.name}
                                    placement="right"
                                    PopperProps={{
                                        disablePortal: false
                                    }}
                                >
                                    <div className={props.location.pathname.startsWith(item.route) ? "nav-item is-active" : "nav-item"} onClick={() => onClick(item.route)}>
                                        <Button className={`${classes.button} ${props.isNavigationOpen ? classes.justifyStart : classes.justifyCenter}`}>{item.icon}</Button>
                                    </div>
                                </Tooltip>
                            ) : (
                                <>
                                    <div className={props.location.pathname.startsWith(item.route) ? "nav-item is-active" : "nav-item"} onClick={() => onClick(item.route)}>
                                        <Button className={`${classes.button} ${props.isNavigationOpen ? classes.justifyStart : classes.justifyCenter}`}>
                                            <div className="nav-item__icon">{item.icon}</div>
                                            <span className="nav-item__label">{item.name}</span>
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    ) : null;
                })}
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        userRole: state.auth.userRole,
        isNavigationOpen: state.nav.isNavigationOpen
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleNavigation: () => dispatch(navigationActions.toggleNavigation())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationMenu));

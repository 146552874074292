import { createSelector } from "reselect";
import { createParameterizedSelector, forwardArg } from "./common";

export const getMapSources = (state) => state.map.sources;
export const getMapPosition = (state) => state.map.mapPosition;
export const getMapZoom = (state) => state.map.mapPosition.zoom;

export const getAppCacheStatusSelector = createSelector([getMapSources], (sources) => {
    let cacheStatus = 0;
    const maps = sources.filter((s) => s.isMap);

    const isCachingInProgress = maps.length && maps.some((s) => s.cacheStatus === 1);

    if (isCachingInProgress) {
        cacheStatus = 1;
    }

    const allMapsCached = maps.length && maps.every((s) => s.cacheStatus === 2);
    if (allMapsCached) {
        cacheStatus = 2;
    }

    return cacheStatus;
});

export const getSourceMinZoomSelector = createParameterizedSelector([getMapSources, forwardArg], (mapSources, sourceId) => {
    return mapSources.find((source) => source.id === sourceId).minZoom;
});

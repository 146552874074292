import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import * as MetadataSchemaService from "../../../actions/metadataSchema";

import toastr from "../../../components/CustomToastr/CustomToastr";
import * as NetworkErrorUtils from "../../../utils/networkErrorUtils";

import MetadataField from "./components/metadataField";
import ListView from "../../../components/listView";
import EditPropertyModal from "./components/editPropertyModal";
import { metadataTypes } from "../../../utils/constants/metadataTypes";

const styles = () => ({});

const EditOperations = {
    ADD: "ADD",
    EDIT: "EDIT",
    REMOVE: "REMOVE"
};

class EditMetadataSchemaView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            schema: this.props.schema,
            propertyToEdit: { name: "" },
            addModalOpen: false,
            editModalOpen: false,
            newPropertyType: metadataTypes.TEXT,
            newPropertyName: "",
            newPropertyNameError: false,
            errorHelperText: "",
            newTags: [],
            hasInvalidTag: false
        };

        this.columns = [
            {
                type: "type",
                name: "type",
                sortingFunction: (sortingReverse) => (sortingReverse ? this.sortByTypeReverse : this.sortByType)
            },
            {
                type: "name",
                name: "name",
                sortingFunction: (sortingReverse) => (sortingReverse ? this.sortByNameReverse : this.sortByName),
                start: true
            }
        ];
    }

    sortByTypeReverse = (item1, item2) => {
        if (item1.name > item2.name) {
            return -1;
        }
        return 0;
    };

    sortByType = (item1, item2) => {
        if (item1.name < item2.name) {
            return -1;
        }
        return 0;
    };

    sortByNameReverse = (item1, item2) => {
        if (item1.name > item2.name) {
            return -1;
        }
        return 0;
    };

    sortByName = (item1, item2) => {
        if (item1.name < item2.name) {
            return -1;
        }
        return 0;
    };

    componentDidMount() {
        this.props.getMetadataSchema().then((res) => {
            this.setState({
                schema: res.result.schema
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.schema !== prevProps.schema) {
            this.setState({
                schema: this.props.schema
            });
        }
    }

    onEditProperty = (property) => {
        if (this.state.hasInvalidTag) {
            return;
        }
        const isEmpty = property.name === "";
        const isDuplicateName = this.state.schema.some((prop) => prop.name === property.name && prop.id !== property.id);

        if (isEmpty || isDuplicateName) {
            this.setState({
                newPropertyNameError: true,
                errorHelperText: isDuplicateName ? "Duplicate name" : ""
            });
            return;
        }

        const newSchema = this.state.schema.map((prop) => {
            return prop.id === property.id ? property : prop;
        });

        this.props.updateMetadataSchema([{ type: EditOperations.EDIT, property: property }]).then(
            (res) => {
                toastr.success("Schema edited");
                this.setState({
                    schema: newSchema,
                    editModalOpen: false,
                    newPropertyNameError: false,
                    errorHelperText: ""
                });
            },
            (err) => NetworkErrorUtils.handleError(err)
        );
    };

    onOpenEditModal = (prop) => {
        this.setState({
            propertyToEdit: prop,
            editModalOpen: true
        });
    };

    onCloseEditModal = () => {
        this.setState({
            editModalOpen: false,
            propertyToEdit: { name: "" },
            newPropertyNameError: false,
            errorHelperText: ""
        });
    };

    onRemoveProperty = (prop) => {
        const newSchema = this.state.schema.filter((x) => x.id !== prop.id);
        const operation = { type: EditOperations.REMOVE, property: prop };

        this.props.updateMetadataSchema([operation]).then(
            (res) => {
                toastr.success("Schema removed");
                this.setState({
                    schema: newSchema
                });
                this.props.setNewSchema(newSchema);
            },
            (err) => NetworkErrorUtils.handleError(err)
        );
    };

    onNewPropertyNameChanged = (e) => {
        this.setState({
            newPropertyName: e.target.value
        });
    };

    onNewPropertyTypeChanged = (value) => {
        this.setState({
            newPropertyType: value
        });
    };

    onAddTag = (tag) => {
        this.setState({
            newTags: [...this.state.newTags, tag]
        });
    };

    onDeleteTag = (tag) => {
        this.setState({
            newTags: this.state.newTags.filter((x) => x.name !== tag.name)
        });
    };

    setInvalidTag = (hasInvalidTag) => {
        this.setState({
            hasInvalidTag: hasInvalidTag
        });
    };

    generateItem = (field, index) => {
        return <MetadataField field={field} selected={field.name === this.state.selected} key={index} onDelete={this.onRemoveProperty} onEdit={this.onOpenEditModal} />;
    };

    filterItem(item, query) {
        return item.name.toLowerCase().includes(query.toLowerCase());
    }

    render() {
        return (
            <>
                <ListView
                    searchPlaceholder={"Search fields"}
                    data={this.state.schema}
                    columns={this.columns}
                    generateItem={this.generateItem}
                    filterFunction={this.filterItem}
                ></ListView>

                <EditPropertyModal
                    open={this.state.editModalOpen}
                    onClose={this.onCloseEditModal}
                    property={this.state.propertyToEdit}
                    onEditProperty={this.onEditProperty}
                    error={this.state.newPropertyNameError}
                    errorHelper={this.state.errorHelperText}
                    setInvalidTag={this.setInvalidTag}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateMetadataSchema: (schemaChanges) => dispatch(MetadataSchemaService.updateSchema(schemaChanges, 1)),
        getMetadataSchema: () => dispatch(MetadataSchemaService.getSchema())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditMetadataSchemaView));

import axiosClient from "./apiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getStyleConfig = createAsyncThunk("getStyleConfig", async () => {
    return axiosClient.get(`config/styles`).then((res) => res.data);
});

export const getProjections = createAsyncThunk("getProjections", async ({ searchText = "", limit = 100 }) => {
    const query = new URLSearchParams();
    query.append("searchText", searchText);
    query.append("limit", limit);
    return axiosClient.get(`config/projections?${query.toString()}`).then((res) => res.data);
});

import React, { useState, useEffect } from "react";
import * as permissionServiceActions from "../../../actions/permissionService";
import * as userActions from "../../../actions/userService";
import * as groupServiceActions from "../../../actions/groupService";
import { useDispatch, useSelector } from "react-redux";
import toastr from "../../../components/CustomToastr/CustomToastr";
import { Typography, TextField, Tabs, Tab, Button, Grid, Divider } from "@mui/material";
import { handleError } from "../../../utils/networkErrorUtils";
import UserPermissionsList from "./components/UserPermissionsList/UserPermissionsList";
import ResourcePermissionsList from "./components/ResourcePermissionsList/ResourcePermissionsList";
import { useStyles } from "./styles.js";
import { getGroupsError, getLoadingGroups } from "../../../selectors/groups";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder/LoadingPlaceholder";
import ErrorPlaceholder from "../../../components/ErrorPlaceholder/ErrorPlaceholder";
import { permissionTypes } from "../../../utils/constants/permissionTypes";

const GroupEditView = ({ groupId }) => {
    const classes = useStyles();
    const [page, setPage] = useState("datasets");
    const [group, setGroup] = useState({ name: "" });
    const [datasetPermissions, setDatasetPermissions] = useState([]);
    const [rasterPermissions, setRasterPermissions] = useState([]);
    const [appPermissions, setAppPermissions] = useState([]);

    const [users, setUsers] = useState([]);
    const [initialGroupName, setInitialGroupName] = useState("");

    const groupsLoading = useSelector(getLoadingGroups);
    const hasErrorOccured = useSelector(getGroupsError);

    const dispatch = useDispatch();

    const filteredPermissions = permissionTypes.filter((p) => p.value !== 1);

    useEffect(() => {
        fetchGroup(groupId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchGroup = (groupId) => {
        let groupPromise = dispatch(groupServiceActions.getGroup(groupId));
        let usersPromise = dispatch(userActions.getUsers());

        Promise.all([groupPromise, usersPromise]).then((res) => {
            let groupData = res[0].result;
            let usersData = res[1].result.filter((x) => x.roles !== "admin");

            let groupUsersLookup = groupData.users.reduce((a, b) => {
                a[b.id] = "";
                return a;
            }, {});

            let users = [];

            for (let i = 0; i < usersData.length; i++) {
                let user = usersData[i];
                if (!groupUsersLookup.hasOwnProperty(user.id)) {
                    users.push({
                        id: user.id,
                        username: user.username,
                        roles: user.roles,
                        isMember: false
                    });
                } else {
                    users.push({
                        id: user.id,
                        username: user.username,
                        roles: user.roles,
                        isMember: true
                    });
                }
            }
            setInitialGroupName(groupData.name);
            setGroup({ id: groupData.id, name: groupData.name, isIndividualGroup: groupData.isIndividualGroup });
            setDatasetPermissions(groupData.datasetPermissions);
            setRasterPermissions(groupData.rasterPermissions);
            setAppPermissions(groupData.applicationPermissions);
            setUsers(users);
            setPage(groupData.isIndividualGroup ? "datasets" : "users");
        });
    };

    const handleDatasetPermissionChange = (dataset, permissionType) => {
        let promise;

        if (dataset.permissionType === 0) {
            promise = dispatch(permissionServiceActions.addDatasetPermission(group.id, dataset.id, permissionType));
        } else if (permissionType === 0) {
            promise = dispatch(permissionServiceActions.removeDatasetPermission(group.id, dataset.id));
        } else {
            promise = dispatch(permissionServiceActions.updateDatasetPermission(group.id, dataset.id, permissionType));
        }

        return promise
            .then(() => {
                toastr.success("Dataset permission updated");
                setDatasetPermissions((datasetPermissions) =>
                    datasetPermissions.map((x) =>
                        x.id === dataset.id
                            ? {
                                  ...x,
                                  permissionType
                              }
                            : x
                    )
                );
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const handleRasterPermissionChange = (raster, permissionType) => {
        let promise;

        if (raster.permissionType === 0) {
            promise = dispatch(permissionServiceActions.addRasterPermission(group.id, raster.id, permissionType));
        } else if (permissionType === 0) {
            promise = dispatch(permissionServiceActions.removeRasterPermission(group.id, raster.id));
        } else {
            promise = dispatch(permissionServiceActions.updateRasterPermission(group.id, raster.id, permissionType));
        }

        return promise
            .then(() => {
                toastr.success("Raster permission updated");
                setRasterPermissions((rasterPermissions) =>
                    rasterPermissions.map((x) =>
                        x.id === raster.id
                            ? {
                                  ...x,
                                  permissionType
                              }
                            : x
                    )
                );
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const handleGroupUserChange = (user) => {
        let promise;

        if (user.isMember) {
            promise = dispatch(groupServiceActions.removeGroupUser(group.id, user.id));
        } else {
            promise = dispatch(groupServiceActions.addGroupUser(group.id, user.id));
        }

        return promise
            .then(() => {
                toastr.success("User permission updated");
                setUsers((users) => users.map((x) => (x.id === user.id ? { ...user, isMember: !user.isMember } : x)));
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const handleAppPermissionChange = (app, permissionType) => {
        let promise;

        if (app.permissionType === 0) {
            promise = dispatch(permissionServiceActions.addAppPermission(group.id, app.id, permissionType));
        } else if (permissionType === 0) {
            promise = dispatch(permissionServiceActions.removeAppPermission(group.id, app.id));
        } else {
            promise = dispatch(permissionServiceActions.updateAppPermission(group.id, app.id, permissionType));
        }

        return promise
            .then((response) => {
                setAppPermissions((appPermissions) =>
                    appPermissions.map((x) =>
                        x.id === app.id
                            ? {
                                  ...x,
                                  permissionType
                              }
                            : x
                    )
                );
                setDatasetPermissions(response.datasetPermissions);
                setRasterPermissions(response.rasterPermissions);
                toastr.success("App permission updated");
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const changePage = (e, value) => {
        setPage(value);
    };

    const changeGroupName = (e) => {
        setGroup({
            ...group,
            name: e.target.value
        });
    };

    const handleUpdateGroup = () => {
        dispatch(groupServiceActions.updateGroup(group.id, { name: group.name }))
            .then(() => {
                toastr.success("Group name updated");
                setInitialGroupName(group.name);
            })
            .catch((err) => handleError(err));
    };

    const usersRender = <UserPermissionsList users={users} userMembershipChangePromise={handleGroupUserChange} />;

    const datasetsRender = <ResourcePermissionsList resources={datasetPermissions} permissions={permissionTypes} permissionChangePromise={handleDatasetPermissionChange} />;

    const rastersRender = <ResourcePermissionsList resources={rasterPermissions} permissions={filteredPermissions} permissionChangePromise={handleRasterPermissionChange} />;

    const appsRender = <ResourcePermissionsList resources={appPermissions} permissions={filteredPermissions} permissionChangePromise={handleAppPermissionChange} />;

    const pageRenderDict = {
        users: usersRender,
        datasets: datasetsRender,
        rasters: rastersRender,
        apps: appsRender
    };

    return (
        <div className="group-edit-view">
            <div className="container">
                {!group.isIndividualGroup ? (
                    <div className="section">
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs>
                                <TextField fullWidth placeholder="Name of the group" value={group.name} onChange={changeGroupName} variant="filled" label="Group Name" />
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={initialGroupName === group.name}
                                    onClick={handleUpdateGroup}
                                    className={classes.buttonMargin}
                                >
                                    Update Name
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <Typography variant="h6" className="section">
                        {group.name}
                    </Typography>
                )}
                <LoadingPlaceholder loading={groupsLoading} message="Getting your data" textVariant="h5" spinnerSize={65}>
                    <ErrorPlaceholder error={hasErrorOccured} message="Encountered an error while getting your data" textVariant="h5" onTryAgain={() => fetchGroup()}>
                        <Tabs value={page} onChange={changePage} variant="fullWidth">
                            {!group.isIndividualGroup && <Tab label="users" value="users" />}
                            <Tab label="datasets" value="datasets" />
                            <Tab label="rasters" value="rasters" />
                            <Tab label="apps" value="apps" />
                        </Tabs>
                        <Divider />
                        <div className="list">{pageRenderDict[page]}</div>
                    </ErrorPlaceholder>
                </LoadingPlaceholder>
            </div>
        </div>
    );
};

export default GroupEditView;

const sortByName = (raster1, raster2) => {
    return raster1.name.localeCompare(raster2.name);
};

const sortByNameReverse = (raster1, raster2) => {
    return raster2.name.localeCompare(raster1.name);
};

const sortByModified = (raster1, raster2) => {
    return new Date(raster2.modifiedUtc) - new Date(raster1.modifiedUtc);
};

const sortByModifiedReverse = (raster1, raster2) => {
    return new Date(raster1.modifiedUtc) - new Date(raster2.modifiedUtc);
};

const sortByCreated = (raster1, raster2) => {
    return new Date(raster2.createdUtc) - new Date(raster1.createdUtc);
};

const sortByCreatedReverse = (raster1, raster2) => {
    return new Date(raster1.createdUtc) - new Date(raster2.createdUtc);
};

const sortByZoom = (raster1, raster2) => {
    return raster2.maxZoom - raster1.maxZoom;
};

const sortByZoomReverse = (raster1, raster2) => {
    return raster1.maxZoom - raster2.maxZoom;
};

const sortBySize = (raster1, raster2) => {
    return raster1.fileSize - raster2.fileSize;
};

const sortBySizeReverse = (raster1, raster2) => {
    return raster2.fileSize - raster1.fileSize;
};

const rastersSorting = [
    {
        type: "name",
        name: "Name",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByNameReverse : sortByName)
    },
    {
        type: "text",
        name: "Zoom",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByZoomReverse : sortByZoom)
    },
    {
        type: "text",
        name: "Size",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortBySizeReverse : sortBySize)
    },
    {
        type: "date",
        name: "Created",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByCreatedReverse : sortByCreated)
    },
    {
        type: "date",
        name: "Modified",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByModifiedReverse : sortByModified),
        start: true
    }
];

export default rastersSorting;

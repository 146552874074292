import { TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import Typography from "../../../CustomTypography/CustomTypography";
import React from "react";
import UpArrow from "../../../../utils/icons/arrow-up.svg";
import DownArrow from "../../../../utils/icons/arrow-down.svg";
import { useCustomTableHeadStyles } from "./styles";
import clsx from "clsx";

const TableHeader = ({ columns, sorting, onSetSort, sortingReverse, additionalActionColumn }) => {
    const classes = useCustomTableHeadStyles();

    const upArrowClass = (column) => {
        return clsx(classes.sortingArrow, sorting === column && sortingReverse && classes.hidden, sorting !== column && classes.hidden && classes.visibleOnHover);
    };

    const downArrowClass = (column) => {
        return clsx(classes.sortingArrow, sorting === column && !sortingReverse && classes.hidden, sorting !== column && classes.hidden);
    };
    return (
        <TableHead>
            <TableRow>
                {columns.map((column, columnIndex) => {
                    return (
                        <TableCell
                            sortDirection="asc"
                            key={columnIndex}
                            className={clsx(sorting === column.name && classes.active, classes.tableCell)}
                            onClick={() => onSetSort(column)}
                        >
                            <Tooltip id="tooltip-top" title={`Sort by ${column.name}`} arrow>
                                <div className={`${classes.button} ${column.type} ${classes.tableCellContent}`}>
                                    {column.icon ? (
                                        <div className={sorting !== column.name ? classes.arrowPlaceholder : ""}>{column.icon}</div>
                                    ) : (
                                        <Typography variant="body2" fontWeight="bold" className={sorting !== column.name ? classes.arrowPlaceholder : ""}>
                                            {column.name}
                                        </Typography>
                                    )}
                                    <img src={UpArrow} className={upArrowClass(column.name)} alt="" />
                                    <img src={DownArrow} className={downArrowClass(column.name)} alt="" />
                                </div>
                            </Tooltip>
                        </TableCell>
                    );
                })}
                {additionalActionColumn && <TableCell size="small" />}
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;

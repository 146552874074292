import React from "react";
import ListView from "../../../../../components/listView";
import ResourcePermissions from "../ResourcesPermissions/ResourcePermissions";

const sortByName = (resource1, resource2) => {
    return resource1.name.toLowerCase().localeCompare(resource2.name.toLowerCase());
};

const sortByNameReverse = (resource1, resource2) => {
    return resource2.name.toLowerCase().localeCompare(resource1.name.toLowerCase());
};

const resourcePermissionsColumns = [
    {
        name: "name",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByNameReverse : sortByName),
        start: true
    }
];

const ResourcePermissionsList = ({ resources, permissions, permissionChangePromise }) => {
    const resourceFilterFunction = (resource, searchedResource) => {
        return resource.name.includes(searchedResource);
    };
    const generateResource = (resource) => {
        return <ResourcePermissions key={resource.id} resource={resource} permissions={permissions} permissionChangePromise={permissionChangePromise} />;
    };

    return (
        <ListView
            searchPlaceholder="Search for resource"
            data={resources}
            columns={resourcePermissionsColumns}
            filterFunction={resourceFilterFunction}
            generateItem={generateResource}
            hideBottomBar
        />
    );
};

export default ResourcePermissionsList;

import React from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import GroupsListView from "../groupsListView/groupsListView";
import Header from "../../../components/header/header";
import HeaderButtons from "../../../components/HeaderButtons/HeaderButtons";

const styles = () => ({});

const GroupsRootView = ({ match, unseenNotifications }) => {
    return (
        <div className="groups-rootview">
            <div className="sidebar">
                <div className="header">
                    <Header />
                    <HeaderButtons unseenNotifications={unseenNotifications} />
                </div>
                <GroupsListView />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        unseenNotifications: state.notifications.unseenNotifications
    };
};

export default connect(mapStateToProps)(withStyles(styles)(GroupsRootView));

import React, { useMemo, useState } from "react";
import CustomTable from "../CustomTable/CustomTable";
import SearchBar from "../SearchBar/SearchBar";
import { useTableViewStyles } from "./styles";

const TableView = ({ searchPlaceholder, data, fetchingData, columns, generateItem, hideBottomBar, tableSize, filterFunction }) => {
    const classes = useTableViewStyles();

    const [searchText, setSearchText] = useState("");

    const onSearchChange = (newValue) => {
        setSearchText(newValue);
    };

    const filteredData = useMemo(
        () => data.filter((entity) => filterFunction(entity, searchText)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, searchText]
    );

    return (
        <div className={classes.tableContainer}>
            <SearchBar searchPlaceholder={searchPlaceholder} value={searchText} onChange={onSearchChange} textFieldClassName={classes.searchBarWidth} />
            <CustomTable data={filteredData} fetchingData={fetchingData} columns={columns} generateItem={generateItem} hideBottomBar={hideBottomBar} tableSize={tableSize} />
        </div>
    );
};

export default TableView;

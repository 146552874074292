import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddCircle";
import Typography from "../../../components/CustomTypography/CustomTypography";
import LinearProgress from "@mui/material/LinearProgress";
import { StyledTabs, StyledTab } from "../../../components/CustomTabs/CustomTabs";
import * as groupsActions from "../../../actions/groupService";
import toastr from "../../../components/CustomToastr/CustomToastr";
import CreateModal from "./CreateGroupModal/CreateGroupModal";
import EditModal from "./EditModal/EditModal";
import Group from "./components/group";
import ListView from "../../../components/listView";
import { withRouter } from "react-router";
import groupSorting from "./components/groupsSorting";
import { handleError } from "../../../utils/networkErrorUtils";

const styles = () => ({
    icon: {
        marginRight: 8
    }
});

class GroupsListView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            groups: [],
            modalOpen: false,
            editGroupModalOpen: false,
            search: "",
            sorting: "name",
            sortingReverse: false,
            rowsPerPage: 10,
            individualGroupsTab: false,
            selectedGroupId: null,
            selected: false
        };

        this.columns = groupSorting;
    }

    componentDidMount() {
        this.props.getGroups();
    }

    groupOnClick = (item) => {
        this.setState({
            editGroupModalOpen: true,
            selectedGroupId: item.id,
            selected: true
        });
    };

    onEditGroupModalClose = () => {
        this.setState({
            editGroupModalOpen: false,
            selectedGroupId: null,
            selected: false
        });
    };

    onAddGroupClick = () => {
        this.setState({
            modalOpen: true
        });
    };

    onModalClose = () => {
        this.setState({
            modalOpen: false
        });
    };

    onDeleteGroup = (group) => {
        const toastrConfirmOptions = {
            onOk: () => this.deleteGroup(group),
            onCancel: () => {}
        };
        toastr.confirm(`Are you sure you want to delete group: ${group.name}?`, toastrConfirmOptions);
    };

    deleteGroup = (group) => {
        this.props
            .deleteGroup(group.id)
            .then(() => {
                toastr.success("Group deleted");
            })
            .catch((err) => {
                handleError(err);
            });
    };

    onSearch = (e) => {
        this.setState({
            search: e.target.value
        });
    };

    onChangeRowsPerPage = (e) => {
        this.setState({
            rowsPerPage: e.target.value
        });
    };

    generateItem = (group, index) => {
        return (
            <Group
                group={group}
                selected={this.state.selected}
                key={index}
                OnClick={this.groupOnClick}
                onDeleteClick={this.onDeleteGroup}
                optionsHidden={this.state.individualGroupsTab}
            />
        );
    };

    filterItem(item, query) {
        return item.name.toLowerCase().includes(query.toLowerCase());
    }

    changeTab = (event, value) => {
        this.setState({ individualGroupsTab: value === 0 ? false : true });
    };

    render() {
        const data = this.props.groups.filter((x) => x.isIndividualGroup === this.state.individualGroupsTab);
        return (
            <div className="sidebar-container group-overview">
                <CreateModal open={this.state.modalOpen} handleClose={this.onModalClose} />
                <EditModal open={this.state.editGroupModalOpen} handleClose={this.onEditGroupModalClose} groupId={this.state.selectedGroupId}></EditModal>

                <div className="header">
                    <Typography variant="h2">Groups</Typography>
                    <Button color="primary" variant="contained" onClick={this.onAddGroupClick}>
                        <AddIcon fontSize="small" className="button-icon" />
                        Add Group
                    </Button>
                </div>

                {this.props.fetching && <LinearProgress className="no-margin-progress" />}

                <StyledTabs value={this.state.individualGroupsTab ? 1 : 0} onChange={this.changeTab} indicatorColor="primary">
                    <StyledTab label="Groups" />
                    <StyledTab label="Individual Groups" />
                </StyledTabs>

                <ListView
                    searchPlaceholder={"Search groups"}
                    data={data}
                    columns={this.columns}
                    generateItem={this.generateItem}
                    filterFunction={this.filterItem}
                    optionsHidden={this.state.individualGroupsTab}
                ></ListView>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        datasets: state.datasets,
        groups: state.groups.groups,
        fetching: state.groups.fetching
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getGroups: () => dispatch(groupsActions.getGroups()),
        deleteGroup: (groupId) => dispatch(groupsActions.deleteGroup(groupId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(GroupsListView)));

export const BASEMAPS = [
    {
        title: "Basemap",
        type: "vector",
        url: "mapbox://styles/lautec/ckk3texd9324b17qjeyovy1h1"
    },
    {
        title: "Satellite",
        type: "raster",
        url: "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibGF1dGVjIiwiYSI6ImNrNzRpNjFxaTBpc2IzZnBkNHU3MGJoMmoifQ.yNFawhQiPQGVujuEwb-TIg"
    },
    {
        title: "Street",
        type: "vector",
        url: "mapbox://styles/lautec/ckl1yfj5a0ooe17r1i737z0sf"
    },
    {
        title: "Navigation",
        type: "vector",
        url: "mapbox://styles/lautec/ckl1yhnc30ht417qncylgee9n"
    },
    {
        title: "Monochrome",
        type: "vector",
        url: "mapbox://styles/lautec/ckl1yikuk0xh717mpo8hbgsbd"
    },
    {
        title: "Road Map",
        type: "vector",
        url: "mapbox://styles/lautec/ckl1yjgtt0afy17mpgibbipuq"
    },
    {
        title: "Frank Wright",
        type: "vector",
        url: "mapbox://styles/lautec/ckl1ypmx10i1617lnm4oqcvni"
    },
    {
        title: "Blueprint",
        type: "vector",
        url: "mapbox://styles/lautec/ckl1yrvwm0xyz17l532svbmfn"
    }
];

export const TOOLS = [
    {
        name: "legend",
        namePretty: "Legend",
        enabled: true
    },
    {
        name: "digitize",
        namePretty: "Digitize",
        enabled: true
    }
];

export const WIDGETS = [
    {
        name: "search",
        namePretty: "Search",
        enabled: true
    },
    {
        name: "measure",
        namePretty: "Measure",
        enabled: true
    },
    {
        name: "print",
        namePretty: "Print",
        enabled: true
    }
];

export const SUPPORTED_LANGUAGES = [
    { name: "Arabic", id: "ar" },
    { name: "English", id: "en", default: true },
    { name: "Spanish", id: "es" },
    { name: "French", id: "fr" },
    { name: "German", id: "de" },
    { name: "Italian", id: "it" },
    { name: "Portuguese", id: "pt" },
    { name: "Russian", id: "ru" },
    { name: "Simplified Chinese", id: "zh-Hans" },
    { name: "Traditional Chinese", id: "zh-Hant" },
    { name: "Japanese", id: "ja" },
    { name: "Korean", id: "ko" },
    { name: "Vietnamese", id: "vi" }
];

export const DEFAULT_PROJECTION = "EPSG:4326";

export const CONFIG = {
    sidebarCollapsed: false,
    mapBounds: [
        [0, 0],
        [0, 0]
    ],
    projections: [{ name: "wgs 84", crs: "EPSG:4326", value: "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs" }],
    basemaps: BASEMAPS,
    tools: TOOLS,
    widgets: WIDGETS,
    layerGroups: [],
    languages: SUPPORTED_LANGUAGES.filter((x) => x.default)
};

export const APP_HIGH_PERSPECTIVE_CONFIG = {
    mapBounds: [
        [-188.17, -75.01],
        [229.43, 84.28]
    ]
};

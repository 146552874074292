import { Button, TextField, Tooltip } from "@mui/material";
import React from "react";
import PropertiesSection from "../PropertiesSection/PropertiesSection";

import toastr from "../../../../../components/CustomToastr/CustomToastr";
import fileCopyIcon from "../../../../../utils/icons/copy-icon.svg";
import { useAdvancedTabStyles } from "./styles";
import ZoomSection from "../ZoomSection/ZoomSection";
import { useFormikContext } from "formik";

const AdvancedTab = ({ bbox, setColumnsDataChanged, setTileDataChanged }) => {
    const classes = useAdvancedTabStyles();
    const { values, errors, handleChange } = useFormikContext();
    return (
        <>
            <div className="field copyable">
                <TextField
                    id="filled-required"
                    label="Tile Name"
                    placeholder="Name of the tiles"
                    variant="filled"
                    fullWidth
                    name="tileName"
                    value={values.tileName}
                    onChange={(e) => {
                        setTileDataChanged(true);
                        handleChange(e);
                    }}
                    error={!!errors.tileName}
                    helperText={errors.tileName}
                />
                <Tooltip title="Copy to clipboard">
                    <Button
                        TouchRippleProps={{ classes: { root: classes.buttonRipple } }}
                        className="copy-button"
                        onClick={() => {
                            navigator.clipboard.writeText(values.tileName);
                            toastr.success("The text has been copied");
                        }}
                    >
                        <img alt="" src={fileCopyIcon} />
                    </Button>
                </Tooltip>
            </div>
            <ZoomSection bbox={bbox} setTileDataChanged={setTileDataChanged} />
            <PropertiesSection setColumnsDataChanged={setColumnsDataChanged} />
        </>
    );
};

export default AdvancedTab;

import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ColorPopover from "./colorPopover";

const styles = () => ({});

const TagCreator = (props) => {
    const [tagName, setTagName] = useState("");
    const [tagNameError, setTagNameError] = useState(false);
    const [errorHelperText, setErrorHelperText] = useState("");
    const [tagColor, setTagColor] = useState("red");

    function onAddTag() {
        let isEmpty = tagName === "";
        let alreadyExisting = props.tags.find((x) => tagName === x.name);
        if (isEmpty || alreadyExisting) {
            setTagNameError(true);
            setErrorHelperText(alreadyExisting ? "Duplicate tag names" : "");
            return;
        }

        setTagNameError(false);
        setErrorHelperText("");
        setTagName("");
        props.onAddTag({ name: tagName, color: tagColor });
    }

    function lowerOpacity(rgbaString, opacity = "0.15") {
        if (rgbaString === "red") {
            //for backwards compatibility
            rgbaString = "rgba(255,0,0,1)";
        }

        let splitted = rgbaString.split(",");
        splitted[3] = opacity + ")";
        return splitted.join(",");
    }

    return (
        <div className="tag-creator">
            <div className="tag-list">
                <div className="tag-create-container">
                    <TextField
                        InputLabelProps={{
                            shrink: true
                        }}
                        placeholder="Add tag"
                        value={tagName}
                        label="Tag name"
                        error={tagNameError}
                        variant="outlined"
                        onChange={(e) => setTagName(e.target.value)}
                        helperText={errorHelperText}
                    />
                    <ColorPopover color={tagColor} onColorChanged={(color) => setTagColor(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)} />
                    <div onClick={onAddTag} className="add-tag-btn">
                        <AddIcon />
                    </div>
                </div>
                {!props.hideTags && (
                    <div className="tags">
                        {props.tags.map((tag, index) => {
                            return (
                                <div key={index} style={{ color: tag.color, backgroundColor: lowerOpacity(tag.color) }} className="tag">
                                    <span>{tag.name}</span>
                                    <CloseIcon className="remove-btn" onClick={() => props.onDeleteTag(tag)} />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default withStyles(styles)(TagCreator);

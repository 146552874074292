import { makeStyles } from "@mui/styles";

export const useDragAndDropStyles = makeStyles((theme) => ({
    dragIndicatorAbove: {
        borderTop: "2px solid " + theme.palette.primary.main
    },
    dragIndicatorBelow: {
        borderBottom: "2px solid " + theme.palette.primary.main + "!important"
    }
}));

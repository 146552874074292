import React, { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import Typography from "../CustomTypography/CustomTypography";
import { useStyles } from "./style.js";

const OverflowTip = (props) => {
    const { variant, fontWeight, color, className } = props;

    const [isOverflowed, setIsOverflowed] = useState(false);
    const textElement = React.createRef();
    const classes = useStyles();

    useEffect(() => {
        setIsOverflowed(textElement.current.scrollWidth > textElement.current.clientWidth);
    }, [textElement]);

    return (
        <div className={classes.textDiv}>
            {isOverflowed ? (
                <Tooltip title={<span className={classes.multipleLine}>{props.children}</span>}>
                    <div>
                        <Typography ref={textElement} variant={variant} fontWeight={fontWeight} color={color} className={className} noWrap>
                            {props.children}
                        </Typography>
                    </div>
                </Tooltip>
            ) : (
                <Typography ref={textElement} variant={variant} fontWeight={fontWeight} color={color} className={className}>
                    {props.children}
                </Typography>
            )}
        </div>
    );
};

OverflowTip.defaultProps = {
    fontWeight: "inherit",
    color: "inherit",
    noWrap: false
};

export default OverflowTip;

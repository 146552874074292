import React, { useState } from "react";
import { TextField, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import { metadataTypes } from "../../../../utils/constants/metadataTypes";
import TagCreator from "../../editSettingsViews/components/tagCreator";

const AddMetadataModal = ({ onCloseAddMetadataModal, isOpen, onAddMetadataProperty }) => {
    const [newPropertyType, setNewPropertyType] = useState(metadataTypes.TEXT);
    const [newPropertyName, setNewPropertyName] = useState("");
    const [nameError, setNameError] = useState("");
    const [newTags, setNewTags] = useState([]);

    const onNewPropertyTypeChanged = (value) => {
        setNewPropertyType(value);
    };

    const onNewPropertyNameChanged = (e) => {
        setNewPropertyName(e.target.value);
    };

    const onAddTag = (tag) => {
        setNewTags([...newTags, tag]);
    };

    const onDeleteTag = (tag) => {
        setNewTags(newTags.filter((x) => x.name !== tag.name));
    };

    const onClose = () => {
        onCloseAddMetadataModal();
        setNameError("");
        setNewPropertyName("");
        setNewTags([]);
        setNewPropertyType(metadataTypes.TEXT);
    };

    const onSubmit = () => {
        onAddMetadataProperty(newPropertyType, newPropertyName, newTags, setNameError, setNewPropertyName);
        setNewPropertyType(metadataTypes.TEXT);
    };

    return (
        <CustomModal handleClose={onClose} isOpen={isOpen} onConfirm={onSubmit} dialogTitle={"Add new property"} dialogType={"add"}>
            <div className="content">
                <div className="property">
                    <FormControl className="type" variant="filled" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                        <Select value={newPropertyType} label="Name" variant="filled">
                            <MenuItem onClick={() => onNewPropertyTypeChanged("text")} value="text">
                                Text
                            </MenuItem>
                            <MenuItem onClick={() => onNewPropertyTypeChanged(metadataTypes.NUMBER)} value={metadataTypes.NUMBER}>
                                Number
                            </MenuItem>
                            <MenuItem onClick={() => onNewPropertyTypeChanged(metadataTypes.DATE)} value={metadataTypes.DATE}>
                                Date
                            </MenuItem>
                            <MenuItem onClick={() => onNewPropertyTypeChanged(metadataTypes.TAG_LIST)} value={metadataTypes.TAG_LIST}>
                                Tag List
                            </MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        id="filled-required"
                        value={newPropertyName}
                        onChange={onNewPropertyNameChanged}
                        error={Boolean(nameError)}
                        label="Name"
                        variant="filled"
                        helperText={nameError}
                    />
                </div>
                {newPropertyType === metadataTypes.TAG_LIST && <TagCreator tags={newTags} onAddTag={onAddTag} onDeleteTag={onDeleteTag} />}
            </div>
        </CustomModal>
    );
};

export default AddMetadataModal;

//import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export const addDatasetPermission = (groupId, datasetId, permssionType) => {
    return () => {
        return axiosClient.post(`permission/${groupId}/datasets/${datasetId}`, { permission: permssionType });
    };
};

export const updateDatasetPermission = (groupId, datasetId, permssionType) => {
    return () => {
        return axiosClient.put(`permission/${groupId}/datasets/${datasetId}`, { permission: permssionType });
    };
};

export const removeDatasetPermission = (groupId, datasetId) => {
    return () => {
        return axiosClient.delete(`permission/${groupId}/datasets/${datasetId}`);
    };
};

export const addRasterPermission = (groupId, rasterId, permssionType) => {
    return () => {
        return axiosClient.post(`permission/${groupId}/rasters/${rasterId}`, { permission: permssionType });
    };
};

export const updateRasterPermission = (groupId, rasterId, permssionType) => {
    return () => {
        return axiosClient.put(`permission/${groupId}/rasters/${rasterId}`, { permission: permssionType });
    };
};

export const removeRasterPermission = (groupId, rasterId) => {
    return () => {
        return axiosClient.delete(`permission/${groupId}/rasters/${rasterId}`);
    };
};

export const addAppPermission = (groupId, appId, permssionType) => {
    return () => {
        return axiosClient.post(`permission/${groupId}/applications/${appId}`, { permission: permssionType }).then((res) => res.data);
    };
};

export const updateAppPermission = (groupId, appId, permssionType) => {
    return () => {
        return axiosClient.put(`permission/${groupId}/applications/${appId}`, { permission: permssionType }).then((res) => res.data);
    };
};

export const removeAppPermission = (groupId, appId) => {
    return () => {
        return axiosClient.delete(`permission/${groupId}/applications/${appId}`).then((res) => res.data);
    };
};

import React, { useState, useEffect } from "react";

import TagEditor from "./tagEditor";

import { TextField } from "@mui/material";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import { metadataTypes } from "../../../../utils/constants/metadataTypes";

const EditPropertyModal = ({ open, onEditProperty, property, onClose, error, errorHelper, setInvalidTag }) => {
    const [newName, setNewName] = useState(property.name);
    const [newValue, setNewValue] = useState(property.value);

    useEffect(() => {
        setNewName(property.name);
        const newPropValue = property.value ? property.value : property.type === metadataTypes.TAG_LIST ? [] : null;
        setNewValue(newPropValue);
    }, [property]);

    const onEdit = () => {
        onEditProperty({ ...property, name: newName, value: newValue });
    };

    const onTagsChanged = (tags) => {
        setNewValue(tags);
    };

    const renderPropertyEditor = (type) => {
        switch (type) {
            case metadataTypes.TAG_LIST:
                return <TagEditor tags={newValue} onTagsChanged={onTagsChanged} setInvalidTag={setInvalidTag} />;
            default:
                return null;
        }
    };

    const propertyEditor = renderPropertyEditor(property.type);

    return (
        <CustomModal handleClose={() => onClose()} isOpen={open} onConfirm={onEdit} dialogTitle={"Edit property"} dialogType={"apply"}>
            <div className="container">
                <div className="content">
                    <div className="property">
                        <TextField
                            id="filled-required"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            label="Name"
                            variant="filled"
                            error={error}
                            helperText={errorHelper}
                            fullWidth
                        />
                    </div>
                    <div>{propertyEditor}</div>
                </div>
            </div>
        </CustomModal>
    );
};

export default EditPropertyModal;

import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import * as groupActions from "../../../../actions/groupService";
import toastr from "../../../../components/CustomToastr/CustomToastr";
import { handleError } from "../../../../utils/networkErrorUtils";
import CustomModal from "../../../../components/CustomModal/CustomModal";

const styles = () => ({});

class CreateGroupModal extends Component {
    state = {
        name: "",
        validated: { name: true },
        buttonDisabled: false
    };

    componentDidMount() {}

    changeName = (e) => {
        let nameValid = e.target.value !== "";

        this.setState({
            name: e.target.value,
            validated: {
                ...this.state.validated,
                name: nameValid
            }
        });
    };

    validateInfo() {
        let nameValid = this.state.name !== "";

        this.setState({
            validated: {
                name: nameValid
            }
        });

        return nameValid;
    }

    createGroup = () => {
        if (!this.validateInfo()) {
            return;
        }

        let request = {
            name: this.state.name
        };

        this.setState({ buttonDisabled: true });
        this.props.createGroup(request).then(
            () => {
                this.setState({ buttonDisabled: false, name: "" });
                this.props.handleClose();
                toastr.success("Group created");
            },
            (err) => {
                this.setState({ buttonDisabled: false });
                handleError(err);
            }
        );
    };

    render() {
        let { props } = this;
        return (
            <div>
                <CustomModal
                    handleClose={props.handleClose}
                    isOpen={props.open}
                    onConfirm={this.createGroup}
                    dialogTitle={"Create group"}
                    dialogType={"create"}
                    disabled={this.state.buttonDisabled}
                >
                    <TextField
                        id="filled-required"
                        placeholder="Name of the Group"
                        value={this.state.name}
                        onChange={this.changeName}
                        fullWidth
                        error={!this.state.validated.name}
                        variant="filled"
                        label="Group Name"
                    />
                </CustomModal>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        createGroup: (createGroupRequest) => dispatch(groupActions.createGroup(createGroupRequest))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateGroupModal));

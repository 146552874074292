import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { history } from "../../../store";
import TextField from "@mui/material/TextField";
import * as appActions from "../../../actions/apps";
import toastr from "../../../components/CustomToastr/CustomToastr";
import { APP_HIGH_PERSPECTIVE_CONFIG, CONFIG } from "../../../utils/constants/appDefaults";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { handleError } from "../../../utils/networkErrorUtils";

const styles = () => ({});

class CreateAppModal extends Component {
    state = {
        name: "",
        validated: { name: true }
    };

    componentDidMount() {}

    changeName = (e) => {
        let nameValid = e.target.value !== "";

        this.setState({
            name: e.target.value,
            validated: {
                ...this.state.validated,
                name: nameValid
            }
        });
    };

    validateInfo() {
        let nameValid = this.state.name !== "";

        this.setState({
            validated: {
                name: nameValid
            }
        });

        return nameValid;
    }

    createApp = () => {
        if (!this.validateInfo()) {
            return;
        }

        let appRequest = {
            name: this.state.name,
            configJson: JSON.stringify({
                ...CONFIG,
                ...APP_HIGH_PERSPECTIVE_CONFIG
            })
        };

        this.props
            .createApp(appRequest)
            .then((res) => {
                let appResult = res.result;

                toastr.success("App created");
                history.push("/applications/edit/" + appResult.id);
                this.props.handleClose();
            })
            .catch((err) => {
                handleError(err);
            });
    };

    render() {
        let { props } = this;
        return (
            <div>
                <CustomModal handleClose={props.handleClose} isOpen={props.open} onConfirm={this.createApp} dialogTitle={"Create App"} dialogType={"create"}>
                    <TextField
                        id="filled-required"
                        placeholder="Name of the Application"
                        value={this.state.name}
                        onChange={this.changeName}
                        fullWidth
                        error={!this.state.validated.name}
                        margin="none"
                        variant="filled"
                        label="Application Name"
                    />
                </CustomModal>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        createApp: (createAppRequest) => dispatch(appActions.createApp(createAppRequest)),
        addMapToApp: (appId, mapId) => dispatch(appActions.addMapToApp(appId, mapId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateAppModal));

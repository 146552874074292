import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddCircle";
import { Dialog, FormControl, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import csv2Geojson from "csv2geojson";
import toastr from "../../../components/CustomToastr/CustomToastr";

const styles = () => ({});

const generateColumnNames = (header, delimiter) => {
    const columnNames = header.split(delimiter).map((col) => {
        const trimmedCol = col.trim();

        //Csv column names read from files can look like this:
        //['"test""bl""""asd"','oksadasd', '"dascasd"']
        //Excel saves csv for col names in the following ways:
        //"asd'casdad""casd"  -> """asd'casdad""""casd"""
        //"basd"  -> """basd"""
        //basd  -> basd
        //Besides this rule, there can be external csv which have cols saved like:
        //"col". The library won't split properly only if we give the name as col (no "")

        let columnName = trimmedCol.replaceAll(/(^"{1})|("{1}$)/g, "");
        columnName = columnName.replaceAll(/""/g, '"');

        return columnName;
    });
    return columnNames;
};

class csvDialog extends Component {
    state = {
        yField: "",
        xField: "",
        delimite: "",
        columns: []
    };

    fileContent = null;

    componentDidUpdate(prevProps) {
        if (this.props.file !== prevProps.file && this.props.file) {
            let file = this.props.file;
            new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = function (theFile) {
                    resolve(reader.result);
                };
                reader.readAsText(file);
            }).then((res) => {
                const header = res.split("\n")[0];
                const delimiter = this.guessDelimiter(header, "csv");

                const columnNames = generateColumnNames(header, delimiter);
                this.fileContent = res;
                this.setState({
                    columns: columnNames,
                    delimiter: delimiter
                });
            });
        }
    }

    guessDelimiter(line, fileType) {
        let array = [];

        array.push(
            {
                length: line.split(",").length,
                delimiter: ","
            },
            {
                length: line.split(";").length,
                delimiter: ";"
            },
            {
                length: line.split("\t").length,
                delimiter: "\t"
            },
            {
                length: line.split("|").length,
                delimiter: "|"
            }
        );

        array.sort((a, b) => {
            return b.length - a.length;
        });

        return array[0].delimiter;
    }

    onLatChanged = (e) => {
        this.setState({
            yField: e.target.value
        });
    };

    onLonChanged = (e) => {
        this.setState({
            xField: e.target.value
        });
    };

    onDelimiterChanged = (e) => {
        this.setState({
            delimiter: e.target.value
        });
    };

    onFinished = () => {
        let options = {
            latfield: this.state.yField,
            lonfield: this.state.xField,
            delimiter: this.state.delimiter
        };

        let file = this.props.file;

        this.convertToGeoJson(file, options).then(
            (res) => {
                this.props.onAddGeojson(file, res);
            },
            (err) => {
                toastr.error(err[0].message);
            }
        );
    };

    convertToGeoJson(file, options) {
        let csvContent = this.fileContent;
        return new Promise(function (resolve, reject) {
            csv2Geojson.csv2geojson(csvContent, options, function (err, data) {
                if (err) {
                    reject(err);
                }
                resolve(data);
            });
        });
    }

    render() {
        let { props } = this;

        if (!props.file) {
            return null;
        }

        let file = this.props.file;

        let columns = this.state.columns.map((column, index) => {
            return <MenuItem value={column}>{column}</MenuItem>;
        });

        return (
            <div>
                <Dialog
                    className="atlas-dialog create-group-modal"
                    open={props.open}
                    onClose={props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}
                >
                    <div className="dialog-header">
                        <Typography variant="h6" className="dialog-title">
                            Add {file.name}
                        </Typography>
                        <Tooltip title={!this.state.yField || !this.state.xField ? "Please select the X and Y columns before proceeding" : ""}>
                            <div>
                                <Button variant="contained" color="primary" disabled={!this.state.yField || !this.state.xField} onClick={this.onFinished}>
                                    <AddIcon style={{ marginRight: 8, marginLeft: -8 }} />
                                    Done
                                </Button>
                            </div>
                        </Tooltip>
                    </div>
                    <div className="container">
                        <div className="section">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="schema-label">X Column</InputLabel>
                                <Select labelId="schema-label" label="X Column" className="column-select" onChange={this.onLonChanged} value={this.state.xField} fullWidth>
                                    {columns}
                                </Select>
                            </FormControl>
                        </div>
                        <br />
                        <div className="section">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="schema-label">Y Column</InputLabel>
                                <Select labelId="schema-label" label="Y Column" className="column-select" onChange={this.onLatChanged} value={this.state.yField} fullWidth>
                                    {columns}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(csvDialog);

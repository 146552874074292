import DeleteIcon from "@mui/icons-material/Delete";
import { TableCell, TableRow } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import MenuButtonCell from "../../../../../components/CustomTable/components/customCells/MenuButtonCell/MenuButtonCell";
import NameCell from "../../../../../components/CustomTable/components/customCells/NameCell/NameCell";
import TooltipCell from "../../../../../components/CustomTable/components/customCells/TooltipCell/TooltipCell";
import Typography from "../../../../../components/CustomTypography/CustomTypography";
import { CELL_SIZES } from "../../../../../utils/constants/cellSizes";
import { humanFileSize } from "../../../../../utils/humanFileSize";
import { fromNowWithCheck } from "../../../../../utils/timeUtils";
import { useRasterRowStyles } from "./styles";

export default function RasterRow({ onDelete, raster, selected, onDoubleClick, onClick }) {
    const classes = useRasterRowStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const humanReadableSize = useMemo(() => humanFileSize(raster.fileSize), [raster.fileSize]);

    const onMenuClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const onDeleteClick = (e) => {
        onMenuClose(e);
        e.stopPropagation();
        onDelete(raster);
    };

    return (
        <TableRow className={clsx(selected && "selected", classes.rasterRow)} onDoubleClick={() => onDoubleClick(raster)} onClick={() => onClick(raster)}>
            <NameCell size={CELL_SIZES.extraLarge}>{raster.name}</NameCell>

            <TableCell>{`${raster.minZoom} - ${raster.maxZoom}`}</TableCell>

            <TableCell>{humanReadableSize}</TableCell>

            <TooltipCell tooltipTitle={dayjs(raster.createdUtc).format("MMMM DD YYYY, h:mm:ss a")}>
                <Typography variant="body2">{fromNowWithCheck(dayjs(raster.createdUtc))}</Typography>
            </TooltipCell>

            <TooltipCell tooltipTitle={dayjs(raster.modifiedUtc).format("MMMM DD YYYY, h:mm:ss a")}>
                <Typography variant="body2">{fromNowWithCheck(dayjs(raster.modifiedUtc))}</Typography>
            </TooltipCell>

            <MenuButtonCell onMenuClick={onMenuClick} />

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
                <MenuItem onClick={onDeleteClick} className="menu-button">
                    <DeleteIcon className="icon" />
                    Delete
                </MenuItem>
            </Menu>
        </TableRow>
    );
}

import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@mui/material/Autocomplete";
import { SUPPORTED_LANGUAGES } from "../../../../utils/constants/appDefaults";
import LanguageCard from "./LanguageCard/LanguageCard";
import CustomModal from "../../../../components/CustomModal/CustomModal";

const LanguagePicker = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [value, setValue] = useState(SUPPORTED_LANGUAGES[0]);
    const [inputValue, setInputValue] = useState("");
    const [, setAnchorEl] = useState(null);

    const defaultLanguage = SUPPORTED_LANGUAGES.find((x) => x.default);

    const onMenuClose = (e) => {
        setAnchorEl(null);
    };

    function onAddLanguage() {
        let languages = [...props.languages];
        if (!languages.some((language) => language.id === value.id)) {
            languages.push(value);
            props.onChange(languages);
        }
        setDialogOpen(false);
    }

    function onDeleteLanguage(language, e) {
        onMenuClose(e);
        let languages = props.languages.filter((x) => x.id !== language.id);

        props.onChange(languages);
    }

    function generateLanguageCard(item, index) {
        return (
            <LanguageCard
                key={item.id}
                language={item}
                onDeleteClick={() => onDeleteLanguage(item)}
                onMenuClose={() => onMenuClose()}
                projection={item}
                defaultLanguage={defaultLanguage}
            />
        );
    }

    return (
        <div className="projection-picker">
            <div className="projections">{props.languages.map((language, index) => generateLanguageCard(language, index))}</div>
            <div className="button-container">
                <Button className="button" variant="text" color="primary" onClick={() => setDialogOpen(true)}>
                    <AddIcon className="left-icon" fontSize="small" />
                    Add Language
                </Button>
            </div>

            <CustomModal handleClose={() => setDialogOpen(false)} isOpen={dialogOpen} onConfirm={onAddLanguage} dialogTitle={"Add Language"} dialogType={"add"}>
                <Autocomplete
                    disableClearable
                    options={SUPPORTED_LANGUAGES}
                    value={value}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => <TextField {...params} id="filled-required" label="Language" variant="filled" style={{ marginBottom: 10 }} />}
                />
            </CustomModal>
        </div>
    );
};

export default LanguagePicker;

import * as types from "../actions/actionTypes";

const tableViewInit = {
    fetching: false,
    datasetId: null,
    features: []
};

export function tableViewReducer(state = tableViewInit, action) {
    switch (action.type) {
        case types.FETCH_DATASET_GEOJSON:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_DATASET_GEOJSON_COMPLETE:
            return {
                ...state,
                fetching: false,
                features: action.result
            };
        case types.TABLE_VIEW_SET_DATASET:
            return {
                ...state,
                datasetId: action.result
            };
        case types.DATASET_ACTION_FAILED:
            return {
                ...state,
                fetching: false
            };
        default:
            return state;
    }
}

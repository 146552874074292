import React, { useState } from "react";
import { Button, Divider } from "@mui/material";
import PublishIcon from "@mui/icons-material/Public";
import Tooltip from "@mui/material/Tooltip";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useAppEditFooterStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getAppCacheStatusSelector } from "../../../../../selectors/map";
import { appHasStyleErrorsSelector, getLastSaveDatasetCount, getApp, getLayerGroups, getLayerStylesMap } from "../../../../../selectors/appData";
import * as appsActions from "../../../../../actions/apps";
import * as appDataActions from "../../../../../reducers/appData/appData";
import config from "../../../../../config";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import { handleError } from "../../../../../utils/networkErrorUtils";
import CustomDialog from "../../../../../components/CustomModal/CustomModal";
import CustomTypography from "../../../../../components/CustomTypography/CustomTypography";

const AppEditFooter = () => {
    const classes = useAppEditFooterStyles();

    const [errorInfoDialogOpen, setErrorInfoDialogOpen] = useState(false);

    const lastSaveDatasetCount = useSelector(getLastSaveDatasetCount);
    const cacheStatus = useSelector(getAppCacheStatusSelector);
    const app = useSelector(getApp);
    const layerGroups = useSelector(getLayerGroups);
    const layerStylesMap = useSelector(getLayerStylesMap);
    const appCanBeSaved = !useSelector(appHasStyleErrorsSelector);
    const dispatch = useDispatch();

    const onNavigateToViewer = () => {
        let url = config.apiUrl.replace("api.", "");
        window.open(`${url}${app.id}/map/layer-selector`, "_blank");
    };

    const onPublishApp = () => {
        const toastrConfirmOptions = {
            onOk: () => publishApp(),
            onCancel: () => {}
        };
        toastr.confirm("Are you sure you want to publish?", toastrConfirmOptions);
    };

    const publishApp = () => {
        dispatch(appsActions.publishApp(app.id)).then(
            () => {
                dispatch(appDataActions.setAppPublishedStatus(true));
                toastr.success("App published");
            },
            (err) => {
                handleError(err);
            }
        );
    };

    const saveAppAttempt = () => {
        if (appCanBeSaved) {
            saveApp();
        } else {
            setErrorInfoDialogOpen(true);
        }
    };

    const saveApp = () => {
        // //remove unneeded properties from layer
        function replacer(key, value) {
            if (key === "visible") {
                return undefined;
            }
            if (key === "totalLayersCount") {
                return undefined;
            }
            if (key === "visibleLayersCount") {
                return undefined;
            }
            return value;
        }

        let updatedApp = {
            name: app.name,
            configJson: JSON.stringify(
                { ...app.configJson, layerGroups: layerGroups.mapLayersRecursive((layer) => ({ ...layer, styles: layerStylesMap[layer.resourceId] })) },
                replacer
            ),
            modifiedUtc: app.modifiedUtc,
            overwriteData: false
        };

        if (cacheStatus === 0) {
            dispatch(appsActions.generateCache(app.id));
        }

        handleSave(app.id, updatedApp);
    };

    const handleSave = (appId, updatedApp) => {
        dispatch(appsActions.updateApp(appId, updatedApp)).then(
            (res) => {
                dispatch(appDataActions.setAppModifiedTime(res.result.newModifiedDate));
                toastr.success("App saved");
            },
            (err) => {
                if (err.response.status === 409) handleSaveOverwrite(appId, updatedApp);
                else handleError(err);
            }
        );
    };

    const handleSaveOverwrite = (appId, updatedApp) => {
        const toastrConfirmOptions = {
            onOk: () => {
                updatedApp.overwriteData = true;
                handleSave(appId, updatedApp);
            },
            onCancel: () => {}
        };
        toastr.confirm("Other app changes appeared in the database. Do you want to overwrite them?", toastrConfirmOptions);
    };

    const saveAppDisabledReason = () => {
        switch (true) {
            case cacheStatus === 1:
                return "Wait for the app to finish the caching process";
            default:
                return "";
        }
    };

    const publishAppDisabledReason = () => {
        switch (true) {
            case lastSaveDatasetCount === 0:
                return "Latest saved app version didn't contain any layers, therefore it can't be published. Add some layers and save the app in order to publish it";
            case cacheStatus === 0:
                return "Save the app before publishing it";
            case cacheStatus === 1:
                return "Wait for the app to finish the caching process";
            default:
                return "";
        }
    };

    const handleDialogClose = () => {
        setErrorInfoDialogOpen(false);
    };

    return (
        <>
            <Tooltip title={saveAppDisabledReason()}>
                <div>
                    <Button disabled={!!saveAppDisabledReason()} className={classes.marginRight} variant="contained" color="primary" onClick={saveAppAttempt}>
                        <SaveIcon className="left-icon" />
                        Save
                    </Button>
                </div>
            </Tooltip>
            <Tooltip title={publishAppDisabledReason()}>
                <div>
                    <Button disabled={!!publishAppDisabledReason()} className={classes.marginRight} variant="contained" color="secondary" onClick={onPublishApp}>
                        <PublishIcon className="left-icon" />
                        Publish
                    </Button>
                </div>
            </Tooltip>
            <Divider />
            <Tooltip title={!app.isPublished ? "You must first publish the app" : ""}>
                <div>
                    <Button disabled={!app.isPublished} onClick={onNavigateToViewer} variant="text" color="primary">
                        <VisibilityIcon className="left-icon" />
                        View Published
                    </Button>
                </div>
            </Tooltip>
            <CustomDialog dialogType="info" isOpen={errorInfoDialogOpen} handleClose={handleDialogClose} onConfirm={handleDialogClose} dialogTitle="App contains styling errors">
                <CustomTypography fontWeight="bold">
                    Before saving the app, fix the existing style errors. <br /> A red warning sign should be noticeable on layers that contain errors.
                </CustomTypography>
            </CustomDialog>
        </>
    );
};

export default AppEditFooter;

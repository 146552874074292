import makeStyles from "@mui/styles/makeStyles";

export const useLayerEditViewStyles = makeStyles((theme) => ({
    backIcon: {
        color: "black",
        transform: "rotate(180deg)"
    },
    stylerHeader: {
        //lots of right padding for the overflowing title
        padding: "10px 78px 10px 4px",
        height: theme.customSpacing.headerHeight,
        alignItems: "center",
        display: "flex",
        borderTop: "1px solid " + theme.customColors.borderColor,
        boxSizing: "border-box",
        backgroundColor: "white",
        width: "100%"
    },
    layerStyler: {
        overflow: "auto",
        display: "flex",
        flexGrow: 1,
        backgroundColor: theme.customColors.appBgColor,
        paddingTop: 8
    }
}));
